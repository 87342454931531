import { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import PooledPieChart from "./PooledPieChart";
import PooledLineChart from "./PooledLineChart";
import { formatSize, formatDataSizeAsTB } from '../../utils/util';
import _ from "lodash";
import TopCards from "./TopCards";
import PooledTable from './Table';
import './index.css';
import Download from '../../asset/image/DownloadUsage.svg';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

interface StarlinkPooledProps {
    pooledUsagePie: any;
    pooledBarChart: any;
    pooledUsagePieSplit: any;
}

function StarlinkPooled(props: StarlinkPooledProps) {
    const { pooledUsagePie, pooledBarChart, pooledUsagePieSplit } = props;

    const [pieLimit, setPieLimit] = useState(10);
    const [pieChartData, setPieChartData] = useState<any>([]);
    const [pieChartDataSplit, setPieChartDataSplit] = useState<any>([]);
    const [barChartData, setBarChartData] = useState<any>([]);
    const [toggleBtn, setToggleBtn] = useState(true);

    const colors = [
        '#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340', '#8cafdf', '#d9914f',
        '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64', '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6',
        '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9', '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585',
        '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6', '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    useEffect(() => {
        if(!_.isEmpty(pooledUsagePieSplit)) {
            const pieChartData = pooledUsagePieSplit.map((item, i) => {
                return {
                    x: item.pool_name,
                    y: item.percentage,
                    z: formatDataSizeAsTB(item.pool_size),
                    color: colors[i]
                }
            })
            setPieChartDataSplit(pieChartData);
        } else {
            setPieChartDataSplit([]);
        }
    }, [pooledUsagePieSplit])

    useEffect(() => {
        if(!_.isEmpty(pooledUsagePie) || !_.isEmpty(pooledBarChart)) {
            const barData = pooledBarChart?.chartUsage?.series;
            const pieData = pooledUsagePie;

            // Collect unique names from barData and pieData
            const uniqueNames = new Set([
                ...Object.keys(barData),
                ...pieData.map(item => item.organizationName)
            ]);

            // Create color mapping
            const colorMapping = {};
            const colorArray = Array.from(uniqueNames);

            colorArray.forEach((name, index) => {
                colorMapping[name] = colors[index % colors.length];
            });

            const formattedBarRes = Object.entries(barData).map(([name, data]) => ({
                name: name,
                color: colorMapping[name],
                data: data
            }));

            const formattedPieRes = pieData.map(item => ({
                x: item.organizationName,
                totalPoolSize: formatSize(item.totalPoolSize),
                z: formatSize(item.usage),
                y: item.usagePercentage,
                color: colorMapping[item.organizationName]
            }));
            setBarChartData(formattedBarRes);
            setPieChartData(formattedPieRes);
        } else {
            setPieChartData([]);
            setBarChartData([]);
        }
    }, [pooledUsagePie, pooledBarChart])

    const handleChangePieLimit = (e) => {
        const value = e.target.value;
        setPieLimit(value);
    }

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'starlink_plans_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <Grid>
            <TopCards />
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid container columns={12} spacing={1} direction={'row'} className="usage-per-site-pie" style={{marginLeft: '0px'}}>
                <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} >
                    <PooledPieChart pieLimit={pieLimit} handleChangePieLimit={handleChangePieLimit} pieChartData={toggleBtn ? pieChartDataSplit : pieChartData} toggleBtn={toggleBtn} setToggleBtn={setToggleBtn} chartRef={chartRef}/>
                </Grid>
                <Grid item xs={12} sm={8.5} md={8.5} lg={8.5} >
                    <PooledLineChart pieLimit={pieLimit} barChartData={barChartData} toggleBtn={toggleBtn} chartRef={chartRef}/>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: "1%" }}>
                <PooledTable />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    pooledUsagePie: state.starlinkPooled?.pooledUsagePie,
    pooledUsagePieSplit: state.starlinkPooled?.pooledUsagePieSplit,
    pooledBarChart: state.starlinkPooled?.pooledBarChart,
});

export default withRouter(
    connect(mapStateToProps, {
    })(StarlinkPooled)
);