import React, { useState, useEffect, useRef } from "react";
import {
  Grid, CircularProgress, Button, Typography as MyTypography, InputAdornment, IconButton
} from "@material-ui/core";
import { Typography } from "../../components/wrappers/Wrappers";
import TextField from "@mui/material/TextField";
import useStyles from "./styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CustomLoader from "../../components/Loader/CustomLoaders";
import useWindowDimensions from "../../utils/screenWidthHight";
import { Base64 } from "js-base64";
import _ from "lodash";
import "./login.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAPI,generateOtp,loginWith2FAAPI,resetOtpStatus } from "../../actions/Users/authenticateLogin";
import { getUserPolicies, getResourceList, getMaintenanceInfo } from "../../actions/Users/authenticateService";
import DialogBox from "../../components/DialogBoxError";
import CustomAlert from "../../components/CustomAlert";
import JWT from "jsonwebtoken";
import { APP_VERSION } from '../../config';
import { LOGGED_IN_EMAIL, PERMISSIONS, USER_OU, IAM_USER_POLICY_ALL_RESOURCES_KEY, SESSION_TOKEN, CAPTIVE_ACCESS_ENABLED, MAINTENANCE, USER_PREFERENCES } from '../../constants/Constants';
import k4Logo from "../../asset/image/k4Logo.png";
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';
import errorMessageAlert from "../../asset/image/errorMessageAlert.svg";
import k4LogoMobileView from "../../asset/image/k4LogoMobileView.png";
import { getDataFromLocalStorage, setDataToLocalStorage } from "../../utils/util";
import { fetchIsPrivateOu, updateIsReadOnlyUser, updateSelectedOu, updateUserOu, fetchServiceLines } from "../../actions/Users/authenticate";
import favIcon from "../../asset/image/poweredBy.png"
import OTPInput , {ResendOTP} from "otp-input-react";
import { OTP_ERROR } from "../../actions/error_types";
import { useDispatch } from "react-redux";
import { getEncodedURI } from "../../utils/util";
import moment from "moment-timezone";
import { USER_TIMEZONE } from "../../actions/types";

function Login({ history, authReducer, errorReducer, loginAPI, getUserPolicies, updateSelectedOu, updateUserOu, location, getResourceList, generateOtp, loginWith2FAAPI, resetOtpStatus, getMaintenanceInfo, updateIsReadOnlyUser, fetchIsPrivateOu, fetchServiceLines }) {
  const [checked, setChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const { width } = useWindowDimensions();
  const [fullLoader, setFullLoader] = useState(false);
  const classes = useStyles();
  //......
  const [releaseNo, setReleaseNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isImageError, setImageError] = useState(false);
  const [showForgotDialog, setShowForgotDialog] = useState(false);
  const [is2FAEnabled , setIs2FAEnabled] = useState(false);
  const [sessionToken, setIsSessionToken] = useState("");
  const [isOtpGenerationSuccess, setIsOtpGenerationSuccess] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [dialogTitle, setDialogTitle] = useState("K4IAM")
  const [dialogBody, setDialogBody] = useState("You are not authorized to access the K4IAM dashboard.")
  const [dialogHrefText, setDialogHrefText] = useState("")
  const [dialogHrefLink, setDialogHrefLink] = useState("")

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
  },[])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeRemember = (event) => {
    setChecked(event.target.checked);
  }


  useEffect(() => {
    if (window.location && window.location.search) {
      let idParams = window.location.search;
      let id = idParams.replace("?redirectUrl=", "").trim();
      setRedirectUrl(id);
    }
  }, [location]);

  useEffect(() => {
    var loggedInUser = getDataFromLocalStorage("loggedInUser");
    if (loggedInUser && authReducer?.loginUser?.data?.access_token && !_.isEmpty(authReducer?.loginUser?.data?.access_token)) {
      var id_token = JSON.parse(loggedInUser)?.data?.id_token
      if (id_token) {
        try {
          const decodedJwt = JSON.parse(atob(id_token.split(".")[1]));
          if (decodedJwt.exp * 1000 > Date.now()) {
            history.replace("/fleet-dashboard/sites");
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, []);

  useEffect(() => {
    authReducer.loginUser = {};
    authReducer.userPolicy = {};
    var rememberStr = getDataFromLocalStorage("rememberMe");
    if (rememberStr === "true") {
      setUserName(getDataFromLocalStorage("userName"));
      const rememberPassword = getDataFromLocalStorage("password");
      setPassword(Base64.decode(rememberPassword));
    }
  }, []);

  useEffect(() => {
    if(authReducer?.otpGenerationStatus) {
      setIsOtpGenerationSuccess(true);
    }
  },[authReducer?.otpGenerationStatus])

  useEffect(() => {
    if (!_.isEmpty(authReducer.getMaintenanceInfo)) {
      let data = authReducer.getMaintenanceInfo?.data;
      if(data?.rows && Array.isArray(data.rows) && data.rows.length==1){
        let rows = data.rows[0];
        let maintenanceDate = rows[1] ? rows[1]:"";
        let maintenanceFromTime = rows[2] ? rows[2]:"";
        let maintenanceToTime = rows[3] ? rows[3]:"";
        let isBanner = rows[6] ? rows[6] : "";
        let maintenanceMessageAvail = rows[7] ?  rows[7] : "";
        if(maintenanceDate && maintenanceFromTime && maintenanceToTime){
          let d = moment(maintenanceDate, "YYYY-MM-DD");
          let day =  d.format("ddd");
          let date = d.format("Do");
          let month= d.format('MMM');
          let message = "On "+day+" "+date+" "+month+" from "+maintenanceFromTime +" to "+maintenanceToTime+" UTC";
          localStorage.setItem(MAINTENANCE,message)
        }
        if(maintenanceMessageAvail && isBanner === "true") {
          localStorage.setItem("ISBANNER",true)
          localStorage.setItem(MAINTENANCE,maintenanceMessageAvail)
        } else {
          localStorage.setItem("ISBANNER",false)
        }
      }
      authReducer.getMaintenanceInfo = {};
    }
  }, [authReducer.getMaintenanceInfo]);

  useEffect(() => {
    let isAllsite = authReducer.loginUser?.data?.user?.allSitesUser
    let userDetails = authReducer.loginUser?.data?.user?.mfaEnabled;
    let dpId = authReducer.loginUser?.data?.ou?.id;
    localStorage.setItem("isAccountsEnabled",isAllsite);

    // user preferences
    try{
      let userPreferences = authReducer.loginUser?.data?.user?.preferences
      // ex: Asia/Colombo - IST
      let timezone = moment.tz.guess(true) + " - " + moment.tz(moment.tz.guess(true)).format('z')
      if (userPreferences && userPreferences?.timezone) {
        timezone = userPreferences.timezone
      }
      dispatch({ type: USER_TIMEZONE, payload: timezone })
      localStorage.setItem(USER_PREFERENCES, JSON.stringify(userPreferences))
    }catch(error){

    }

    // get maintenance info
    if(dpId){
      getMaintenanceInfo(dpId);
    }

    if (!_.isEmpty(authReducer.loginUser) && !authReducer.loginUser?.data?.user?.mfaEnabled) {
      const loggedInUser = JSON.parse(getDataFromLocalStorage("loggedInUser"));
      if (loggedInUser && loggedInUser.data) {
        var JWTUser = JWT.decode(loggedInUser.data.id_token);
        setDataToLocalStorage(LOGGED_IN_EMAIL, JWTUser.sub);

        //show expiry popup message
        var popupMessage = loggedInUser.data.login_popup_message
        if (popupMessage && popupMessage.length > 0){
          setDialogTitle("Password Expiry Warning")
          setDialogBody(popupMessage)
          setDialogHrefText("Change Password")
          let changePasswordLink = window.location.origin +"/changePassword"
          setDialogHrefLink(changePasswordLink)
          setShowPopUp(true)
        }

        if (loggedInUser.data.ou) {
          setDataToLocalStorage(USER_OU, JSON.stringify(loggedInUser.data.ou));
          updateUserOu(loggedInUser.data.ou);
          updateSelectedOu(loggedInUser.data.ou);
          // update read only user 
          // updateIsReadOnlyUser(true)
        }

        getUserPolicies();
        localStorage.setItem("rememberMe", checked === true);
        if (checked === true) {
          localStorage.setItem("userName", userName);
          localStorage.setItem("password", Base64.encode(password));
        }
        else {
          localStorage.setItem("userName", "");
          localStorage.setItem("password", "");
        }
      }else{
        if(authReducer.loginUser?.errors && authReducer.loginUser?.errors[0]?.message === "Either user name or Password is Incorrect") {
          setFullLoader(false);
          setError({ message: "Invalid login credentials", status: true });
          setErrorMessage("Invalid login credentials")
        } else if (authReducer.loginUser?.errors && authReducer.loginUser?.errors[0]?.message === "Account is locked. Cannot login until the account is unlocked.") {
          setFullLoader(false);
          setError({ message: "Too many invalid attempts, account paused for 10 min", status: true });
          setErrorMessage("Too many invalid attempts, account paused for 10 min")
        } 
        else if (authReducer.loginUser?.errors && authReducer.loginUser?.errors[0]?.message === "Your password is expired. Please click on forgot password to reset your password.") {
          setFullLoader(false);
          setError({ message: "Your password is expired. Please click on forgot password to reset your password.", status: true });
          setErrorMessage("Your password is expired. Please click on forgot password to reset your password.")
        } else if (authReducer.loginUser?.errors && authReducer.loginUser?.errors[0]?.message === "Account is disabled. Cannot login until the account is enabled") {
          setFullLoader(false);
          setError({ message: "Account is disabled. Cannot login until the account is enabled.", status: true });
          setErrorMessage("Account is disabled. Cannot login until the account is enabled.")
        } else {
          setFullLoader(false);
          setError({ message: "Error occured in login. Please try again. Contact admin, if issue persist.", status: true });
          setErrorMessage("Error occured in login. Please try again. Contact admin, if issue persist.")
        }
      }
      authReducer.loginUser = {};
    } else if(authReducer.loginUser?.data?.user?.mfaEnabled  && !authReducer?.otpErrorStatus && authReducer?.loginUser?.data?.access_token === "" ) {
      setIs2FAEnabled(true);
      setFullLoader(false);
      setDataToLocalStorage(SESSION_TOKEN, authReducer.loginUser?.data?.session_token);
      setIsSessionToken(authReducer.loginUser?.data?.session_token);
      if(authReducer.loginUser?.data?.session_token) {
        let payload = {
          userName: userName,
          session_token: authReducer.loginUser?.data?.session_token
        }
        generateOtp(payload)
      }
    } else if(!_.isEmpty(authReducer.loginUser)){
      const loggedInUser = JSON.parse(getDataFromLocalStorage("loggedInUser"));
      if (loggedInUser && loggedInUser.data) {

        //show expiry popup message
        var popupMessage = loggedInUser.data.login_popup_message
        if (popupMessage && popupMessage.length > 0){
          setDialogTitle("Password Expiry Warning")
          setDialogBody(popupMessage)
          setDialogHrefText("Change Password")
          let changePasswordLink = window.location.origin +"/changePassword"
          setDialogHrefLink(changePasswordLink)
          setShowPopUp(true)
        }

        var JWTUser = JWT.decode(loggedInUser.data.id_token);
        setDataToLocalStorage(LOGGED_IN_EMAIL, JWTUser.sub);
        
        if (loggedInUser.data.ou) {
          setDataToLocalStorage(USER_OU, JSON.stringify(loggedInUser.data.ou));
          updateUserOu(loggedInUser.data.ou);
          updateSelectedOu(loggedInUser.data.ou);
        }

        getUserPolicies();
        localStorage.setItem("rememberMe", checked === true);
        if (checked === true) {
          localStorage.setItem("userName", userName);
          localStorage.setItem("password", Base64.encode(password));
        }
        else {
          localStorage.setItem("userName", "");
          localStorage.setItem("password", "");
        }
      }else{
        if(authReducer.loginUser?.errors && authReducer.loginUser?.errors[0]?.message === "Either user name or Password is Incorrect") {
        setFullLoader(false);
        setError({ message: "Invalid login credentials", status: true });
        setErrorMessage("Invalid login credentials")
        } else {
          setFullLoader(false);
          setError({ message: "Too many invalid attempts, account paused for 10 min", status: true });
          setErrorMessage("Too many invalid attempts, account paused for 10 min")
        }
      }
      authReducer.loginUser = {};
    } else {
      setFullLoader(false);
      setIsValidOtp(authReducer?.otpErrorStatus);
    }
  }, [authReducer.loginUser]);

  const redirect = () => {
    let queryString = window.location.search;
    let redirectURL;
    if (queryString && queryString.includes("?redirectURL=")) {
      redirectURL = queryString.substring(queryString.indexOf("=") + 1);
    }

    let userPreferences = authReducer.loginUser?.data?.user?.preferences
    let timezone = moment.tz.guess(true) + " - " + moment.tz(moment.tz.guess(true)).format('z')
    if (userPreferences && userPreferences?.timezone) {
      timezone = userPreferences.timezone
    }
    dispatch({ type: USER_TIMEZONE, payload: timezone })

    // dashboard redirection with internet status filter
    let params = {
      "filterApplied":"true",
      "internetStatus":"Online,Offline",
      "timezone": timezone,
      "sideNav" : true
    }
    let dashboardURLPath = "/fleet-dashboard/sites";

    try {
      let userPreferences = JSON.parse(getDataFromLocalStorage(USER_PREFERENCES))
      if (!_.isEmpty(userPreferences) && userPreferences?.defaultDashboard && "Trends" == userPreferences.defaultDashboard) {
        dashboardURLPath = "/fleet-dashboard/newsummary/sites"
      }
    } catch (error) {

    }

    if (redirectURL) {
      let qString;
      if (redirectURL.indexOf("?") > -1) {
        qString = redirectURL.substring(redirectURL.indexOf("?") + 1);
      }
      redirectURL = new URL(redirectURL).pathname;
      if (redirectURL) {
        if (qString) {
          redirectURL = redirectURL + "?" + qString;
        }
        history.replace(redirectURL);
      } else {
        history.replace({ pathname: dashboardURLPath, search: `?${getEncodedURI(params)}`});
      }
    } else {
      const loggedInUser = JSON.parse(getDataFromLocalStorage("loggedInUser"));
      if(loggedInUser.data.ou?.name === 'K4Mobility') {
        fetchIsPrivateOu()
        delete params.filterApplied;
        delete params.internetStatus;
        history.replace({ pathname: dashboardURLPath, search: `?k4Ids=K4-0620%3AMY-FirstVessel&${getEncodedURI(params)}`});
      } else {
        fetchIsPrivateOu()
        history.replace({ pathname: dashboardURLPath, search: `?${getEncodedURI(params)}`});
      }
      
    }
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.userPolicy) && authReducer?.loginUser?.data?.access_token && !_.isEmpty(authReducer?.loginUser?.data?.access_token)) {
      let retPolicy = authReducer.userPolicy;
      if (PERMISSIONS in retPolicy && retPolicy.permissions.length !== 0) {
        // call resources api store all_resources in local storage, if exists
        if(authReducer?.userPolicy?.root_ou && !_.isEmpty(authReducer?.userPolicy?.root_ou) && authReducer?.userPolicy?.root_ou !== undefined) {
          getResourceList(authReducer?.userPolicy?.root_ou, "", "serviceportal", "ViewVesselSummary", redirect);
          fetchServiceLines(authReducer?.loginUser?.data?.ou?.id);    
        }
        localStorage.setItem(PERMISSIONS, JSON.stringify(retPolicy.permissions));
      } else {
        setDialogTitle("K4IAM")
        setDialogBody("You are not authorized to access the K4IAM dashboard.")
        setShowPopUp(true);
      }

      let captiveAccessEnabledForDP = authReducer.loginUser?.data?.ou?.captiveAccess;
      let PermissionCheck = getDataFromLocalStorage(PERMISSIONS);
      let isAllsite = authReducer.loginUser?.data?.user?.allSitesUser
      PermissionCheck = JSON.parse(PermissionCheck)
      let ViewCaptivePortal = PermissionCheck.find(item => item?.service === "serviceportal")['servicefunctions']?.find(item => item?.function === "ViewCaptivePortal")
      if( captiveAccessEnabledForDP && ViewCaptivePortal?.function == "ViewCaptivePortal" && isAllsite) {
        localStorage.setItem(CAPTIVE_ACCESS_ENABLED, true);
      }

      authReducer.userPolicy = {};
    }
  }, [authReducer.userPolicy]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.message === "User not authorize.") {
        setDialogTitle("K4IAM")
        setDialogBody("You are not authorized to access the K4IAM dashboard.")
        setFullLoader(false);
        setShowPopUp(true);
      } else {
        setFullLoader(false);
        setError({ message: errorReducer.description, status: true });
        setErrorMessage(errorReducer.description)
      }
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleLogin = () => {
    setError(null)
    if (_.isEmpty(userName) || _.isEmpty(password)) {
      setError({ message: "Please enter all the mandatory fields.", status: true });
      setErrorMessage("Please enter all the mandatory fields.")
    } else {
      let payload = {
        userName: userName,
        password: Base64.encode(password),
        applicationName: "Sport"
      };
      loginAPI(payload, redirectUrl);
      setFullLoader(true);
    }
  };

  const handleLoginWith2FA = () => {
    if(OTP) {
      let payload = {
        userName: userName,
        session_token: sessionToken,
        verification_code: OTP
      }
      resetOtpStatus();
      setFullLoader(true);
      loginWith2FAAPI(payload)
    } else {
  
    }
  }

  useEffect(() => {
    if(authReducer?.otpErrorStatus) {
      setIsValidOtp(true)
    } else {
      setFullLoader(false)
    }
    setFullLoader(false)
  }, [authReducer?.otpErrorStatus])

  const handleImgError = (host) => {
    if(host) {
      setImageError(true);
    }
  }

  const handleCancel = () => {
    setOTP("");
    setIsValidOtp(false);
    setIsOtpGenerationSuccess(false)
    if(is2FAEnabled) {
      setIs2FAEnabled(false);
    }
  }

  const handleResendOtp = () => {
    setIsValidOtp(false);
    setIsOtpGenerationSuccess(false);
      let payload = {
        userName: userName,
        session_token: getDataFromLocalStorage(SESSION_TOKEN)
      }
      generateOtp(payload)
  }

  const [OTP, setOTP] = useState("");

  const handleOtpChange = (e) => {
    if(authReducer?.otpErrorStatus) {
      resetOtpStatus();
    }
    const OTP_REGEX = /^[0-9]{1,6}$/
    if (OTP_REGEX.test(e.target.value) || e.target.value === "") {
      setOTP(e.target.value);
      setIsValidOtp(false);
    } else {
      setOTP(OTP)
    }
  }


  const renderButton = (buttonProps) => {
    return (
      <button {...buttonProps}>
        {buttonProps.remainingTime !== 0 ? `Resend Code` : "Resend code"}
      </button>
    );
  };

  const renderTime = () => React.Fragment;

  const [isSvgError, setIsSvgError] = useState(false);
  const [isPngError, setIsPngError] = useState(false);

  const handleSvgError = () => {
    setIsSvgError(true);
  };

  const handlePngError = () => {
    setIsPngError(true);
  };

  const passwordRef = useRef<HTMLInputElement>(null);
  const handleUserNameKeyDown = (e, nextRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      nextRef.current?.focus();
    }
  };
  

  return (
    <Grid container component="main" className={classes.root}>
      {showErrorMsg && errorMsg !== "You are not authorized to access this feature." ? <CustomAlert type="error" msg={errorMsg} /> : ""}
      <Grid item container style={{ height: "100%" }} 
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter' && !is2FAEnabled) {
            handleLogin();
          }
        }}>
        <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.logoContainer}>
          {authReducer.hostName && (
        <img
          onError={isSvgError ? handlePngError : handleSvgError}
          src={!isSvgError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.svg` : (!isPngError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.png` : k4Logo)}
          style={{ width: "50%" }}
          alt="K4 Logo"
        />
      )}
            <Typography className={classes.copyright}>
              {/* <span>Powered by</span>
              <span className="poweredByCover">
                <img className ="poweredbyIcon" src={favIcon} alt={"Poweredby"} />
              </span>
              <span>{APP_VERSION}</span> */}
            </Typography>
          </Grid>
        </Box>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
          {authReducer?.domainConfig?.name && <Typography className={classes.logoCaption}>{authReducer?.domainConfig?.name ? `${authReducer?.domainConfig?.name} ` : `K4 Service Portal`}</Typography>}
            {!is2FAEnabled ? <Typography className={classes.loginText}>Login</Typography>: <Typography className={classes.loginText}>Two Factor Authentication</Typography>}
          </Box>
          <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
            {!is2FAEnabled ? <Grid>
              <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }} />
              <Grid className="loginMobileText">Login</Grid>
            </Grid>: <Grid>
              <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }} />
              <Grid className="loginMobileText">Two Factor Authentication</Grid>
            </Grid>}
            {/* <Typography className={classes.logoCaptionMobile}> K4 Service Portal </Typography> */}
          </Box>
          <Grid className="inputBoxMobileView">
           {!is2FAEnabled && <Box
              sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "592px", marginTop: "40px" } }}
              className="TextBoxMobileView"
            >
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                placeholder="Username"
                size="small"
                value={userName}
                name="userName"
                onChange={handleChange}
                onKeyDown={(e) => handleUserNameKeyDown(e, passwordRef)}
                InputLabelProps={{shrink:true}}
                InputProps={{ className: "labelTextbox" }}
              />
            </Box>}
            {!is2FAEnabled && <Box
              sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "592px", marginTop: "30px" } }}
              className="TextBoxMobileView"
            >
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                placeholder="Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                onChange={handleChange}
                inputRef={passwordRef}
                autoFocus
                InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{
                  className: "labelTextbox",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>}
            {!is2FAEnabled && <Grid container item xs={12} className="inputRootContainer">
              <Grid item container xs={6} sm={6} md={6} lg={6} className="RemeberMeContainer">
                <Grid className="inputRootSubContainer">
                  <Checkbox {...label} className="checkboxRemeberME" onChange={handleChangeRemember}
                    sx={{ color: "#5D647C", '&.Mui-checked': { color: "#264C86" } }} />
                </Grid>
                <Grid>
                  <Typography className={classes.RemeberMe}> Remember me </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className="inputRootContainerForget">
                <Typography className={classes.forgotPass} onClick={() => window.location.href = "/forgot-password"}> Forgot Password? </Typography>
              </Grid>
            </Grid>}
          </Grid>
          {error ? (<Typography className="errorMessageAlert"><img src={errorMessageAlert} className="alertMessageImage" /> {errorMessage} </Typography>) : ""}
          {!is2FAEnabled && <div className={classes.submitBtnWrapper}>
            <Button className="loginButton" onClick={handleLogin} autofocus>
              Login
            </Button>
            {isLoading && (
              <CircularProgress size={26} className={classes.loginLoader} />
            )}
          </div>}
          {is2FAEnabled && <Grid item xs={12} sm={12} md={12} lg={12} className="OtpVerficationContainer">
            {/* {isOtpGenerationSuccess && !isValidOtp ? <span className="otpSuccessMessage">A 6 digit code was sent to your email address {userName}</span> : !isValidOtp ? <span className="otpDefaultMessage">Generating Passcode please wait...</span> : null} */}
            <div className="otpMessageContainer">
              <span className="otpDefaultMessage">A 6 digit code has been sent to your email address <span className="userName">{userName}</span></span>
              <span className="otpDefaultMessage">The code may take a few minutes to arrive</span>
            </div>
            {isValidOtp ? <span className="invalidOTP">Invalid Security code</span>: null}
            <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
              <Grid>
                <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }} />
              </Grid>
              <Typography className={classes.logoCaptionMobile}>{authReducer?.domainConfig?.name ? `${authReducer?.domainConfig?.name} ` : `K4 Service Portal`}</Typography>
            </Box>
            <Grid className="inputBoxMobileView">
              <Box
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "592px", marginTop: "40px" } }}
                className="TextBoxMobileView"
              >
                <TextField
                  className="otPtextField"
                  type="text"
                  variant="outlined"
                  onChange={handleOtpChange}
                  id="outlined-basic"
                  label=""
                  placeholder="Security Code"
                  size="small"
                  value={OTP}
                  name="userName"
                  autoFocus
                />


              </Box>
              <span className="otpDefaultMessage">The code expires in 10 minutes</span>
            </Grid>
            {error ? (<Typography className="errorMessageAlert"><img src={errorMessageAlert} className="alertMessageImage" /> {errorMessage} </Typography>) : ""}
            <div className="verrifyOtpButtonContainer">
              <Button className="loginButton otpVerifyButton" onClick={handleLoginWith2FA} autofocus>
                Verify
              </Button>
              <Button className="loginButton otpVerifyButton" onClick={handleCancel} autofocus>
                Cancel
              </Button>
            </div>
            <ResendOTP className="resendOtp" onResendClick={() => handleResendOtp()} maxTime={60} renderButton={renderButton} renderTime={renderTime}/>
            <div className="errorEmailAccess">If you cannot access your email, contact administrator</div>
          </Grid>}
        </Grid> 
       
          {isLoading && (
                <CircularProgress size={26} className={classes.loginLoader} />
              )}
      </Grid>
      {fullLoader && <CustomLoader showLoader={fullLoader} />}
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle={dialogTitle}
        DialogBody={dialogBody}
        HrefLink={dialogHrefLink}
        HrefText={dialogHrefText}
        ButtonLabel="Done"
        onClick={() => {
          setFullLoader(false)
          setShowPopUp(false)
        }}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    loginAPI,
    getUserPolicies,
    updateSelectedOu,
    updateUserOu,
    getResourceList,
    generateOtp,
    loginWith2FAAPI,
    resetOtpStatus,
    getMaintenanceInfo,
    updateIsReadOnlyUser,
    fetchIsPrivateOu,
    fetchServiceLines
  })(Login)
);
