import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import { SET_HUB_DASHBOARD_HUB_DATA_LOADING, GET_HUB_DASHBOARD_HUB_DATA, SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, GET_HUB_DASHBOARD_HUB_DATA_COUNT, SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, GET_HUB_DASHBOARD_CLIENT_TABLE, SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, GET_HUB_DASHBOARD_SITES_DROPDOWN,
  SET_HUB_DASHBOARD_CLIENT_WISE_LOADING, GET_HUB_DASHBOARD_CLIENT_WISE, SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING, GET_HUB_DASHBOARD_CLIENT_WISE_COUNT, SET_TOPOLOGY_HUB_TABLE, GET_TOPOLOGY_HUB_TABLE, SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE,
  SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, SET_CARRIER_HUB_SDWAN_STATUS, GET_CARRIER_HUB_SDWAN_STATUS, SET_CARRIER_HUB_SDWAN_LATENCY, GET_CARRIER_HUB_SDWAN_LATENCY, SET_CARRIER_HUB_SDWAN_DATA_RATES, GET_CARRIER_HUB_SDWAN_DATA_RATES,
  SET_CARRIER_HUB_AGENT_DROPDOWN, GET_CARRIER_HUB_AGENT_DROPDOWN, SET_CARRIER_HUB_WAN_DROPDOWN, GET_CARRIER_HUB_WAN_DROPDOWN, SET_CARRIER_HUB_SDWAN_USAGE, GET_CARRIER_HUB_SDWAN_USAGE, SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, SET_CARRIER_HUB_SDWAN_HUBS, GET_CARRIER_HUB_SDWAN_HUBS
 } from "../types";
import { getAvilWindow } from "./authenticateDashboard";

export const getIntervalInNewFormat = (interval) => {
  switch (interval) {
    case "15m": return "minute,15";
    case "1h": return "hour,1";
    case "2h": return "hour,2";
    case "6h": return "hour,6";
    case "12h": return "hour,12";
    case "1d": return "day,1";
    case "7d": return "day,7";
    case "30d": return "day,30";
    default: return "minute,15";
  }
}

export const getHubDataTableRow = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD',
    named: true,
    groupBy: 'server_device_id',
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowCount = (dpId, vessels, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, payload: true });
  const count: any = {
    query: 'HUB_DASHBOARD_COUNT',
    named: true,
    parameters: {
      "dp_id": dpId,
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, count)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA_COUNT)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataClietTable = (server_location_id, client_device_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, payload: true });
  const clientData: any = {
    query: 'HUB_DASHBOARD_CLIENT_TABLE',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "client_device_id": client_device_id,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, clientData)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataSitesList = (dpId) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, payload: true });
  const sitesDropdown: any = {
    query: 'HUB_DASHBOARD_SITES_DROPDOWN',
    named: true,
    parameters: {
      "dp_id": dpId
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, sitesDropdown)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_SITES_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowClientWise = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_WISE_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD_CLIENT_WISE',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_WISE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowClientWiseCount = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD_CLIENT_WISE_COUNT',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_WISE_COUNT)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getTopologyHubTableData = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search, siteId, agentId) => (dispatch) => {
  dispatch({ type: SET_TOPOLOGY_HUB_TABLE, payload: true });
  const data: any = {
    query: 'TOPOLOGY_HUB_TABLE',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search,
      "k4Id_filter": `WHERE server_location_id = '${siteId}'`,
      "agent_filter": `AND client_device_id = '${agentId}'`
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_TOPOLOGY_HUB_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDashboardClietTableData = (server_location_id, client_device_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE, payload: true });
  const clientData: any = {
    query: 'HUB_DASHBOARD_CARRIER_CLIENT_TABLE_V2',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "client_device_id": client_device_id,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, clientData)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDashboardServiceTableData = (server_location_id, agent_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, payload: true });
  const serviceTable: any = {
    query: 'HUB_DASHBOARD_TOPOLOGY_SERVICE_VRF',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "agent_id": `AND agent_id = '${agent_id}'`,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, serviceTable)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANStatusChart = (startDate, endDate, agentId, wanNames, interval, hubs) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_STATUS, payload: true });
  const statusData: any = {
    query: 'CARRIER_HUB_SDWAN_STATUS',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "status",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, statusData)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_STATUS)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANLatencyChart = (startDate, endDate, agentId, wanNames, interval, hubs) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_LATENCY, payload: true });
  const latencyData: any = {
    query: 'CARRIER_HUB_SDWAN_LATENCY',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "latency",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, latencyData)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_LATENCY)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANDataRatesChart = (startDate, endDate, agentId, wanNames, interval, hubs) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_DATA_RATES, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_DATA_RATES',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "avgDownSpeed,avgUpSpeed",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_DATA_RATES)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANUsageChart = (startDate, endDate, agentId, wanNames, interval, hubs) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_USAGE, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_USAGE',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "download,upload",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan: any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_USAGE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANHubsChart = (startDate, endDate, agentId, wanNames, interval, hubs) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_HUBS, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_HUBS',
    named: true,
    format: 'csv',
    groupBy: 'hub_name',
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan: any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_HUBS)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubAgentDropdownList = () => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_AGENT_DROPDOWN, payload: true });
  const agents: any = {
    query: 'CARRIER_HUB_AGENT_DROPDOWN',
    named: true,
    format: 'json',
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, agents)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_AGENT_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubWanDropdownList = (agentId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_WAN_DROPDOWN, payload: true });
  const wans: any = {
    query: 'CARRIER_HUB_WAN_DROPDOWN',
    named: true,
    format: 'json',
    parameters: {
      agent_id: agentId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, wans)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_WAN_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubsDropdownList = (startDate, endDate, agentId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, payload: true });
  const hubs: any = {
    query: 'CARRIER_HUB_SDWAN_HUB_DROPDOWN',
    named: true,
    format: 'json',
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, hubs)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};