import _ from "lodash";
import { OTP_ERROR } from "../actions/error_types";
import moment from "moment";
import { THREAT_SEVERITY_ARRAY, WAN_ARRAY } from "../constants/Constants";
import {
  SET_SIDE_MENU,
  SET_USER_OU,
  SET_SELECTED_OU,
  SET_IS_USER_OU_HAS_SUB_OUS,
  SET_PARENT_AND_SUBDP_LIST,
  SET_SELECTED_VESSELS,
  SET_FILTERED_VESSELS,
  SET_HUB_FILTERED_VESSELS,
  SET_SELECTED_VESSEL,
  SET_PERMISSION_OBSERVER,
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_VESSEL,

  SET_FETCH_USERS_LOADING,
  IS_PRIVATE_LOADING,
  IS_PRIVATE_ENABLED,
  FETCH_USERS,

  SET_LOADING,

  INVITE_USER_LOADING,
  INVITE_USER,

  RESEND_INVITE_LOADING,
  RESEND_INVITE,

  GET_ORGANISATIONS,

  SET_VESSEL_PLANS_LOADING,
  GET_VESSEL_PLANS,
  SET_VESSEL_LISTING_LOADING,

  GET_USER_POLICY,

  SET_SEARCH_ALERTS_LOADING,
  SEARCH_ALERTS,

  OU_DERIVED,
  GET_VESSEL_EDGE_INFO,

  SET_FETCH_OU_RESOURCES_LOADING,
  FETCH_OU_RESOURCES,

  OU_POLICY,

  USER_DETACH_LOADING,
  USER_DETACH,

  USER_DELETE_LOADING,
  USER_DELETE,

  SET_TICKETS_LOADING,
  GET_TICKETS,

  DISPLAY_APPLICABLE_USER_POLICIES,
  POLICIES_DETAILS,

  UPDATE_USER_LOADING,
  UPDATE_USER,

  UPDATE_USER_ORGANIZATION_LOADING,
  UPDATE_USER_ORGANIZATION,

  ACCOUNT_CREATE_SUB_DP,
  SET_SUB_DP_CREATE_LOADING,
  SET_DELETE_ORG_LOADING,
  GET_DELETE_ORG,
  SET_MODIFY_ORG_LOADING,
  GET_MODIFY_ORG,
  SET_DP_INFO_LOADING,
  GET_DP_INFO,
  
  GET_TICKET_DETAILS,
  GET_HOT_VESSELS,
  SEND_TICKET_EMAIL,
  GET_TICKET_COMMENTS,
  GET_TICKET_ATTACHMENTS,
  SET_TICKET_ATTACHMENTS_LOADING,
  SET_TICKET_COMMENTS_LOADING,
  GET_SINGLE_TICKET_ATTACHMENTS,
  GET_TICKET_DETAILS_FIELD,
  SET_TICKET_DETAILS_FIELD_LOADING,
  UPDATE_TICKET,
  CREATE_NEW_TICKET,
  ADD_ATTACHMENTS_TO_TICKET,
  SEND_TICKET_COMMENT,
  GET_TICKET_HISTORY,
  SET_TICKET_HISTORY_LOADING,
  SET_TICKET_DETAILS_LOADING,
  GET_TICKET_AGENTS_BY_NAME,
  GET_TICKET_ALL_AGENTS,
  GET_DP_DATA,

  ENABLE_USER_ACCOUNT_LOADING,
  ENABLE_USER_ACCOUNT,

  DISABLE_USER_ACCOUNT_LOADING,
  DISABLE_USER_ACCOUNT,

  SET_VESSEL_ALERTS_LOADING,
  GET_VESSEL_ALERTS,

  SET_VESSEL_DASHBOARD_LOADING,
  GET_VESSEL_SUMMARY_DASHBOARD,

  SET_VESSEL_SUMMARY_LOADING,
  GET_VESSEL_SUMMARY_TABLE,

  GET_VESSEL_LOC_WITH_ALERTS_LOADING,
  GET_VESSEL_LOC_WITH_ALERTS,

  DELETE_ALERT,
  DELETE_ALERT_LOADING,

  GET_DP_ALERTS,
  UPDATE_DP_ALERT,
  UPDATE_DP_ALERT_NOTIFICATION,
  UPDATE_DP_ALERT_STATUS,
  DELETE_DP_ALERT_LINK_AVAILABILITY,
  GET_DP_ALERT_AUDIT_LOGS,
  GET_DP_ALERTS_LOADING,
  UPDATE_DP_ALERT_LOADING,
  UPDATE_DP_ALERT_NOTIFICATION_LOADING,
  UPDATE_DP_ALERT_STATUS_LOADING,
  DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING,
  GET_DP_ALERT_AUDIT_LOGS_LOADING,

  GET_SITE_ALERTS,
  GET_SITE_ALERTS_LOADING,
  UPDATE_SITE_ALERT,
  UPDATE_SITE_ALERT_LOADING,
  UPDATE_SITE_ALERT_NOTIFICATION,
  UPDATE_SITE_ALERT_NOTIFICATION_LOADING,
  DELETE_SITE_ALERT_LINK_AVAILABILITY,
  DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING,
  GET_SITE_ALERT_AUDIT_LOGS,
  GET_SITE_ALERT_AUDIT_LOGS_LOADING,
  UPDATE_SITE_ALERT_STATUS,
  UPDATE_SITE_ALERT_STATUS_LOADING,

  MARK_AS_FIXED_ALERT,
  ALERTS_NAME,
  ALERTS_HISTORY,
  GET_SYSTEM_INVENTORY,
  GET_EDGE_SERVER_STATUS,
  RECOVERY_PASSWORD_USER,
  CHANGE_PASSWORD,

  SET_PASSWORD_User_LOADING,
  SET_PASSWORD_User,

  GET_USER_DETAILS,
  UPDATE_USER_PROFILE,
  USER_PREFERENCES_LOADING,
  UPDATE_USER_PREFERENCES,
  GET_USER_PREFERENCES,
  USER_TIMEZONE,
  RESET_STATE,
  DETACH_RESOURCE_OU,
  NOT_ATTACH_RESOURCE_OU,
  ATTACH_RESOURCE_OU,
  GET_VESSEL_EDGE_TOPOLOGY_PRODUCTS,
  GET_PRODUCT_EDGE_TOPOLOGY_DEVICES,
  GET_PARENT_DEVICE_OF_A_SITE,
  UPDATE_PARENT_DEVICE_OF_A_SITE,
  GET_ORGANISATIONS_UNITS,
  SOFTWARE_PRODUCT,
  SET_SOFTWARE_LISTING_LOADING,
  SOFTWARE_LISTING,
  ALL_TEMPLATE,
  GET_VESSELS_LISTING,
  GET_CONFIGURED_ALERTS_FOR_DEVICE,
  UPDATE_CREATE_DEVICE_ALERT_CONFIGURATION,
  UPDATE_DELETE_DEVICE_ALERT_CONFIGURATION,
  UPDATE_DEVICE_ALERT_CONFIGURATION_SETTINGS,
  GET_VESSEL_SUMMARY_TOPOLOGY,
  SET_LINK_STATUS_PER_DEVICE_LOADING,
  GET_LINK_STATUS_PER_DEVICE,
  GET_SEARCH_DP_LIST,

  GET_SOFTWARE_VERSIONS_DEVICE_COUNT,
  GET_SOFTWARE_VERSION_DEVICE_DETAILS,
  GET_SOFT_VERSION_DEVICE_DETAILS,
  GET_KONNECT_APP_DOWNLOAD_LINKS,
  GET_VX_BUILD_DETAILS,
  GET_DP_DOCUMENTS_LIST,
  
  GET_USER_LOGIN_HISTORY,

  GET_SIM_BOX,
  GET_SIM_BOXES,
  GET_SIM_BOXES_COUNT,
  GET_LTE_PROVIDERS,
  GET_DATA_USAGE,
  GET_DATA_USAGE_COUNT,
  GET_DAILY_DATA_USAGE,
  SET_CELLULAR_DATA_USAGE_LOADING,
  GET_GRAPHICAL_DATA_USAGE,
  GET_CELLULAR_DATA_USAGE,
  GRAPHICAL_DATA_USAGE_LOADING,
  EXPORT_DATA_USAGE_REPORT,
  GET_USAGE_VESSEL_LIST,

  SET_EDGE_EVENTS_LOADING,
  SET_EDGE_EVENTS_FILTER_LOADING,
  SET_EDGE_EVENTS_TABLE_LOADING,
  SET_EDGE_EVENTS_TABLE_COUNT_LOADING,
  GET_EDGE_EVENTS,
  GET_EDGE_EVENTS_FILTER,
  GET_EDGE_SERVER_STATUS_LOADING,
  GET_EDGE_EVENTS_TABLE,
  GET_EDGE_EVENTS_TABLE_COUNT,
  DOWNLOAD_EDGE_EVENTS_TABLE,
  CLEAR_EDGE_EVENTS,

  SET_SITE_CHARTS_RSSI_LOADING,
  SET_SITE_CHARTS_SINR_LOADING,
  SET_SITE_CHARTS_LINK_STATUS_LOADING,
  SET_SITE_CHARTS_LATENCY_JITTER_LOADING,
  SET_SITE_CHARTS_THROUGHPUT_LOADING,
  SET_SITE_CHARTS_VSAT_SNR_LOADING,
  SET_SITE_CHARTS_VSAT_TXPOWER_LOADING,
  SET_SITE_CHARTS_VSAT_POWER_LOADING,
  SET_SITE_CHARTS_VSAT_SYMBOL_RATE_LOADING,
  SET_SITE_CHARTS_SITE_INTERFACES_LOADING,
  SET_SITE_CHARTS_SITE_DATA_RATE_LOADING,
  GET_SITE_RSSI,
  GET_SITE_SINR,
  GET_SITE_DATA_RATE,
  GET_SITE_LINK_STATUS,
  GET_SITE_LATENCY_JITTER,
  GET_SITE_VSAT_SNR,
  GET_SITE_VSAT_TXPOWER,
  GET_SITE_VSAT_POWER,
  GET_SITE_VSAT_SYMBOL_RATE,
  GET_SITE_THROUGHPUT,
  GET_SITE_INTERFACES,
  CLEAR_SITE_CHARTS,
  GET_SITE_CLIENTS,
  GET_SITE_USAGE,
  GET_SITE_SYSTEM_UPTIME,
  SET_SITE_CLIENTS_LOADING,
  SET_SITE_USAGE_LOADING,
  SET_SITE_SYSTEM_UPTIME_LOADING,
  SET_SITE_LAN_STATUS_LOADING,
  GET_SITE_LAN_STATUS,

  SET_INVENTORY_LISTING_LOADING,
  SET_T0_AND_OPEN_EDGE_LOADING,
  GET_TRAFFIC_POLICY_LISTING_LOADING,
  GET_WAN_PROFILE_LISTING_LOADING,
  GET_POP_IPS_LISTING_LOADING,
  APPLY_TRAFFIC_POLICY_LOADING,
  GET_TRAFFIC_POLICY_LOG_LIST_LOADING,
  APPLY_FIREWALL_RULE_LOADING,
  APPLY_WAN_PROFILE_LOADING,
  APPLY_HUB_IP_CONFIG_LOADING,
  COPY_QUOTA_CONFIG_LOADING,
  GET_INVENTORY_DEPLOYED_DATA,
  GET_T0_AND_OPEN_EDGE_DATA,
  DOWNLOAD_INVENTORY_DEPLOYED_DATA,
  GET_INVENTORY_WAREHOUSE_TABLE_DATA,
  INVENTORY_WAREHOUSE_CHANGE_GROUP,
  GET_INVENTORY_WAREHOUSE_VESSEL_LIST,
  SET_ASSIGN_INVENTORY_TO_VESSEL,
  ASSIGN_INVENTORY_TO_VESSEL,
  GET_LICENSE_TYPES,
  GET_DPS_DEFAULT_ACTIVE_CONFIG,
  CREATE_AND_ASSIGN_INVENTORY,
  SET_INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE,
  INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE,
  INVENTORY_DELETE_SITE,
  INVENTORY_TOP_WIDGETS_REGISTERED_COUNT,
  INVENTORY_TOP_WIDGET_KAAS_COUNT,
  INVENTORY_TOP_WIDGETS_WAREHOUSE_COUNT,
  INVENTORY_DOWNLOAD_CONFIG,
  INVENTORY_CHANGE_GROUP,
  GET_CONFIG_PRODUCTS,
  APPLY_GOLDEN_CONFIG,
  APPLY_TRAFFIC_POLICY,
  APPLY_FIREWALL_RULE,
  APPLY_WAN_PROFILE,
  APPLY_HUB_IP_CONFIG,
  GET_TRAFFIC_POLICY_LOG_LIST,
  GET_WAN_PROFILE_LIST,
  GET_POP_IPS_LIST,
  BACKUP_CONFIG_NOW,
  DEVICE_LATEST_EOS_VERSION,
  GET_DEVICE_LIST,
  CREATE_INVENTORY_GROUP,
  UPDATE_INVENTORY_GROUP,
  DELETE_INVENTORY_GROUP,
  GET_TRAFFIC_POLICY_LIST,
  GET_TRAFFIC_POLICY_LIST_V3,
  GET_TRAFFIC_POLICY_LIST_V4,
  GET_TRAFFIC_POLICY_LIST_V5,
  GET_INVENTORY_GROUP_LIST,
  GET_INVENTORY_GROUP_LIST_COUNT,
  GET_SITE_INFO_LIST,
  GET_SITE_INFO_LIST_COUNT,
  GET_BACKUP_CONFIG_DATA,
  COPY_CONFIG_TO_MANUAL_BACKUP,
  DELETE_MANUAL_BACKUP_CONFIG,
  GET_DEFAULT_CONFIG_LISTING,
  EDIT_DEFAULT_CONFIG,
  CREATE_DEFAULT_CONFIG,
  DELETE_DEFAULT_CONFIG,
  GET_DEFAULT_PRODUCTS,
  GET_CONFIG_SOFTWARE_VERSIONS,
  GET_CONFIG_STATUS,
  GET_GROUP_TRANSCATION_CONFIG_STATUS,
  GET_DP_LIST,
  GET_WIDGEY_SUMMARY_DASHBOARD,
  GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM,
  GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD,
  GET_NEW_SUMMARY_USAGE_TABLE,
  GET_VESSEL_SUMMARY_TABLE_COUNT,
  GET_INVENTORY_DEPLOYED_DATA_COUNT,
  DELETE_SITE_CHART_SERIES,
  CLEAR_SITE_CHART_EVENTS,
  UPDATE_NAME,
  VESSEL_ADDRESS_MMSI,
  GET_VESSEL_INFO,
  UPDATE_MMSI_AND_STREETADDRESS,
  SET_DEVICE_HISTORY_LOADING,
  GET_DEVICE_HISTORY,
  SET_DEVICE_CONFIG_ACTION_LOGS_LOADING,
  GET_DEVICE_CONFIG_ACTION_LOGS,
  SET_DEVICE_EOS_HISTORY_EVENTS_LOADING,
  GET_DEVICE_EOS_HISTORY_EVENTS,
  SET_UPGRADE_INVENTORY_LICENSE_LOADING,
  GET_UPGRADE_INVENTORY_LICENSE,
  SET_DEVICE_LICENSE_HISTORY_LOADING,
  GET_DEVICE_LICENSE_HISTORY,
  IS_INVENTORY_PREVIOUS_PAGE,
  SET_UPGRADE_WAREHOUSE_DEVICE_TO_LATEST_EOS_VERSION_LOADING,
  UPGRADE_WAREHOUSE_DEVICE_TO_LATEST_EOS_VERSION,

  HIGH_AVAILABILITY,
  GET_HIGH_AVAILABILITY_DEVICE,
  CREATE_HA,
  SYNC_DEVICE,
  UPDATE_DEVICE,

  SET_ACTIVITY_REPORTS_LOADING,
  SET_ACTIVITY_REPORTS_APPS_LOADING,
  SET_ACTIVITY_REPORTS_VLANS_LOADING,
  SET_ACTIVITY_REPORTS_WIDGET_LOADING,
  SET_ACTIVITY_REPORTS_CATEGORY_PIE_LOADING,
  SET_ACTIVITY_REPORTS_APPLICATION_PIE_LOADING,
  SET_ACTIVITY_REPORTS_USAGE_LINE_CHART_LOADING,
  SET_ACTIVITY_REPORTS_TOP_APP_LINE_CHART_LOADING,
  SET_ACTIVITY_REPORTS_TOP_USERS_LOADING,
  SET_ACTIVITY_REPORTS_TOP_SITES_LOADING,
  GET_ALL_USERNAMES,
  GET_USAGE_WIDGETS,
  GET_USAGE_TABLE_DATA,
  GET_LINE_CHART_USAGE,
  GET_CATEGORY_PIE_CHART_USAGE,
  GET_TOP_APPLICATION_LINE_CHART,
  GET_TOP_SITES_TABLE_DATA,
  GET_TOP_APP_TOP_BLOCKED_PIE_CHART,
  GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION,
  GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_DOMAIN,
  GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_IP,
  SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING,
  SET_ACTIVITY_REPORTS_BOTTOM_TABLE_DOMAIN_LOADING,
  SET_ACTIVITY_REPORTS_BOTTOM_TABLE_IP_LOADING,
  GET_ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE,
  GET_TOP_BLOCKED_APP_BOTTOM_TABLE,
  SET_REPORTS__TOP_BLOCKED_APP_BOTTOM_TABLE_LOADING,
  GET_ALL_APPLICATIONS,
  GET_ALL_VLANS,
  GET_DPI_REPORTS_USER_MAX_RISK_TYPES,
  SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK,
  GET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK,
  GET_DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK,
  SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK,
  GET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK,
  GET_DPI_REPORTS_PIE_CHART_SINGLESITE_RISK,

  CREATE_DOWNLOAD_SERVICE,
  SET_GENERATED_REPORTS_TABLE_LOADING,
  SET_GENERATED_REPORTS_TABLE_COUNT_LOADING,
  GET_GENERATED_REPORTS_TABLE,
  GET_GENERATED_REPORTS_TABLE_COUNT,
  CREATE_DOWNLOAD_SERVICE_FIREWALL,

  AUDIT_LOGS,
  DELETE_HIGH_AVAILABILITY,
  GET_SIM_BOXES_LOADING,
  DOMAIN_NAME,
  DOMAIN_CONFIG,
  GET_FIREALL_WIDGETS,
  GET_FIREWALL_ACTIONS_TODAY_GRAPH,
  GET_FIREWALL_RULE_NAME_GRAPH,
  GET_FIREWALL_TABLE_DATA,
  GET_FIREWALL_RULE_NAME_FILTER,
  GET_FIREWALL_TABLE_DATA_COUNT,
  GET_FIREWALL_LINE_CHART,
  CLEAR_SITE_DASHBOARD,
  SET_LOADING_FIREWALL_COUNTS,
  SET_LOADING_FIREWALL_LINECHART,
  SET_LOADING_FIREWALL_PIE_RULENAME,
  SET_LOADING_FIREWALL_RISTRICT_PIECHART,
  SET_LOADING_FIREWALL_RULE_FILTER,
  SET_LOADING_FIREWALL_TABLE_DATA,
  SET_LOADING_FIREWALL_WIDGETS,
  OTP_GENERATION_STATUS,
  GET_TOP_WIDGET_AVAILABILITY,
  GET_TOP_WIDGET_DATA_NEW_SUMM,

  SET_IAM_LOGIN_HISTORY_TABLE,
  SET_IAM_LOGIN_HISTORY_TABLE_COUNT,
  SET_IAM_LOGIN_HISTORY_TOP_WIDGET,
  SET_IAM_LOGIN_HISTORY_TIMESERIES_CHART,
  SET_IAM_LOGIN_HISTORY_FILTER_USER,
  SET_IAM_LOGIN_HISTORY_FILTER_APP,
  SET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST,
  GET_IAM_LOGIN_HISTORY_TABLE,
  GET_IAM_LOGIN_HISTORY_TABLE_COUNT,
  GET_IAM_LOGIN_HISTORY_TOP_WIDGET,
  GET_IAM_LOGIN_HISTORY_TIMESERIES_CHART,
  GET_IAM_LOGIN_HISTORY_FILTER_USER,
  GET_IAM_LOGIN_HISTORY_FILTER_APP,
  GET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST,
  GET_MAINTENANCE_INFO,
  VERIFY_USER,
  RESET_PASSWORD,
  GET_GROUP_SETTINGS_DATA,
  UPDATE_GROUP_SETTINGS_DATA,

  SET_CF_TOP_USERS_TABLE_LOADING,
  SET_CF_TOP_USERS_TABLE_COUNT_LOADING,
  SET_CF_TOP_SITES_TABLE_LOADING,
  SET_CF_TOP_SITES_TABLE_COUNT_LOADING,
  SET_CF_CATEGORY_PIE_LOADING,
  SET_CF_APPLICATION_PIE_LOADING,
  SET_CF_SUBCAT_PIE_LOADING,
  SET_CF_LINECHART_LOADING,
  SET_CF_TW_TOP_SITE_LOADING,
  SET_CF_TW_TOP_USER_LOADING,
  SET_CF_TW_TOP_CATEGORY_LOADING,
  SET_CF_TW_TOP_DOMAIN_LOADING,
  SET_CF_CATEGORY_LIST_LOADING,
  SET_CF_APP_LIST_LOADING,
  SET_CF_USER_LIST_LOADING,
  SET_CF_VLAN_LIST_LOADING,
  SET_CF_DEVICE_LIST_LOADING,
  SET_CF_SUb_CATEGORY_LIST_LOADING,
  GET_CF_TOP_USERS_TABLE,
  GET_CF_TOP_USERS_TABLE_COUNT,
  GET_CF_TOP_SITES_TABLE,
  GET_CF_TOP_SITES_TABLE_COUNT,
  GET_CF_CATEGORY_PIE,
  GET_CF_APPLICATION_PIE,
  GET_CF_SUBCAT_PIE,
  GET_CF_LINECHART,
  GET_CF_TW_TOP_SITE,
  GET_CF_TW_TOP_USER,
  GET_CF_TW_TOP_CATEGORY,
  GET_CF_TW_TOP_DOMAIN,
  GET_CF_CATEGORY_LIST,
  GET_CF_APP_LIST,
  GET_CF_USER_LIST,
  GET_CF_VLAN_LIST,
  GET_CF_DEVICE_LIST,
  GET_CF_SUB_CATEGORY_LIST,
  UPDATE_NEW_SUMMARY_START_DATE,
  UPDATE_NEW_SUMMARY_END_DATE,
  UPDATE_NEW_SUMMARY_WAN_TYPE,
  GET_DRAWER_OPEN,
       
  SET_DASHBOARD_PIE_USAGEPER_WANTYPE_LOADING,
  SET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE_LOADING,
  SET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_LOADING,
  SET_DASHBOARD_USAGE_PIE_TOP10_APPS_LOADING,
  SET_DASHBOARD_PIE_WAN_USAGE_BYSITE_LOADING,
  SET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK_LOADING,
  SET_DASHBOARD_USAGE_HISTOGRAM_BYSITE_LOADING,
  GET_DASHBOARD_PIE_USAGEPER_WANTYPE,
  GET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE,
  GET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_COUNT,
  GET_DASHBOARD_USAGE_PIE_TOP10_APPS,
  GET_DASHBOARD_PIE_WAN_USAGE_BYSITE,
  GET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK,
  GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY,
  GET_DASHBOARD_USAGE_HISTOGRAM_BYSITE,
  SET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE_LOADING,
  SET_DASHBOARD_UPTIME_LINECHART_BYWAN_LOADING,
  SET_DASHBOARD_UPTIME_PIECHART_BYSITE_LOADING,
  SET_DASHBOARD_UPTIME_LINECHART_BYSITE_LOADING,
  SET_DASHBOARD_UPTIME_PIECHART_BYWAN_LOADING,
  GET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE,
  GET_DASHBOARD_UPTIME_LINECHART_BYWAN,
  GET_DASHBOARD_UPTIME_PIECHART_BYSITE,
  GET_DASHBOARD_UPTIME_LINECHART_BYSITE,
  GET_DASHBOARD_UPTIME_PIECHART_BYWAN,
  GET_CSV_DATA_TO_DOWNLOAD,
  SET_DASHBOARD_ALERT_PIECHART_BYNAME_LOADING,
  SET_DASHBOARD_ALERT_PIECHART_BYSITE_LOADING,
  SET_DASHBOARD_ALERT_LINECHART_BYNAME_LOADING,
  SET_DASHBOARD_ALERT_LINECHART_BYSITE_LOADING,
  GET_DASHBOARD_ALERT_PIECHART_BYNAME,
  GET_DASHBOARD_ALERT_PIECHART_BYSITE,
  GET_DASHBOARD_ALERT_LINECHART_BYNAME,
  GET_DASHBOARD_ALERT_LINECHART_BYSITE,
  SET_DASHBOARD_ALERT_TOPWIDGET_LOADING,
  GET_DASHBOARD_ALERT_TOPWIDGET,
  SET_READONLY_USER,
  CREATE_DOWNLOAD_SERVICE_CONTENT_FILTER,
  SET_VESSEL_WAN_LINK_STATUS_LOADING,
  SPORTS_SITE_SUMMARY_COUNT_LOADING,
  SET_PRIVATE_VESSEL_LIST,
  SET_PRIVATE_VESSEL_LIST_LOADING,
  GET_NEW_SUMMARY_USAGE_TABLE_LOADING,
  GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY_LOADING,
  SET_DASHBOARD_PER_DEVICE_WAN_PROFILES,
  GET_DASHBOARD_PER_DEVICE_WAN_PROFILES,
  SET_DASHBOARD_ADVANCED_BONDING_LOADING,
  GET_DASHBOARD_ADVANCED_BONDING,
  SET_DASHBOARD_LAN_STATUS_LOADING,
  GET_DASHBOARD_LAN_STATUS,
  SET_DASHBOARD_CONNECTED_HUB_LOADING,
  GET_DASHBOARD_CONNECTED_HUB,

  SET_SYSTEM_HEALTH_DEVICES_LOADING,
  GET_SYSTEM_HEALTH_DEVICES,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU_LOADING,
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY_LOADING, 
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK_LOADING,
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS_LOADING,
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP_LOADING,
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP,
  SET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE_LOADING,
  GET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE,
  SET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART_LOADING,
  GET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART,
  FETCH_SERVICE_LINES,
  SET_FLIGHT_SCHEDULE_DATA_LOADING,
  GET_FLIGHT_SCHEDULE_DATA,
  SET_FLIGHT_TAILID_UPDATE_TABLE_LOADING,
  GET_FLIGHT_TAILID_UPDATE_TABLE,
  SET_FLIGHT_TAILID_UPDATE_TABLE_COUNT_LOADING,
  GET_FLIGHT_TAILID_UPDATE_TABLE_COUNT,
  SET_FLIGHT_SCHEDULE_TABLE_LOADING, 
  GET_FLIGHT_SCHEDULE_TABLE,
  SET_FLIGHT_SCHEDULE_TABLE_COUNT_LOADING,
  GET_FLIGHT_SCHEDULE_TABLE_COUNT,

  SET_HUB_DASHBOARD_HUB_DATA_LOADING,
  GET_HUB_DASHBOARD_HUB_DATA,
  SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING,
  GET_HUB_DASHBOARD_HUB_DATA_COUNT,
  SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING,
  GET_HUB_DASHBOARD_CLIENT_TABLE,
  SET_HUB_CLIENT_SDWAN_FLOWS_LOADING,
  GET_HUB_CLIENT_SDWAN_FLOWS,
  SET_HUB_CLIENT_SDWAN_SPEED_LOADING,
  GET_HUB_CLIENT_SDWAN_SPEED,
  SET_HUB_CLIENT_SDWAN_LATENCY_LOADING,
  GET_HUB_CLIENT_SDWAN_LATENCY,
  SET_HUB_CLIENT_SDWAN_STATUS_LOADING,
  GET_HUB_CLIENT_SDWAN_STATUS,
 UPDATE_NEW_SUMMARY_SERVICE_LINE,
 NEW_SUMMARY_SERVICE_ACCOUNTS,
 UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS,
 UPDATE_NEW_SUMMARY_SERVICE_LINE_QUERY_PARAM,
 SET_SDWAN_SPEEDTEST_LOADING,
 GET_SDWAN_SPEEDTEST,
 SET_SITE_LINK_PROBE_NATIVE_LOADING,
 GET_SITE_LINK_PROBE_NATIVE,
 SET_SITE_LINK_PROBE_VTP_LOADING,
 GET_SITE_LINK_PROBE_VTP,
 SET_SDWAN_QOE_STREAMING_LOADING,
 GET_SDWAN_QOE_STREAMING,
 SET_SDWAN_QOE_RTC_LOADING,
 GET_SDWAN_QOE_RTC,
 SET_SDWAN_QOE_HMAP_LOADING,
 GET_SDWAN_QOE_HMAP,
 SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING,
 GET_HUB_DASHBOARD_SITES_DROPDOWN,
 SET_HUB_DASHBOARD_CLIENT_WISE_LOADING,
 GET_HUB_DASHBOARD_CLIENT_WISE,
 SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING,
 GET_HUB_DASHBOARD_CLIENT_WISE_COUNT,
 SET_TOPOLOGY_HUB_TABLE,
 GET_TOPOLOGY_HUB_TABLE,
 SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE,
 GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE,
 GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, 
 SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE,
 SET_CARRIER_HUB_SDWAN_STATUS,
 GET_CARRIER_HUB_SDWAN_STATUS,
 SET_CARRIER_HUB_SDWAN_LATENCY,
 GET_CARRIER_HUB_SDWAN_LATENCY,
 SET_CARRIER_HUB_SDWAN_DATA_RATES,
 GET_CARRIER_HUB_SDWAN_DATA_RATES,
 SET_CARRIER_HUB_SDWAN_USAGE,
 GET_CARRIER_HUB_SDWAN_USAGE,
 SET_CARRIER_HUB_SDWAN_HUBS,
 GET_CARRIER_HUB_SDWAN_HUBS,
 SET_CARRIER_HUB_AGENT_DROPDOWN,
 GET_CARRIER_HUB_AGENT_DROPDOWN,
 SET_CARRIER_HUB_WAN_DROPDOWN,
 GET_CARRIER_HUB_WAN_DROPDOWN,
 SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN,
 GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN,

 GET_THREAT_DASHBOARD_TOPWIDGET,
 SET_THREAT_DASHBOARD_TOPWIDGET_LOADING,
 GET_TOP_10_THREAT_BY_NAME,
 SET_TOP_10_THREAT_BY_NAME_LOADING,
 GET_TOP_10_THREAT_BY_SEVERITY,
 SET_TOP_10_THREAT_BY_SERVERITY_LOADING,
 GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY,
 GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING,
 GET_TOP_10_THREAT_TABLE_DATA,
 SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING,
 GET_ALL_THREAT_TABLE_DATA,
 SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING,
 UPDATE_NEW_SEVERITY_TYPE,
 GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL,
 SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING,
 GET_THREAT_DEVICE_LIST,
 GET_THREAT_DEVICE_LIST_LOADING,
 GET_THREAT_SITE_LIST,
 GET_THREAT_SITE_LIST_LOADING,
 GET_USER_THREAT_TABLE_DATA,
 SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING,
 SET_THREAT_FILTERS,
 GET_THREAT_UNIQUE_DEVICE_LIST_LOADING,
 GET_THREAT_UNIQUE_DEVICE_LIST,
 GET_THREAT_UNIQUE_IP_LIST,
 GET_THREAT_UNIQUE_CLASS_LIST,
 GET_THREAT_UNIQUE_THREATNAME_LIST_LOADING,
 GET_THREAT_UNIQUE_THREATNAME_LIST,
 GET_THREAT_UNIQUE_IP_LIST_LOADING,
 GET_THREAT_UNIQUE_CLASS_LIST_LOADING,
 GET_THREAT_UNIQUE_ACTION_LIST,
 GET_THREAT_UNIQUE_ACTION_LIST_LOADING,
 GET_THREAT_ACTION_PIE_DATA,
 SET_THREAT_ACTION_PIE_DATA_LOADING,
 SET_THREAT_SEVERITY_CLASS_LIST_LOADING,
 GET_THREAT_SEVERITY_CLASS_LIST,
 SET_THREAT_KONTROL_LOADING,
 CREATE_THREAT_KONTROL, 
 UPDATE_THREAT_KONTROL, 
 DELETE_THREAT_KONTROL,
 SET_THREATS_LOADING,
 GET_THREATS,
 SET_THREATNAMES_LIST_LOADING,
 GET_THREATNAMES_LIST,
 SET_SNORT_RULES_DETAILS,
 GET_SNORT_RULES_DETAILS,
 
 SET_CAPTIVE_USAGE_PIE_DATA_LOADING,
 GET_CAPTIVE_USGAE_PIE_DATA,
 GET_CAPTIVE_SUBS_PIE_DATA,
 SET_CAPTIVE_SUBS_PIE_DATA_LOADING,

 SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET,
 GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET,
 SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET,
 GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET,
 SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET,
 GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET,
 SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR,
 GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR,
 SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART,
 GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART,
 SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART,
 GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART,
 SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART,
 GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART,
 SET_CAPTIVE_DASHBOARD_SITES_TABLE,
 GET_CAPTIVE_DASHBOARD_SITES_TABLE,
 SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR,
 GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR,
 SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR,
 GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR,

 SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT_LOADING,
 GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT,
 SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING,
 GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE,
 SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_LOADING,
 GET_OPERATIONAL_USAGE_TIMESERIES_TABLE,
 SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT_LOADING,
 GET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT,
 SET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE_LOADING,
 GET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE,
 SET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST_LOADING,
 GET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST,
 SET_OPERATIONAL_USAGE_FILTER_SW_LIST_LOADING,
 GET_OPERATIONAL_USAGE_FILTER_SW_LIST,
 SET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE_LOADING,
 GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE,

 SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE,
 GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE,
 SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE,
 GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE,
 SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE,
 GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE,
 SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR,
 GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR,
 SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR,
 GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR,
 SET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR,
 GET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR,
 SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART,
 GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART,
 SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST,
 GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST,
 SET_CAPTIVE_DASHBOARD_PLANS_LIST,
 GET_CAPTIVE_DASHBOARD_PLANS_LIST,
 SET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE,
 GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE,
 SET_CAPTIVE_DASHBOARD_EVENTS_TABLE,
 GET_CAPTIVE_DASHBOARD_EVENTS_TABLE,
 SET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST,
 GET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST
} from "../actions/types";
import { CHARTS_METRICS } from "../constants/Constants";

const initialState = {
  sideMenu: { menuItem: "dashboard" },
  userOu: null,
  selectedOu: null,
  isUserOuHasSubOus : false,
  parentDpAndSubDpList: {},
  selectedVessels: { isAll: false, vessels: [] },
  filteredVessels: [],
  hubFilteredVessels: [],
  selectedVessel: { vessel: null },
  permissionObserver: {
    isMultiVessel: false,
    isSingleVessel: false,
    permission: null
  },
  loginUser: {},
  logoutUser: {},
  fetchVessel: {},

  usersListLoading: false,
  usersList: null,

  vesselPlansLoading: false,
  vesselPlans: {},

  loading: false,

  inviteUserLoading: false,
  inviteUser: {},

  resendInviteLoading: false,
  resendInvite: {},

  getOrganisationList: {},
  userPolicy: {},

  searchAlertsLoading: false,
  searchAlerts: {},

  vesselEdgeInfo: {},

  fetchResourcesLoading: false,
  fetchResources: {},
  fetchServiceLines: {},

  organizationpolicy: {},

  userDetachLoading: false,
  userdetch: {},

  userDeleteLoading: false,
  userdelete: {},

  accountCreateSubDP: {},
  setSubDPCreateLoading: false,
  setDeleteOrgLoading: false,
  setModifyOrgLoading: false,
  setDpInfoLoading: false,
  accountDeleteOrg: {},
  accountModifyOrg: {},
  getDpInfo: {},
  
  getTicketsLoading: false,
  getTickets: {},

  displayApplicableUserPolicies: {},
  policiesDetails: {},

  updateUserLoading: false,
  UpdateUser: {},

  updateUserOrganizationLoading: false,
  updateUserOrganization: {},

  fetchOu: {},
  getTicketDetails: {},
  getTicketComments: {},
  getSingleTicketAttachments: {},
  updateTicket: {},
  createNewTicket: {},
  addAttachmentsToTicket: {},
  sendTicketEmail: {},
  sendTicketComment: {},
  getTicketHistory: {},
  getHotVessels: {},
  getTicketAgentsByName: {},
  getTicketAllAgents: {},
  getDpAddressData:{},

  enableUserAccountLoading: false,
  enableUserAccount: {},

  disableUserAccountLoading: false,
  disableUserAccount: {},

  vesselAlertsLoading: false,
  vesselAlerts: {},

  deleteAlert: {},
  deleteAlertLoading: false,

  dpAlerts: {},
  updateDpAlert: {},
  updateDpAlertNotification: {},
  updateDpAlertStatus: {},
  deleteDpAlertLinkAvailability: {},
  dpAlertAuditLogs: {},
  dpAlertsLoading: false,
  updateDpAlertLoading: false,
  updateDpAlertNotificationLoading: false,
  updateDpAlertStatusLoading: false,
  deleteDpAlertLALoading: false,
  dpAlertLogsLoading: false,

  siteAlerts: {},
  updateSiteAlert: {},
  updateSiteAlertNotification: {},
  deleteSiteAlertLinkAvailability: {},
  siteAlertAuditLogs: {},
  siteAlertsLoading: false,
  updateSiteAlertLoading: false,
  updateSiteAlertNotificationLoading: false,
  deleteSiteAlertLALoading: false,
  siteAlertLogsLoading: false,
  updateSiteAlertStatus: {},
  updateSiteAlertStatusLoading: false,

  vesselDashboardLoading: false,
  vesselWanLinkStatusLoading: false,
  vesselDashboardSummary: {},
  widgetDashboardSummary: {},
  topWidgetLinkStatus:{},
  widgetLinkStatusDashboardSummary: {},

  vesselSummaryLoading: false,
  vesselSummaryTableData: {},
  vesselSummaryTableDataCount: null,

  vesselLocWithAlertsLoading: false,
  vesselLocWithAlerts: {},

  markAsFixedAlert: {},
  alertnames: {},
  alertHistory: {},
  inventory: {},

  edgeServerStatus: {},
  edgeServerStatusLoading: false,

  recoveryPasswordUser: {},
  changePassword: {},

  setPasswordUserLoading: false,
  setPasswordUser: {},

  userDetails: {},
  verifyUser: {},
  resetPassword: {},

  updateUserProfile: {},
  userPreferencesLoading: false,
  updateUserPreferences: {},
  getUserPreferences : {},
  detachResource: {},
  notAttachedOuResources: {},
  attachResource: {},
  vesselEdgeTopologyProducts: {},
  productEdgeTopologyDevices: {},
  parentDeviceOfSite: {},
  updateParentDeviceOfSite: {},
  softwareProduct: {},

  softwareListingLoading: false,
  softwareListing: {},
  konnectAppDownloadLinks : {},
  vxBuildDetails : {},
  dpDocumentsList : {},

  allTemplate: {},
  getConfiguredAlertsForDevice: {},
  createDeviceAlertConfiguration: {},
  deleteDeviceAlertConfiguration: {},
  updateDeviceAlertConfiguration: {},
  getOrganisationUnitList: {},
  getVesselsListing: {},
  setVesselListingLoading: false,
  vesselSummaryTopology: {},
  getSoftwareVersionsDeviceCount : {},
  getSoftwareVersionDeviceDetails : {},
  getSoftVersionDeviceDetails: {},
  getSearchDpList: {},

  getEdgeEventsLoading: false,
  getEdgeEventsFilterLoading: false,
  getEdgeEventsTableLoading: false,
  getEdgeEventsTableCountLoading: false,
  getEdgeEvents: {},
  getEdgeEventsFilter: {},
  getEdgeEventsTable: {},
  getEdgeEventsTableCount: {},
  downloadEdgeEventsTable: {},

  siteChartsRSSILoading: false,
  siteChartsSINRLoading: false,
  siteChartsLinkStatusLoading: false,
  siteChartsLatencyJitterLoading: false,
  siteChartsThroughputLoading: false,
  siteChartsVSATSnrLoading: false,
  siteChartsVSATyTXPowerLoading: false,
  siteChartsVSATPowerLoading: false,
  siteChartsVSATSymbolRateLoading: false,
  siteChartsSiteInterfacesLoading: false,
  siteChartsSiteDataRateLoading: false,
  siteChartsSiteClientsLoading: false,
  siteChartsSiteUsageLoading: false,
  siteChartsSiteSystemUptimeLoading: false,
  siteChartsSiteLanStatusLoading: false,
  siteChartsSiteFlowsLoading: false,
  siteChartsSiteSpeedLoading: false,
  siteChartsSiteLatencyLoading: false,
  siteChartsSiteStatusLoading: false,
  siteChartsSiteSpeedtestLoading: false,

  getSiteRSSI: {data: {rssiAvg: {series: {}}}},
  getSiteSINR: {data: {sinrAvg: {series: {}}}},
  getSiteDataRate: {data: {rateAvg: {series: {}}}},
  getSiteLinkStatus: {data: {probeSuccess: {series: {}}}},
  getSiteLatencyJitter: {data: {latency: {series: {}}, jitter: {series: {}}}},
  getSiteThroughput: {data: {avgDownSpeed: {series: {}}, avgUpSpeed: {series: {}}}},
  getSiteVSATSnr: {data: {avg: {series: {}}}},
  getSiteVSATTxpower: {data: {avg: {series: {}}}},
  getSiteVSATPower: {data: {avg: {series: {}}}},
  getSiteVSATSymbolRate: {data: {avg: {series: {}}}},
  getSiteClients: {data: {clients: {series: {}}}},
  getSiteUsage: {data: {totalUsage: {series: {}}, downUsage: {series: {}}, upUsage: {series: {}}}},
  getSiteSystemUptime: {data: {status: {series: {}}}},
  getSiteLanStatus: {data: {LAN_status: {series: {}}}},
  getSiteFlows: {data: {flows: {series: {}}}},
  getSiteSpeed: {data: {avgDownSpeed: {series: {}}, avgUpSpeed: {series: {}}}},
  getSiteLatency: {data: {latency: {series: {}}}},
  getSiteStatus: {data: {status: {series: {}}}},
  getSiteSpeedtest: {},
  getSiteLinkProbeNative: {data: {probeSuccess: {series: {}}}},
  getSiteLinkProbeVtp: {data: {probeSuccess: {series: {}}}},
  getSiteQoeStreaming: {data: {QoEStreamingPoints: {series: {}}}},
  getSiteQoeRtc: {data: {QoEGamingPoints: {series: {}}}},
  getSiteInterfaces: {},

  getUserLoginHistory: {},
  getDataUsage:{},
  getDataUsageCount: {},
  getDailyDataUsage: {},
  getGraphicalDataUsage:{},
  setCellularDataUsageLoading: false,
  getCellularDataUsage: {},
  graphicalDataUsageLoading: false,
  getUsageVesselList:{},
  exportDataUsageReport:{},

  inventoryListingLoading :false,
  trafficPolicyListingLoading: false,
  wanProfileListingLoading: false,
  applyTrafficPolicyLoading: false,
  applyFirewallRuleLoading: false,
  applyHubIPConfigLoading: false,
  copyQuotaConfigLoading: false,
  trafficPolicyLogListLoading: false,
  getInventoryDeployedData: {},
  downloadnventoryDeployedData: {},
  getInventoryWarehouseTableData: {},
  inventoryWarehouseChangeGroupData: {},
  getInventoryWarehouseVesselList: {},
  assignInventoryToVessel: {},
  getLicenseTypes : {},
  getDpsDefaultActiveConfig : {},
  createVessel: {},
  getDPList: {},
  getInventoryDeployedDataCount: {},
  inventoryAssignDeviceToWarehouse : {},
  inventoryDeleteSite :{},
  inventoryTopWidgetsRegisteredCount : {},
  inventoryTopWidgetsKaasCount: {},
  inventoryTopWidgetsWarehouseCount : {},
  inventoryDownloadConfig: {},
  inventoryChangeGroup: {},
  getConfigProducts: {},
  applyGoldenConfig: {},
  backupConfigNow:{},
  deviceLatestEosVersion :{},
  getDeviceList:{},
  createInventoryGroup:{},
  updateInventoryGroup:{},
  deleteInventoryGroup:{},
  getInventoryGroupList:{},
  getInventoryGroupListCount:{},
  getSiteInfoList: {},
  getSiteInfoListCount : {},
  getTrafficPolicy: {},
  getTrafficPolicyV3: {},
  backupConfigData: {},
  copyConfigToManualBackup: {},
  deleteManualBackupConfig: {},
  getDefaultConfigListing: {},
  editDefaultConfig: {},
  createDefaultConfig: {},
  deleteDefaultConfig: {},
  getDefaultProducts: {},
  getConfigSoftwareVersions: {},
  getConfigStatus: [],
  getConfigStatusTotalCount: 0,
  getGroupTranscationConfigStatus: [],
  getGroupTranscationConfigStatusTotalCount: 0,
  linkStatusPerDeviceLoading: false,
  linkStatusPerDevice: {},
  vesselAddressMMSI: {},
  getVesselInfo: {},
  updateMMSIAndStreetAddress: {},
  setDeviceHistoryLoading: false,
  deviceHistory: {},
  deviceConfigActionLogsLoading: false,
  deviceConfigActionLogs: {},
  deviceEosHistoryEventsLoading: false,
  deviceEosHistoryEvents: {},
  deviceLicenseHistoryLoading: false,
  deviceLicenseHistory: {},
  setUpgradeInventoryLicenseLoading: false,
  inventoryUpgradeLicense: {},
  isInventoryPreviousPage: false,
  upgradeWarehouseDeviceToLatestEosVersionLoading : false,
  upgradeWarehouseDeviceToLatestEosVersion: {},

  highAvailability: {},
  getHighAvailabilityDevice: {},
  createHighAvailability: {},
  syncDevice: {},
  updateDevice: {},

  activityReportsLoading: false,
  activityReportsAppsLoading: false,
  activityReportsVlansLoading: false,
  activityReportsWidgetLoading: false,
  activityReportsCategoryPieLoading: false,
  activityReportsApplicationPieLoading: false,
  activityReportsUsageLineChartLoading: false,
  activityReportsTopAppLineChartUsage: false,
  activityReportsTopUsersLoading: false,
  activityReportsTopSitesLoading: false,
  getAllUsernames: {},
  UsageWidgetsData: {},
  UsageTableData: {},
  getLineChartUsage: {},
  CategoryPieChartUsageData: {},
  getTopApplicationLineChart: {},
  TopSitesTableData: {},
  TopAppTopBlockedPieChartData: {},
  getTopDownloadUploadBlockedApplication: {},
  getTopDownloadUploadBlockedDomain: {},
  getTopDownloadUploadBlockedIp: {},
  getContentFiltersTable: {},
  setActivityReportsBottomTableLoading: false,
  setActivityReportsBottomTableDomainLoading: false,
  setActivityReportsBottomTableIPLoading: false,
  setActivityReportsTopBlockedAppBottomTableLoading: false,
  getAllApplications: {},
  getAllVlans: {},
  riskList: {},
  setTopWidgetRiskScore: false,
  topWidgetAllSiteRiskScore: {},
  topWidgetSingleSiteRiskScore: {},
  setPieChartAllSiteRisk: false,
  getPieChartAllSiteRisk: {},
  getPieChartSingleSiteRisk: {},

  setOperationalUsageDataTableLoading: false,
  getOperationalUsageDataTable: {},
  setOperationalUsageDataTableCountLoading : false,
  getOperationalUsageDataTableCount: {},

  setOperationalUsageTimeseriesTableLoading: false,
  getOperationalUsageTimeseriesTable: {},
  setOperationalUsageTimeseriesTableCountLoading: false,
  getOperationalUsageTimeseriesTableCount: {},
  setOperationalUsageDataSingleSitePerWanWiseLoading: false,
  getOperationalUsageDataSingleSitePerWanWise: {},
  setOperationalUsageFilterDeviceListLoading: false,
  getOperationalUsageFilterDeviceList: {},
  setOperationalUsageFilterSWListLoading: false,
  getOperationalUsageFilterSWList: {},
  setOperationalUsageReportsDownloadLoading: false,
  getOperationalUsageReportsDownload: {}, 

  createDownloadService: {},
  setGeneratedReportsTableLoading: false,
  setGeneratedReportsTableCountLoading: false,
  getGeneratedReportsTable: {},
  getGeneratedReportsTableCount: 0,
  createDownloadServiceFirewall: {},
  createDownloadServiceContentFilter: {},

  auditLogs: {},
  deleteHighAvailability: {},

  getSimBoxes: {},
  getSimBoxesCount: {},
  getSimBoxesLoading: false,
  hostName: null,
  domainConfig : {},

  getFirewallWidgets: {},
  getFirewallActiveToday: {},
  getFirewallRuleName: {},
  getFirewallRuleNameFilter: {},
  getFirewallTableDataCount: {},
  getFirewallTableData: {},
  getFirewallLineChart: {},
  setFirewallWidgetsLoading: false,
  setFirewallActiveTodayLoading: false,
  setFirewallRuleNameLoading: false,
  setFirewallRuleNameFilterLoading: false,
  setFirewallTableDataCountLoading: false,
  setFirewallTableDataLoading: false,
  setFirewallLineChartLoading: false,

  otpGenerationStatus: false,
  otpErrorStatus: false,

  setTopWidgetAvailability: {},
  setTopWidgetData:{},

  setIamLoginHistoryTable: false,
  setIamLoginHistoryCountLoading: false,
  setIamLoginHistoryTopWidget: false,
  setIamLoginHistoryTimeseriesChart: false,
  setIamLoginHistoryFilterUser: false,
  setIamLoginHistoryFilterApp: false,
  setIamLoginHistoryFilterDescList: false,
  getIamLoginHistoryTable: {},
  getIamLoginHistoryCountLoading: {},
  getIamLoginHistoryTopWidget: {},
  getIamLoginHistoryTimeseriesChart: {},
  getIamLoginHistoryFilterUser: {},
  getIamLoginHistoryFilterApp: {},
  getIamLoginHistoryFilterDescList: {},

  getMaintenanceInfo: {},
  getGroupSettingsData: {},

  setContentFilterTopUsersTableLoading: false,
  setContentFilterTopUsersTableCountLoading: false,
  setContentFilterTopSitesTableLoading: false,
  setContentFilterTopSitesTableCountLoading: false,
  setContentFilterTableCountLoading: false,
  setContentFilterCategoryPieLoading: false,
  setContentFilterApplicationPieLoading: false,
  setContentFilterSubCategoryPieLoading: false,
  setContentFilterLinechartLoading: false,
  setContentFilterTopWidgetTopSiteLoading: false,
  setContentFilterTopWidgetTopUserLoading: false,
  setContentFilterTopWidgetTopCategoryLoading: false,
  setContentFilterTopWidgetTopDomainLoading: false,
  setContentFilterCategoryListLoading: false,
  setContentFilterAppListLoading: false,
  setContentFilterUserListLoading: false,
  setContentFilterVlanListLoading: false,
  setContentFilterDeviceListLoading: false,
  steContentFilterSubCategoryListLoading: false,
  getContentFilterTopUsersTable: {},
  getContentFilterTopUsersTableCount: {},
  getContentFilterTopSitesTable: {},
  getContentFilterTopSitesTableCount: {},
  getContentFilterTableCount: {},
  getContentFilterCategoryPie: {},
  getContentFilterApplicationPie: {},
  getContentFilterSubCategoryPie: {},
  getContentFilterLinechart: {},
  getContentFilterTopWidgetTopSite: {},
  getContentFilterTopWidgetTopUser: {},
  getContentFilterTopWidgetTopCategory: {},
  getContentFilterTopWidgetTopDomain: {},
  getContentFilterCategoryList: {},
  getContentFilterAppList: {},
  getContentFilterUserList: {},
  getContentFilterVlanList: {},
  getContentFilterDeviceList: {},
  getContentFilterSubCategoryList: {},
  newSummaryStartDate: moment().subtract(60, 'minutes').utc(),
  newSummaryEndDate: moment().utc(),
  newSummaryWanType:WAN_ARRAY && WAN_ARRAY[0],
  newSummaryServiceLine: ["All"],
  newSummaryServiceAccount: ["All"],
  selectedServiceAccount: ["All"],
  getDrawerOpen: true,
  setDashboardPieUsageperWantypeLoading: false,
  setDashboardHistogramUsagePerWantypeLoading: false,
  setDashboardHistogramUsagePerSiteLoading:false,
  setDashboardUsagePieTop10AppsLoading: false,
  setDashboardPieWanUsageBySiteLoading: false,
  setDashboardUsageHistogramUpDownLinkLoading: false,
  setDashboardUsageHistogramBySiteLoading: false,
  setDashboardUptimeSiteStatisticsTableLoading: false,
  setDashboardUptimeLinechartByWanLoading: false,
  setDashboardUptimeLinechartBySiteLoading: false,
  setDashboardUptimePiechartBySiteLoading: false,
  setDashboardUptimePiechartByWanLoading: false,
  newUsageperWantype: {},
  newHistogramUsageperWantype: {},
  newHistogramBySiteCount:{},
  newUsagePieTop10Apps: {},
  wanUsageBySites: {},
  newSummaryUsageTable: {},
  newSummaryUsageTableLoading: false,
  newSummaryUploadDownload: {},
  newSummaryUptimeAllLinks:{},
  newSummaryUptimeAllLinksLoading: false,
  newSummaryHistogramBySite: {},
  newSummaryUptimeTable: {},
  newSummaryUptimeLinechart: {},
  newSummaryUptimePiechart: {},
  newSummaryUptimeLineChartBySite: {},
  newSummaryUptimePiechartByWan: {},
  csvDataDownloded: [],
  csvDownloadId: '',
  csvDownloadLoading: false,
  newSummaryAlertPieChartByNameLoading: false,
  newSummaryAlertPieChartBySiteLoading: false,
  newSummaryAlertLineChartByNameLoading: false,
  newSummaryAlertLineChartBySiteLoading: false,
  newSummaryAlertTopWidgetLoading: false,
  alertPieChartByName: {},
  alertPieChartBySite: {},
  alertLineChartByName: {},
  alertLineChartBySite: {},
  alertTopWidget: {},
  isReadOnlyUser: false,
  privateVesselList: {},
  privateVesselListLoading: false,
  isPrivateLoading: false,
  isPrivateEnabled: false,
  setWanProfilePerDeviceLoading: false,
  wanProfilesPerDevice: {},
  setAdvancedBondingLoading: false,
  getAdvancedBonding: {},
  setLANStatusLoading: false,
  getLANStatus: {},
  setConnectedHubLoading: false,
  getConnectedHub: {},

  setSystemHealthDevicesLoading: false,
  setSystemHealthCpuChartLoading: false,
  setSystemHealthMemoryChartLoading: false,
  setSystemHealthContrackChartLoading: false,
  setSystemHealthRxDropsChartLoading: false,
  setSystemHealthSwapChartLoading: false,
  setSystemHealthTemperatureChartLoading: false,
  setSystemHealthDiskStorageChartLoading: false,
  systemHealthDevices: {},
  systemHealthCpuChart: {},
  systemHealthMemoryChart: {},
  systemHealthContrackChart: {},
  systemHealthRxDropsChart: {},
  systemHealthSwapChart: {},
  systemHealthTemperatureChart: {},
  systemHealthDiskStorageChart: {},

  setHubDataLoading: false,
  hubDataTableRow: {},
  setHubDataCountLoading: false,
  hubDataTableRowCount: {},
  setHubDataClientTableLoading: false,
  hubDataClientTable: {},
  hubDataSitesDropdownLoading: false,
  getHubVesselListing: {},

  threatTopWidgetData: {},
  threatTopCrossBarData: {},
  threatTopCrossBarDataLoading: false,
  threatTopWidgetLoading: false,
  topThreatServerityData: {},
  topThreatServerityDataLoading: false,
  threatBySeverityBarChart: {},
  theatBySeverityBarChartLoading: false,
  top10ThreatTableData: {},
  top10ThreatTableDataLoading: false ,
  allThreatTableData: {},
  allThreatTableDataLoading: false ,
  newSummarySeverityType: THREAT_SEVERITY_ARRAY && THREAT_SEVERITY_ARRAY[0],
  topwidgetTotalLoading: false,
  topwidgetTotal: {},
  allThreatSiteList: {},
  allThreatSiteListLoading: false,
  allThreatDeviceList: {},
  allTheratDeviceListLoading: false,
  userThreatTableData: {},
  userThreatTableDataLoading: false ,
  threatFilters: {},
  threatUniqueDeviceList: {},
  threatUniqueDeviceListLoading: false,
  threatUniqueIpList: {},
  threatUniqueIpListLoading: false,
  threatUniqueClassList: {},
  threatUniqueClassListLoading: false,
  threatUniqueThreatNameList: {},
  threatUniqueThreatNameListLoading: false,
  threatUniqueActionList: {},
  threatUniqueActionListLoading: false,
  threatActionPieData: {},
  threatAcyionPieDataLoading: false,
  threatSeverityClassList: {},
  threatSeverityClassListLoading: false,
  threatKontrolLoading: false,
  createThreatKontrol: {},
  updateThreatKontrol: {},
  deleteThreatkontrol: {},
  threatList: {},
  threatListLoading: false,
  threatNamesList: {},
  threatNamesListLoading: false,

  captiveUsagePieData: {},
  captiveUsagePieDataLoading: false,
  captiveSubsPieData: {},
  captiveSubsPieDataLoading: false,

  flightSchedulingDataLoading: false,
  flightSchedulingdata: {},
  flightTailIdUpdateTableLoading: false,
  flightTailIdUpdateTable: {},
  flightTailIdUpdateTableCountLoading: false,
  flightTailIdUpdateTableCount: {},
  flightScheduleTableLoading: false,
  flightScheduleTable: {},
  flightScheduleTableCountLoading: false,
  flightScheduleTableCount: {},

};

export default function authReducer(state = initialState, action) {

  switch (action.type) {
    case SET_SIDE_MENU:
      return {
        ...state,
        sideMenu: action.payload,
      };
    case SET_USER_OU:
      return {
        ...state,
        userOu: action.payload,
      };
    case SET_READONLY_USER:
      return {
        ...state,
        isReadOnlyUser: action.payload,
      };
    case SET_SELECTED_OU:
      return {
        ...state,
        selectedOu: action.payload,
        getVesselsListing: {},
        // getInventoryWarehouseVesselList: {}
      };
    case SET_PRIVATE_VESSEL_LIST: 
      return {
        ...state,
        privateVesselList: action.payload,
        privateVesselListLoading: false
      }
    case SET_PRIVATE_VESSEL_LIST_LOADING:
      return {
        ...state,
        privateVesselListLoading: action.payload
      }
    case SET_IS_USER_OU_HAS_SUB_OUS:
      return {
        ...state,
        isUserOuHasSubOus: action.payload,
      };
    case SET_PARENT_AND_SUBDP_LIST: 
      return {
        ...state,
        parentDpAndSubDpList: action.payload
      };
    case SET_SELECTED_VESSELS:
      return {
        ...state,
        selectedVessels: action.payload,
      };
    case SET_FILTERED_VESSELS:
      // when same filter or the vessel is in use, to avoid api call
      if (_.isEqual(state.filteredVessels, action.payload)) return state;
      return {
        ...state,
        filteredVessels: action.payload,
      };
    case SET_HUB_FILTERED_VESSELS:
      if (_.isEqual(state.hubFilteredVessels, action.payload)) return state;
      return {
        ...state,
        hubFilteredVessels: action.payload,
      };
    case SET_SELECTED_VESSEL:
      return {
        ...state,
        selectedVessel: action.payload,
      };
    case SET_PERMISSION_OBSERVER:
      return {
        ...state,
        permissionObserver: action.payload
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOGIN_USER:
      initialState.hostName = state?.hostName
      initialState.domainConfig = state?.domainConfig
      return {
        ...initialState,
        loginUser: action.payload,
      };
    case OTP_GENERATION_STATUS: {
        initialState.hostName = state?.hostName
        initialState.domainConfig = state?.domainConfig
        return {
            ...initialState,
            otpGenerationStatus: action.payload
        }
    }
    case OTP_ERROR: {
      return {
        ...initialState,
        otpErrorStatus: action.payload,
      }
    }
    case LOGOUT_USER:
      return {
        ...state,
        logoutUser: action.payload,
      };
    case FETCH_VESSEL:
      return {
        ...state,
        fetchVessel: action.payload,
      };
    case SET_FETCH_USERS_LOADING:
      return {
        ...state,
        usersListLoading: action.payload,
      };
    case IS_PRIVATE_LOADING:
      return {
        ...state,
        isPrivateLoading: action.payload
      }
    case IS_PRIVATE_ENABLED:
      return {
      ...state,
      isPrivateEnabled: action.payload
    }
    case FETCH_USERS:
      return {
        ...state,
        usersList: action.payload,
        usersListLoading: false
      };
    case SET_VESSEL_PLANS_LOADING:
      return {
        ...state,
        vesselPlansLoading: action.payload,
      };
    case GET_VESSEL_PLANS:
      return {
        ...state,
        vesselPlans: action.payload,
        vesselPlansLoading: false
      };
    case GET_USER_POLICY:
      return {
        ...state,
        userPolicy: action.payload,
      };
    case INVITE_USER_LOADING:
      return {
        ...state,
        inviteUserLoading: action.payload
      };
    case INVITE_USER:
      return {
        ...state,
        inviteUser: action.payload,
        inviteUserLoading: false
      };
    case RESEND_INVITE_LOADING:
      return {
        ...state,
        resendInviteLoading: action.payload
      };
    case RESEND_INVITE:
      return {
        ...state,
        resendInvite: action.payload,
        resendInviteLoading: false
      };
    case GET_ORGANISATIONS:
      return {
        ...state,
        getOrganisationList: action.payload,
      };
    case SET_SEARCH_ALERTS_LOADING:
      return {
        ...state,
        searchAlertsLoading: action.payload,
      };
    case SEARCH_ALERTS:
      return {
        ...state,
        searchAlerts: action.payload,
        searchAlertsLoading: false
      };
    case OU_DERIVED:
      return {
        ...state,
        ouDerived: action.payload
      }
    case GET_VESSEL_EDGE_INFO:
      return {
        ...state,
        vesselEdgeInfo: action.payload
      }
    case SET_FETCH_OU_RESOURCES_LOADING:
      return {
        ...state,
        fetchResourcesLoading: action.payload
      }
    case FETCH_OU_RESOURCES:
      return {
        ...state,
        fetchResources: action.payload,
        fetchResourcesLoading: false
      }
    case FETCH_SERVICE_LINES:
        return {
          ...state,
          fetchServiceLines: action.payload,
          fetchResourcesLoading: false
        }
    case OU_POLICY:
      return {
        ...state,
        organizationpolicy: action.payload
      }
    case USER_DETACH_LOADING:
      return {
        ...state,
        userDetachLoading: action.payload
      }
    case USER_DETACH:
      return {
        ...state,
        userdetch: action.payload,
        userDetachLoading: false
      }
    case SET_TICKETS_LOADING:
      return {
        ...state,
        getTicketsLoading: action.payload
      }
    case GET_TICKETS:
      return {
        ...state,
        getTickets: action.payload,
        getTicketsLoading: false
      }
    case DISPLAY_APPLICABLE_USER_POLICIES:
      return {
        ...state,
        displayApplicableUserPolicies: action.payload
      }
    case POLICIES_DETAILS:
      return {
        ...state,
        policiesDetails: action.payload
      }
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: action.payload,
      }
    case UPDATE_USER:
      return {
        ...state,
        UpdateUser: action.payload,
        updateUserLoading: false,
      }
    case UPDATE_USER_ORGANIZATION_LOADING:
      return {
        ...state,
        updateUserOrganizationLoading: action.payload,
      }
    case UPDATE_USER_ORGANIZATION:
      return {
        ...state,
        updateUserOrganization: action.payload,
        updateUserOrganizationLoading: false,
      }
    case SET_TICKET_DETAILS_LOADING: {
      return {
        ...state,
        setTicketDetailsLoading: action.payload
      }
    }
    case GET_TICKET_DETAILS:
      return {
        ...state,
        getTicketDetails: action.payload,
        setTicketDetailsLoading: false
      }
    case GET_HOT_VESSELS:
      return {
        ...state,
        getHotVessels: action.payload
      }
    case SET_TICKET_COMMENTS_LOADING: {
      return {
        ...state,
        setTicketCommentsLoading: action.payload
      }
    }
    case GET_TICKET_COMMENTS:
      return {
        ...state,
        getTicketComments: action.payload,
        setTicketCommentsLoading: false
      }
    case SET_TICKET_ATTACHMENTS_LOADING: {
      return {
        ...state,
        setTicketAttachmentsLoading: action.payload
      }
    }
    case GET_TICKET_ATTACHMENTS:
      return {
        ...state,
        getTicketAttachments: action.payload,
        setTicketAttachmentsLoading: false
      }
    case GET_SINGLE_TICKET_ATTACHMENTS:
      return {
        ...state,
        getSingleTicketAttachments: action.payload
      }
    case SET_TICKET_DETAILS_FIELD_LOADING: {
      return {
        ...state,
        setTicketFieldsLoading: action.payload
      }
    }
    case GET_TICKET_DETAILS_FIELD:
      return {
        ...state,
        getTicketFields: action.payload,
        setTicketFieldsLoading: false
      }
    case UPDATE_TICKET:
      return {
        ...state,
        updateTicket: action.payload
      }
    case CREATE_NEW_TICKET:
      return {
        ...state,
        createNewTicket: action.payload
      }
    case ADD_ATTACHMENTS_TO_TICKET:
      return {
        ...state,
        addAttachmentsToTicket: action.payload
      }
    case SEND_TICKET_EMAIL:
      return {
        ...state,
        sendTicketEmail: action.payload
      }
    case SEND_TICKET_COMMENT:
      return {
        ...state,
        sendTicketComment: action.payload
      }
    case SET_TICKET_HISTORY_LOADING: {
      return {
        ...state,
        setTicketHistoryLoading: action.payload
      }
    }
    case GET_TICKET_HISTORY:
      return {
        ...state,
        getTicketHistory: action.payload,
        setTicketHistoryLoading: false
      }
    case GET_TICKET_AGENTS_BY_NAME:
      return {
        ...state,
        getTicketAgentsByName: action.payload
      }
    case GET_TICKET_ALL_AGENTS:
      return {
        ...state,
        getTicketAllAgents: action.payload
      }
      case GET_DP_DATA:
      return {
        ...state,
        getDpAddressData: action.payload
      }
    case ENABLE_USER_ACCOUNT_LOADING:
      return {
        ...state,
        enableUserAccountLoading: action.payload
      }
    case ENABLE_USER_ACCOUNT:
      return {
        ...state,
        enableUserAccount: action.payload,
        enableUserAccountLoading: false
      }
    case DISABLE_USER_ACCOUNT_LOADING:
      return {
        ...state,
        disableUserAccountLoading: action.payload
      }
    case DISABLE_USER_ACCOUNT:
      return {
        ...state,
        disableUserAccount: action.payload,
        disableUserAccountLoading: false
      }
    case DELETE_ALERT:{
      return {
        ...state,
        deleteAlert: action.payload,
        deleteAlertLoading: false
      }
    }
    case DELETE_ALERT_LOADING:{
      return {
        ...state,
        deleteAlertLoading: action.payload
      }
    }
    case GET_DP_ALERTS:{ 
      return{
        ...state,
        dpAlerts: action.payload,
        dpAlertsLoading: false
      }  
    }
    case GET_DP_ALERTS_LOADING:
      return {
        ...state,
        dpAlertsLoading: action.payload
      }
    case UPDATE_DP_ALERT:{
      return {
        ...state,
        updateDpAlert: action.payload,
        updateDpAlertLoading: false
      }
    }
    case UPDATE_DP_ALERT_LOADING:
      return {
        ...state,
        updateDpAlertLoading: action.payload
      }
    case UPDATE_DP_ALERT_NOTIFICATION:{
      return {
        ...state,
        updateDpAlertNotification: action.payload,
        updateDpAlertNotificationLoading: false
      }
    }
    case UPDATE_DP_ALERT_NOTIFICATION_LOADING:
      return {
        ...state,
        updateDpAlertNotificationLoading: action.payload
      }
    case UPDATE_DP_ALERT_STATUS:{
      return {
        ...state,
        updateDpAlertStatus: action.payload,
        updateDpAlertStatusLoading: false
      }
    }
    case UPDATE_DP_ALERT_STATUS_LOADING:
      return {
        ...state,
        updateDpAlertStatusLoading: action.payload
      }
    case UPDATE_SITE_ALERT_STATUS:{
      return {
        ...state,
        updateSiteAlertStatus: action.payload,
        updateSiteAlertStatusLoading: false
      }
    }
    case UPDATE_SITE_ALERT_STATUS_LOADING:
      return {
        ...state,
        updateSiteAlertStatusLoading: action.payload
      }
    case DELETE_DP_ALERT_LINK_AVAILABILITY:{
      return {
        ...state,
        deleteDpAlertLinkAvailability: action.payload,
        deleteDpAlertLALoading: false
      }
    }
    case DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING:
      return {
        ...state,
        deleteDpAlertLALoading: action.payload
      }
    case GET_DP_ALERT_AUDIT_LOGS:{ 
      return{
        ...state,
        dpAlertAuditLogs: action.payload,
        dpAlertLogsLoading: false
      }  
    }
    case GET_DP_ALERT_AUDIT_LOGS_LOADING:
      return {
        ...state,
        dpAlertLogsLoading: action.payload
      }
    case GET_SITE_ALERTS:{ 
      return{
        ...state,
        siteAlerts: action.payload,
        siteAlertsLoading: false
      }  
    }
    case GET_SITE_ALERTS_LOADING:
      return {
        ...state,
        siteAlertsLoading: action.payload
      }
    case UPDATE_SITE_ALERT:{
      return {
        ...state,
        updateSiteAlert: action.payload,
        updateSiteAlertLoading: false
      }
    }
    case UPDATE_SITE_ALERT_LOADING:
      return {
        ...state,
        updateSiteAlertLoading: action.payload
      }
    case UPDATE_SITE_ALERT_NOTIFICATION:{
      return {
        ...state,
        updateSiteAlertNotification: action.payload,
        updateSiteAlertNotificationLoading: false
      }
    }
    case UPDATE_SITE_ALERT_NOTIFICATION_LOADING:
      return {
        ...state,
        updateSiteAlertNotificationLoading: action.payload
      }
    case DELETE_SITE_ALERT_LINK_AVAILABILITY:{
      return {
        ...state,
        deleteSiteAlertLinkAvailability: action.payload,
        deleteSiteAlertLALoading: false
      }
    }
    case DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING:
      return {
        ...state,
        deleteSiteAlertLALoading: action.payload
      }
    case GET_SITE_ALERT_AUDIT_LOGS:{ 
      return{
        ...state,
        siteAlertAuditLogs: action.payload,
        siteAlertLogsLoading: false
      }  
    }
    case GET_SITE_ALERT_AUDIT_LOGS_LOADING:
      return {
        ...state,
        siteAlertLogsLoading: action.payload
      }
    case SET_VESSEL_ALERTS_LOADING:
      return {
        ...state,
        vesselAlertsLoading: action.payload
      }
    case GET_VESSEL_ALERTS:
      return {
        ...state,
        vesselAlerts: action.payload,
        vesselAlertsLoading: false
      }
    case SET_VESSEL_DASHBOARD_LOADING:
      return {
        ...state,
        vesselDashboardLoading: action.payload
      }
    case SET_VESSEL_WAN_LINK_STATUS_LOADING:
      return {
        ...state,
        vesselWanLinkStatusLoading : action.payload
      }
    case SPORTS_SITE_SUMMARY_COUNT_LOADING:
      return {
        ...state,
        sportSiteSummaryCountLoading: action?.payload
      }
    case GET_VESSEL_SUMMARY_DASHBOARD:
      return {
        ...state,
        vesselDashboardSummary: action.payload,
        vesselDashboardLoading: false
      }
    case GET_WIDGEY_SUMMARY_DASHBOARD:
      return {
        ...state,
        widgetDashboardSummary: action.payload,
        vesselDashboardLoading: false
      }
    case GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM:
      return {
        ...state,
        topWidgetLinkStatus: action.payload,
        vesselDashboardLoading: false
      }
    case GET_THREAT_DASHBOARD_TOPWIDGET:
      return {
        ...state,
        threatTopWidgetData: action.payload,
        threatTopWidgetLoading: false,
      }
    case SET_THREAT_DASHBOARD_TOPWIDGET_LOADING: 
      return {
        ...state,
        threatTopWidgetLoading: true
      }
    case GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL:
      return {
        ...state,
        topwidgetTotal: action.payload,
        topwidgetTotalLoading: false,
      }
    case SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING: 
      return {
        ...state,
        topwidgetTotalLoading: true
      }
    case GET_TOP_10_THREAT_BY_NAME:
      return {
        ...state,
        threatTopCrossBarData: action.payload,
        threatTopCrossBarDataLoading: false,
      }
    case SET_TOP_10_THREAT_BY_NAME_LOADING:
      return {
        ...state,
        threatTopCrossBarDataLoading: true
      }
    case GET_TOP_10_THREAT_BY_SEVERITY:
      return {
        ...state,
        topThreatServerityData: action.payload,
        topThreatServerityDataLoading: false,
      }
    case SET_TOP_10_THREAT_BY_SERVERITY_LOADING:
      return {
        ...state,
        topThreatServerityDataLoading: true
      }
    case SET_THREAT_DASHBOARD_TOPWIDGET_LOADING: 
      return {
        ...state,
        threatTopWidgetLoading: true
      }
    case GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY:
      return {
        ...state,
        threatBySeverityBarChart: action.payload,
        theatBySeverityBarChartLoading: false
      }
    case GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING:
      return {
        ...state,
        theatBySeverityBarChartLoading: true
      }
    case GET_TOP_10_THREAT_TABLE_DATA:
      return {
        ...state,
        top10ThreatTableData: action.payload,
        top10ThreatTableDataLoading: false
      }
    case SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING:
      return {
        ...state,
        top10ThreatTableDataLoading: true ,
      }
    case GET_ALL_THREAT_TABLE_DATA:
      return {
        ...state,
        allThreatTableData: action.payload,
        allThreatTableDataLoading: false
      }
    case SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING:
      return {
        ...state,
        allThreatTableDataLoading: true ,
      }
    case GET_USER_THREAT_TABLE_DATA:
    return {
      ...state,
      userThreatTableData: action.payload,
      userThreatTableDataLoading: false
    }
    case SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING:
      return {
        ...state,
        userThreatTableDataLoading: true ,
      }

    case GET_THREAT_SITE_LIST:
      return {
        ...state,
        allThreatSiteList: action.payload,
        allThreatSiteListLoading: false
      }
    case GET_THREAT_SITE_LIST_LOADING:
      return {
        ...state,
        allThreatSiteListLoading: true ,
      }
    case GET_THREAT_UNIQUE_ACTION_LIST:
      return {
        ...state,
        threatUniqueActionList: action.payload,
        threatUniqueActionListLoading: false
      }
    case GET_THREAT_UNIQUE_ACTION_LIST_LOADING:
      return {
        ...state,
        threatUniqueActionListLoading: true ,
      }
    case GET_THREAT_DEVICE_LIST:
      return {
        ...state,
        allThreatDeviceList: action.payload,
        allTheratDeviceListLoading: false
      }
    case GET_THREAT_DEVICE_LIST_LOADING:
      return {
        ...state,
        allTheratDeviceListLoading: true ,
      }
    case SET_THREAT_FILTERS:
      return {
        ...state,
        threatFilters: action.payload
      }
    case GET_THREAT_UNIQUE_DEVICE_LIST_LOADING:
      return {
        ...state,
        threatUniqueDeviceListLoading: true,
      }
    case GET_THREAT_UNIQUE_DEVICE_LIST:
      return {
        ...state,
        threatUniqueDeviceList: action.payload,
        threatUniqueDeviceListLoading : false
      }
    case GET_THREAT_UNIQUE_IP_LIST_LOADING:
      return {
        ...state,
        threatUniqueIpListLoading: true ,
      }
    case GET_THREAT_UNIQUE_IP_LIST:
      return {
        ...state,
        threatUniqueIpList: action.payload,
        threatUniqueIpListLoading : false
      }
    case GET_THREAT_UNIQUE_CLASS_LIST_LOADING:
      return {
        ...state,
        threatUniqueClassListLoading: true ,
      }
    case GET_THREAT_UNIQUE_CLASS_LIST:
      return {
        ...state,
        threatUniqueClassList: action.payload,
        threatUniqueClassListLoading : false
      }
    case GET_THREAT_UNIQUE_THREATNAME_LIST_LOADING:
      return {
        ...state,
        threatUniqueThreatNameListLoading: true,
      }
    case GET_THREAT_UNIQUE_THREATNAME_LIST:
      return {
        ...state,
        threatUniqueThreatNameList: action.payload,
        threatUniqueThreatNameListLoading: false
      }
    case GET_THREAT_ACTION_PIE_DATA:
    return {
      ...state,
      threatActionPieData: action.payload,
      threatAcyionPieDataLoading : false
    }
    case SET_THREAT_ACTION_PIE_DATA_LOADING:
      return {
        ...state,
        threatAcyionPieDataLoading: true,
      }
    case SET_THREAT_SEVERITY_CLASS_LIST_LOADING:
      return {
        ...state,
        threatSeverityClassListLoading: true
      } 
    case SET_THREATNAMES_LIST_LOADING:
      return {
        ...state,
        threatNamesListLoading: true
      }
    case GET_THREAT_SEVERITY_CLASS_LIST:
      return {
        ...state,
        threatSeverityClassList: action.payload,
        threatSeverityClassListLoading: false
      }
    case GET_THREATNAMES_LIST:
      return {
        ...state,
        threatNamesList: action.payload,
        threatNamesListLoading: false
      }
    case SET_THREATS_LOADING: 
      return {
        ...state,
        threatListLoading: true
      }
    case GET_THREATS:
      return {
        ...state,
        threatList: action.payload,
        threatListLoading: false
      }
    case SET_SNORT_RULES_DETAILS: {
      return {
        ...state,
        setSnortRulesDetailsLoading: action.payload
      }
    }
    case GET_SNORT_RULES_DETAILS: {
      return {
        ...state,
        getSnortRules: action.payload,
        setSnortRulesDetailsLoading: false
      }
    }
    case GET_CAPTIVE_USGAE_PIE_DATA:
      return {
        ...state,
        captiveUsagePieData: action.payload,
        captiveUsagePieDataLoading : false
      }
    case SET_CAPTIVE_SUBS_PIE_DATA_LOADING:
      return {
        ...state,
        captiveSubsPieDataLoading: true,
      }
      case GET_CAPTIVE_SUBS_PIE_DATA:
    return {
      ...state,
      captiveSubsPieData: action.payload,
      captiveSubsPieDataLoading : false
    }
    case SET_CAPTIVE_USAGE_PIE_DATA_LOADING:
      return {
        ...state,
        captiveSubsPieDataLoading: true,
      }
    case GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD:
      return {
        ...state,
        widgetLinkStatusDashboardSummary: action.payload,
        vesselDashboardLoading: false
      }
    case GET_NEW_SUMMARY_USAGE_TABLE_LOADING: {
      return {
        ...state,
        newSummaryUsageTableLoading: action.payload
      }
    }
    case GET_NEW_SUMMARY_USAGE_TABLE:
      return {
        ...state,
        newSummaryUsageTable: action.payload,
        newSummaryUsageTableLoading: false
      }
    case GET_VESSEL_SUMMARY_TABLE_COUNT:
      return {
        ...state,
        vesselSummaryTableDataCount: action.payload,
        vesselDashboardLoading:false
      }
    case SET_VESSEL_SUMMARY_LOADING:
      return {
        ...state,
        vesselSummaryLoading: action.payload
      }
    case GET_VESSEL_SUMMARY_TABLE:
      return {
        ...state,
        vesselSummaryTableData: action.payload,
        vesselSummaryLoading: false
      }
    case CLEAR_SITE_DASHBOARD:
      return {
        ...state,
        vesselSummaryTableData: {},
        vesselSummaryTableDataCount: null,
        widgetLinkStatusDashboardSummary: {},
        widgetDashboardSummary: {},
        vesselDashboardSummary: {}
      }
    case GET_VESSEL_LOC_WITH_ALERTS_LOADING:
      return {
        ...state,
        vesselLocWithAlertsLoading: action.payload
      }
    case GET_VESSEL_LOC_WITH_ALERTS:
      return {
        ...state,
        vesselLocWithAlerts: action.payload,
        vesselLocWithAlertsLoading: false
      }
    case MARK_AS_FIXED_ALERT:
      return {
        ...state,
        markAsFixedAlert: action.payload
      }
    case ALERTS_NAME:
      return {
        ...state,
        alertnames: action.payload
      }
    case ALERTS_HISTORY:
      return {
        ...state,
        alertHistory: action.payload
      }
    case GET_SYSTEM_INVENTORY:
      return {
        ...state,
        inventory: action.payload
      }
    case GET_EDGE_SERVER_STATUS_LOADING:
      return {
        ...state,
        edgeServerStatusLoading: action.payload
      }
    case GET_EDGE_SERVER_STATUS:
      return {
        ...state,
        edgeServerStatus: action.payload,
        edgeServerStatusLoading: false
      }
    case RECOVERY_PASSWORD_USER:
      return {
        ...state,
        recoveryPasswordUser: action.payload
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: action.payload
      }
    case SET_PASSWORD_User_LOADING:
      return {
        ...state,
        setPasswordUserLoading: action.payload
      }
    case SET_PASSWORD_User:
      return {
        ...state,
        setPasswordUser: action.payload,
        setPasswordUserLoading: false,
      }
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      }
    case VERIFY_USER:
      return {
        ...state,
        verifyUser: action.payload,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload
      }
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        updateUserProfile: action.payload
      }
    case USER_PREFERENCES_LOADING:
      return {
        ...state,
        userPreferencesLoading: action.payload,
      }
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        updateUserPreferences: action.payload,
        userPreferencesLoading: false
      }
    case GET_USER_PREFERENCES:
      return {
        ...state,
        getUserPreferences: action.payload
      }
    case USER_TIMEZONE:
      return {
        ...state,
        userTimezone: action.payload
      }
    case DETACH_RESOURCE_OU:
      return {
        ...state,
        detachResource: action.payload
      }
    case NOT_ATTACH_RESOURCE_OU:
      return {
        ...state,
        notAttachedOuResources: action.payload
      }
    case ATTACH_RESOURCE_OU:
      return {
        ...state,
        attachResource: action.payload
      }
    case GET_VESSEL_EDGE_TOPOLOGY_PRODUCTS:
      return {
        ...state,
        vesselEdgeTopologyProducts: action.payload
      }
    case GET_PRODUCT_EDGE_TOPOLOGY_DEVICES:
      return {
        ...state,
        productEdgeTopologyDevices: action.payload,
        loadig: false
      }
    case GET_PARENT_DEVICE_OF_A_SITE:
      return {
        ...state,
        parentDeviceOfSite: action.payload
      }
    case UPDATE_PARENT_DEVICE_OF_A_SITE:
      return {
        ...state,
        updateParentDeviceOfSite: action.payload
      }
      case SET_LINK_STATUS_PER_DEVICE_LOADING: {
        return {
          ...state,
          linkStatusPerDeviceLoading: action.payload
        }
      }
    case GET_LINK_STATUS_PER_DEVICE:
      let passeddev = action.payload.passedDevice;
       let linkStatusPerDeviceNew = {...state.linkStatusPerDevice}
       linkStatusPerDeviceNew[passeddev] = action.payload
      return {
        ...state,
        linkStatusPerDevice: linkStatusPerDeviceNew,
        linkStatusPerDeviceLoading: false
      }
    case SOFTWARE_PRODUCT:
      return {
        ...state,
        softwareProduct: action.payload
      }
    case SET_SOFTWARE_LISTING_LOADING:
      return {
        ...state,
        softwareListingLoading: action.payload
      }
    case SOFTWARE_LISTING:
      return {
        ...state,
        softwareListing: action.payload,
        softwareListingLoading: false
      }
    case ALL_TEMPLATE:
      return {
        ...state,
        allTemplate: action.payload
      }
      case SET_SUB_DP_CREATE_LOADING: {
        return {
          ...state,
          setSubDPCreateLoading: action.payload
        }
      }
      case ACCOUNT_CREATE_SUB_DP: {
        return {
          ...state,
          accountCreateSubDP: action.payload,
          setSubDPCreateLoading: false
        }
      }
      case SET_DELETE_ORG_LOADING: {
        return {
          ...state,
          setDeleteOrgLoading: action.payload
        }
      }
      case GET_DELETE_ORG: {
        return {
          ...state,
          accountDeleteOrg: action.payload,
          setDeleteOrgLoading: false
        }
      }
      case SET_MODIFY_ORG_LOADING: {
        return {
          ...state,
          setModifyOrgLoading: action.payload
        }
      }
      case GET_MODIFY_ORG: {
        return {
          ...state,
          accountModifyOrg: action.payload,
          setModifyOrgLoading: false
        }
      }
      case SET_DP_INFO_LOADING: {
        return {
          ...state,
          setDpInfoLoading: action.payload
        }
      }
      case GET_DP_INFO: {
        return {
          ...state,
          getDpInfo: action.payload,
          setDpInfoLoading: false
        }
      }
    case GET_CONFIGURED_ALERTS_FOR_DEVICE:
      return {
        ...state,
        getConfiguredAlertsForDevice: action.payload
      }
    case UPDATE_CREATE_DEVICE_ALERT_CONFIGURATION:
      return {
        ...state,
        createDeviceAlertConfiguration: action.payload
      }

    case UPDATE_DELETE_DEVICE_ALERT_CONFIGURATION:
      return {
        ...state,
        deleteDeviceAlertConfiguration: action.payload
      }

    case UPDATE_DEVICE_ALERT_CONFIGURATION_SETTINGS:
      return {
        ...state,
        updateDeviceAlertConfiguration: action.payload
      }

    case GET_ORGANISATIONS_UNITS:
      return {
        ...state,
        getOrganisationUnitList: action.payload,
      }
    case GET_SEARCH_DP_LIST: {
      return {
        ...state,
        getSearchDpList: action.payload
      }
    }

    case SET_VESSEL_LISTING_LOADING:
      return {
        ...state,
        setVesselListingLoading: action.payload
      }
    case GET_VESSELS_LISTING:
      if (!_.isEqual(state.getVesselsListing, action.payload)) {
        return {
          ...state,
          getVesselsListing: action.payload,
          setVesselListingLoading: false
        }
      } else {
        return {
          ...state
        }
      }
    case GET_VESSEL_SUMMARY_TOPOLOGY:
      return {
        ...state,
        vesselSummaryTopology: action.payload
      }
    case UPDATE_NAME:
      return {
        ...state,
        updateNames: action.payload
      }
    case  VESSEL_ADDRESS_MMSI:
      return {
        ...state,
        vesselAddressMMSI: action.payload
      }
    case GET_VESSEL_INFO:
      return {
        ...state,
        getVesselInfo: action.payload
      }
    case UPDATE_MMSI_AND_STREETADDRESS:
      return {
        ...state,
        updateMMSIAndStreetAddress: action.payload
      }
    case GET_SOFTWARE_VERSIONS_DEVICE_COUNT:
      return {
        ...state,
        getSoftwareVersionsDeviceCount: action.payload,
        softwareListingLoading: false
      }
    case GET_SOFTWARE_VERSION_DEVICE_DETAILS:
        return {
          ...state,
          getSoftwareVersionDeviceDetails: action.payload,
          softwareListingLoading: false
        }
    case GET_SOFT_VERSION_DEVICE_DETAILS:
      return {
        ...state,
        getSoftVersionDeviceDetails: action.payload,
        softwareListingLoading: false
      }
      case GET_KONNECT_APP_DOWNLOAD_LINKS:
        return {
          ...state,
          konnectAppDownloadLinks: action.payload,
          softwareListingLoading: false
        }
      case GET_VX_BUILD_DETAILS:
        return {
          ...state,
          vxBuildDetails: action.payload,
          softwareListingLoading: false
        }
      case GET_DP_DOCUMENTS_LIST:
        return {
          ...state,
          dpDocumentsList: action.payload,
          softwareListingLoading: false
        }
    case RESET_STATE:
      return initialState;
    case USER_DELETE:{
      return {
        ...state,
        userdelete: action.payload,
        userDeleteLoading: false
      }
    }
    case USER_DELETE_LOADING:{
      return{
        ...state,
        userDeleteLoading: action.payload
      }
    }

    case GET_SIM_BOX:
      return {
        ...state,
        getSimBox: action.payload
      }

    case GET_SIM_BOXES:
      return {
        ...state,
        getSimBoxes: action.payload,
        getSimBoxesLoading: false
      }
    case GET_SIM_BOXES_LOADING:
      return {
        ...state,
        getSimBoxesLoading: action.payload
      }
    case GET_SIM_BOXES_COUNT:
        return {
          ...state,
          getSimBoxesCount: action.payload,
        }

    case GET_LTE_PROVIDERS:
      return {
        ...state,
        getLteProviders: action.payload
      }

    case GET_DATA_USAGE:
      return {
        ...state,
        getDataUsage: action.payload
      }

    case GET_DATA_USAGE_COUNT:
      return {
        ...state,
        getDataUsageCount: action.payload
      }

    case GET_DAILY_DATA_USAGE:
      return {
        ...state,
        getDailyDataUsage: action.payload
      }

    case  EXPORT_DATA_USAGE_REPORT:
      return {
        ...state,
        exportDataUsageReport: action.payload
      }

    case GRAPHICAL_DATA_USAGE_LOADING:
      return {
        ...state,
        graphicalDataUsageLoading: action.payload,
      };
    case SET_CELLULAR_DATA_USAGE_LOADING : {
      return {
        ...state,
        setCellularDataUsageLoading: action.payload
      }
    }
    case GET_GRAPHICAL_DATA_USAGE:
      return {
        ...state,
        getGraphicalDataUsage: action.payload,
        graphicalDataUsageLoading: false
      }
    case GET_CELLULAR_DATA_USAGE:
      return {
        ...state,
        getCellularDataUsage: action.payload,
        setCellularDataUsageLoading: false
      }

    case GET_USAGE_VESSEL_LIST:
      return {
        ...state,
        getUsageVesselList: action.payload
      }

    case SET_EDGE_EVENTS_LOADING:{
      return {
        ...state,
        getEdgeEventsLoading: action.payload
      }
    }
    case SET_EDGE_EVENTS_FILTER_LOADING: {
      return {
        ...state,
        getEdgeEventsFilterLoading: action.payload
      }
    }
    case SET_EDGE_EVENTS_TABLE_LOADING: {
      return {
        ...state,
        getEdgeEventsTableLoading: action.payload
      }
    }
    case SET_EDGE_EVENTS_TABLE_COUNT_LOADING: {
      return {
        ...state,
        getEdgeEventsTableCountLoading: action.payload
      }
    }
    case GET_EDGE_EVENTS:{
      return {
        ...state,
        getEdgeEvents: action.payload,
        getEdgeEventsLoading: false
      }
    }
    case GET_EDGE_EVENTS_TABLE:{
      return {
        ...state,
        getEdgeEventsTable: action.payload,
        getEdgeEventsTableLoading: false
      }
    }
    case DOWNLOAD_EDGE_EVENTS_TABLE:{
      return {
        ...state,
        downloadEdgeEventsTable: action.payload
      }
    }
    case GET_EDGE_EVENTS_FILTER:{
      return {
        ...state,
        getEdgeEventsFilter: action.payload,
        getEdgeEventsFilterLoading: false
      }
    }
    case GET_EDGE_EVENTS_TABLE_COUNT:{
      return {
        ...state,
        getEdgeEventsTableCount: action.payload,
        getEdgeEventsTableCountLoading: false
      }
    }
    case CLEAR_EDGE_EVENTS: {
      return {
        ...state,
        getEdgeEvents: {},
        getEdgeEventsFilter: {},
        getEdgeEventsTable: {},
        getEdgeEventsTableCount: {},
        downloadEdgeEventsTable: {},
      }
    }
    case SET_SITE_CHARTS_RSSI_LOADING: {
      return {
        ...state,
        siteChartsRSSILoading: action.payload
      }
    }
    case SET_SITE_CHARTS_SINR_LOADING: {
      return {
        ...state,
        siteChartsSINRLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_LINK_STATUS_LOADING: {
      return {
        ...state,
        siteChartsLinkStatusLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_LATENCY_JITTER_LOADING: {
      return {
        ...state,
        siteChartsLatencyJitterLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_THROUGHPUT_LOADING: {
      return {
        ...state,
        siteChartsThroughputLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_VSAT_SNR_LOADING: {
      return {
        ...state,
        siteChartsVSATSnrLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_VSAT_TXPOWER_LOADING: {
      return {
        ...state,
        siteChartsVSATyTXPowerLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_VSAT_POWER_LOADING: {
      return {
        ...state,
        siteChartsVSATPowerLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_VSAT_SYMBOL_RATE_LOADING: {
      return {
        ...state,
        siteChartsVSATSymbolRateLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_SITE_INTERFACES_LOADING: {
      return {
        ...state,
        siteChartsSiteInterfacesLoading: action.payload
      }
    }
    case SET_SITE_CHARTS_SITE_DATA_RATE_LOADING: {
      return {
        ...state,
        siteChartsSiteDataRateLoading: action.payload
      }
    }
    case SET_SITE_CLIENTS_LOADING: {
      return {
        ...state,
        siteChartsSiteClientsLoading: action.payload
      }
    }
    case SET_SITE_USAGE_LOADING: {
      return {
        ...state,
        siteChartsSiteUsageLoading: action.payload
      }
    }
    case SET_SITE_SYSTEM_UPTIME_LOADING: {
      return {
        ...state,
        siteChartsSiteSystemUptimeLoading: action.payload
      }
    }
    case SET_SITE_LAN_STATUS_LOADING: {
      return {
        ...state,
        siteChartsSiteLanStatusLoading: action.payload
      }
    }
    case SET_HUB_CLIENT_SDWAN_FLOWS_LOADING: {
      return {
        ...state,
        siteChartsSiteFlowsLoading: action.payload
      }
    }
    case SET_HUB_CLIENT_SDWAN_SPEED_LOADING: {
      return {
        ...state,
        siteChartsSiteSpeedLoading: action.payload
      }
    }
    case SET_HUB_CLIENT_SDWAN_LATENCY_LOADING: {
      return {
        ...state,
        siteChartsSiteLatencyLoading: action.payload
      }
    }
    case SET_HUB_CLIENT_SDWAN_STATUS_LOADING: {
      return {
        ...state,
        siteChartsSiteStatusLoading: action.payload
      }
    }
    case SET_SDWAN_SPEEDTEST_LOADING: {
      return {
        ...state,
        siteChartsSiteSpeedtestLoading: action.payload
      }
    }
    case SET_SITE_LINK_PROBE_NATIVE_LOADING: {
      return {
        ...state,
        siteChartsSiteLinkProbeNativeLoading: action.payload
      }
    }
    case SET_SITE_LINK_PROBE_VTP_LOADING: {
      return {
        ...state,
        siteChartsSiteLinkProbeVtpLoading: action.payload
      }
    }
    case SET_SDWAN_QOE_STREAMING_LOADING: {
      return {
        ...state,
        siteChartsSiteQoeStreamingLoading: action.payload
      }
    }
    case SET_SDWAN_QOE_RTC_LOADING: {
      return {
        ...state,
        siteChartsSiteQoeRtcLoading: action.payload
      }
    }
    case GET_SITE_RSSI:{
      return {
        ...state,
        getSiteRSSI: {data: {rssiAvg: {series: {...state.getSiteRSSI.data.rssiAvg.series, ...action.payload?.data?.rssiAvg?.series}}}},
        siteChartsRSSILoading: false
      }
    }
    case GET_SITE_SINR:{
      return {
        ...state,
        getSiteSINR: {data: {sinrAvg: {series: {...state.getSiteSINR.data.sinrAvg.series, ...action.payload?.data?.sinrAvg?.series}}}},
        siteChartsSINRLoading: false
      }
    }
    case GET_SITE_DATA_RATE:{
      return {
        ...state,
        getSiteDataRate: {data: {rateAvg: {series: {...state.getSiteDataRate.data.rateAvg.series, ...action.payload?.data?.rateAvg?.series}}}},
        siteChartsSiteDataRateLoading: false
      }
    }
    case GET_SITE_LINK_STATUS:{
      return {
        ...state,
        getSiteLinkStatus: {data: {probeSuccess: {series: {...state.getSiteLinkStatus.data.probeSuccess.series, ...action.payload?.data?.probeSuccess?.series}}}},
        siteChartsLinkStatusLoading: false
      }
    }
    case GET_SITE_LATENCY_JITTER:{
      return {
        ...state,
        getSiteLatencyJitter: {data: {
          latency: {series: {...state.getSiteLatencyJitter.data.latency.series, ...action.payload?.data?.latency?.series}},
          jitter: {series: {...state.getSiteLatencyJitter.data.jitter.series, ...action.payload?.data?.jitter?.series}}
        }},
        siteChartsLatencyJitterLoading: false
      }
    }
    case GET_SITE_THROUGHPUT:{
      return {
        ...state,
        getSiteThroughput: {data: {
          avgDownSpeed: {series: {...state.getSiteThroughput.data.avgDownSpeed.series, ...action.payload?.data?.avgDownSpeed?.series}},
          avgUpSpeed: {series: {...state.getSiteThroughput.data.avgUpSpeed.series, ...action.payload?.data?.avgUpSpeed?.series}}
        }},
        siteChartsThroughputLoading: false
      }
    }
    case GET_SITE_VSAT_SNR: {
      return {
        ...state,
        getSiteVSATSnr: {data: {avg: {series: {...state.getSiteVSATSnr.data.avg.series, ...action.payload?.data?.avg?.series}}}},
        siteChartsVSATSnrLoading: false
      }
    }
    case GET_SITE_VSAT_TXPOWER: {
      return {
        ...state,
        getSiteVSATTxpower: {data: {avg: {series: {...state.getSiteVSATTxpower.data.avg.series, ...action.payload?.data?.avg?.series}}}},
        siteChartsVSATyTXPowerLoading: false
      }
    }
    case GET_SITE_VSAT_POWER: {
      return {
        ...state,
        getSiteVSATPower: {data: {avg: {series: {...state.getSiteVSATPower.data.avg.series, ...action.payload?.data?.avg?.series}}}},
        siteChartsVSATPowerLoading: false
      }
    }
    case GET_SITE_VSAT_SYMBOL_RATE: {
      return {
        ...state,
        getSiteVSATSymbolRate: {data: {avg: {series: {...state.getSiteVSATSymbolRate.data.avg.series, ...action.payload?.data?.avg?.series}}}},
        siteChartsVSATSymbolRateLoading: false
      }
    }
    case GET_SITE_CLIENTS: {
      return {
        ...state,
        getSiteClients: {data: {clients: {series: {...state.getSiteClients.data.clients.series, ...action.payload?.data?.clients?.series}}}},
        siteChartsSiteClientsLoading: false
      }
    }
    case GET_SITE_USAGE: {
      return {
        ...state,
        getSiteUsage: {data: {
          totalUsage: {series: {...state.getSiteUsage.data.totalUsage.series, ...action.payload?.data?.totalUsage?.series}},
          downUsage: {series: {...state.getSiteUsage.data.downUsage.series, ...action.payload?.data?.downUsage?.series}},
          upUsage: {series: {...state.getSiteUsage.data.upUsage.series, ...action.payload?.data?.upUsage?.series}},
        }},
        siteChartsSiteUsageLoading: false
      }
    }
    case GET_SITE_SYSTEM_UPTIME: {
      return {
        ...state,
        getSiteSystemUptime: {data: {status: {series: {...state.getSiteSystemUptime.data.status.series, ...action.payload?.data?.status?.series}}}},
        siteChartsSiteSystemUptimeLoading: false
      }
    }
    case GET_SITE_LAN_STATUS: {
      return {
        ...state,
        getSiteLanStatus: { data: { LAN_status: { series: { ...state.getSiteLanStatus.data.LAN_status.series, ...action.payload?.data?.LAN_status?.series } } } },
        siteChartsSiteLanStatusLoading: false
      }
    }
    case GET_HUB_CLIENT_SDWAN_FLOWS: {
      return {
        ...state,
        getSiteFlows: { data: { flows: { series: { ...state.getSiteFlows.data.flows.series, ...action.payload?.data?.flows?.series } } } },
        siteChartsSiteFlowsLoading: false
      }
    }
    case GET_HUB_CLIENT_SDWAN_SPEED: {
      return {
        ...state,
        getSiteSpeed: {data: {
          avgDownSpeed: {series: {...state.getSiteSpeed.data.avgDownSpeed.series, ...action.payload?.data?.avgDownSpeed?.series}},
          avgUpSpeed: {series: {...state.getSiteSpeed.data.avgUpSpeed.series, ...action.payload?.data?.avgUpSpeed?.series}}
        }},
        siteChartsSiteSpeedLoading: false
      }
    }
    case GET_HUB_CLIENT_SDWAN_LATENCY: {
      return {
        ...state,
        getSiteLatency: { data: { latency: { series: { ...state.getSiteLatency.data.latency.series, ...action.payload?.data?.latency?.series } } } },
        siteChartsSiteLatencyLoading: false
      }
    }
    case GET_HUB_CLIENT_SDWAN_STATUS: {
      return {
        ...state,
        getSiteStatus: { data: { status: { series: { ...state.getSiteStatus.data.status.series, ...action.payload?.data?.status?.series } } } },
        siteChartsSiteStatusLoading: false
      }
    }
    case GET_SDWAN_SPEEDTEST: {
      return {
        ...state,
        getSiteSpeedtest: action.payload,
        siteChartsSiteSpeedtestLoading: false
      }
    }
    case GET_SITE_LINK_PROBE_NATIVE: {
      return {
        ...state,
        getSiteLinkProbeNative: {data: {probeSuccess: {series: {...state.getSiteLinkProbeNative.data.probeSuccess.series, ...action.payload?.data?.probeSuccess?.series}}}},
        siteChartsSiteLinkProbeNativeLoading: false
      }
    }
    case GET_SITE_LINK_PROBE_VTP: {
      return {
        ...state,
        getSiteLinkProbeVtp: {data: {probeSuccess: {series: {...state.getSiteLinkProbeVtp.data.probeSuccess.series, ...action.payload?.data?.probeSuccess?.series}}}},
        siteChartsSiteLinkProbeVtpLoading: false
      }
    }
    case GET_SDWAN_QOE_STREAMING: {
      return {
        ...state,
        getSiteQoeStreaming: {data: {QoEStreamingPoints: {series: {...state.getSiteQoeStreaming.data.QoEStreamingPoints.series, ...action.payload?.data?.QoEStreamingPoints?.series}}}},
        siteChartsSiteQoeStreamingLoading: false
      }
    }
    case GET_SDWAN_QOE_RTC: {
      return {
        ...state,
        getSiteQoeRtc: {data: {QoEGamingPoints: {series: {...state.getSiteQoeRtc.data.QoEGamingPoints.series, ...action.payload?.data?.QoEGamingPoints?.series}}}},
        siteChartsSiteQoeRtcLoading: false
      }
    }
    case SET_SDWAN_QOE_HMAP_LOADING: {
      return {
        ...state,
        setSiteQoeHmapLoading: action.payload
      }
    }
    case GET_SDWAN_QOE_HMAP: {
      return {
        ...state,
        getSiteQoeHmap: action.payload,
        setSiteQoeHmapLoading: false
      }
    }
    case GET_SITE_INTERFACES:{
      return {
        ...state,
        getSiteInterfaces: action.payload,
        siteChartsSiteInterfacesLoading: false
      }
    }
    case CLEAR_SITE_CHARTS:{
      return {
        ...state,
        getSiteRSSI: action.payload.includes(CHARTS_METRICS.RSSI) ? {data: {rssiAvg: {series: {}}}} : state.getSiteRSSI,
        getSiteSINR: action.payload.includes(CHARTS_METRICS.SINR) ? {data: {sinrAvg: {series: {}}}} : state.getSiteSINR,
        getSiteDataRate: action.payload.includes(CHARTS_METRICS.PRE) ? {data: {rateAvg: {series: {}}}} : state.getSiteDataRate,
        getSiteVSATSnr: action.payload.includes(CHARTS_METRICS.VSAT_SNR) ? {data: {avg: {series: {}}}} : state.getSiteVSATSnr,
        getSiteVSATTxpower: action.payload.includes(CHARTS_METRICS.VSAT_TXPOWER) ? {data: {avg: {series: {}}}} : state.getSiteVSATTxpower,
        getSiteVSATPower: action.payload.includes(CHARTS_METRICS.VSAT_POWER) ? {data: {avg: {series: {}}}} : state.getSiteVSATPower,
        getSiteVSATSymbolRate: action.payload.includes(CHARTS_METRICS.VSAT_SYMBOL_RATE) ? {data: {avg: {series: {}}}} : state.getSiteVSATSymbolRate,
        getSiteLinkStatus: action.payload.includes(CHARTS_METRICS.LINK_STATUS) ? {data: {probeSuccess: {series: {}}}} : state.getSiteLinkStatus,
        getSiteLatencyJitter: action.payload.includes(CHARTS_METRICS.RTT) ? {data: {latency: {series: {}}, jitter: {series: {}}}} : state.getSiteLatencyJitter,
        getSiteThroughput: action.payload.includes(CHARTS_METRICS.THROUGHPUT) ? {data: {avgDownSpeed: {series: {}}, avgUpSpeed: {series: {}}}} : state.getSiteThroughput,
        getSiteClients: action.payload.includes(CHARTS_METRICS.CLIENTS) ? {data: {clients: {series: {}}}} : state.getSiteClients,
        getSiteUsage: action.payload.includes(CHARTS_METRICS.USAGE) ? {data: {totalUsage: {series: {}}, downUsage: {series: {}}, upUsage: {series: {}}}} : state.getSiteUsage,
        getSiteSystemUptime: action.payload.includes(CHARTS_METRICS.SYSTEM_UPTIME) ? {data: {status: {series: {}}}} : state.getSiteSystemUptime,
        getSiteLanStatus: action.payload.includes(CHARTS_METRICS.LAN_STATUS) ? {data: {LAN_status: {series: {}}}} : state.getSiteLanStatus,
        getSiteFlows: action.payload.includes(CHARTS_METRICS.FLOWS) ? {data: {flows: {series: {}}}} : state.getSiteFlows,
        getSiteSpeed: action.payload.includes(CHARTS_METRICS.SPEED) ? {data: {avgDownSpeed: {series: {}}, avgUpSpeed: {series: {}}}} : state.getSiteSpeed,
        getSiteLatency: action.payload.includes(CHARTS_METRICS.LATENCY) ? {data: {latency: {series: {}}}} : state.getSiteLatency,
        getSiteStatus: action.payload.includes(CHARTS_METRICS.STATUS) ? {data: {status: {series: {}}}} : state.getSiteStatus,
        getSiteSpeedtest: action.payload.includes(CHARTS_METRICS.SPEEDTEST) ? {} : state.getSiteSpeedtest,
        getSiteLinkProbeNative: action.payload.includes(CHARTS_METRICS.LINK_PROBE_NATIVE) ? {data: {probeSuccess: {series: {}}}} : state.getSiteLinkProbeNative,
        getSiteLinkProbeVtp: action.payload.includes(CHARTS_METRICS.LINK_PROBE_VTP) ? {data: {probeSuccess: {series: {}}}} : state.getSiteLinkProbeVtp,
        getSiteQoeStreaming: action.payload.includes(CHARTS_METRICS.QOE_STREAMING) ? {data: {QoEStreamingPoints: {series: {}}}} : state.getSiteQoeStreaming,
        getSiteQoeRtc: action.payload.includes(CHARTS_METRICS.QOE_RTC) ? {data: {QoEGamingPoints: {series: {}}}} : state.getSiteQoeRtc,
      }
    }
    case DELETE_SITE_CHART_SERIES:{
      delete state.getSiteDataRate?.data?.rateAvg?.series[action.payload];
      delete state.getSiteLatencyJitter?.data?.latency?.series[action.payload];
      delete state.getSiteLatencyJitter?.data?.jitter?.series[action.payload];
      delete state.getSiteThroughput?.data?.avgDownSpeed?.series[action.payload];
      delete state.getSiteThroughput?.data?.avgUpSpeed?.series[action.payload];
      delete state.getSiteLatencyJitter?.data?.jitter?.series[action.payload];
      delete state.getSiteLinkStatus?.data?.probeSuccess?.series[action.payload];
      delete state.getSiteRSSI?.data?.rssiAvg?.series[action.payload];
      delete state.getSiteSINR?.data?.sinrAvg?.series[action.payload];
      delete state.getSiteVSATSnr?.data?.avg?.series[action.payload];
      delete state.getSiteVSATTxpower?.data?.avg?.series[action.payload];
      delete state.getSiteVSATPower?.data?.avg?.series[action.payload];
      delete state.getSiteVSATSymbolRate?.data?.avg?.series[action.payload];
      delete state.getSiteClients?.data?.clients?.series[action.payload];
      delete state.getSiteUsage?.data?.totalUsage?.series[action.payload];
      delete state.getSiteSystemUptime?.data?.status?.series[action.payload];
      delete state.getSiteLanStatus?.data?.LAN_status?.series[action.payload];
      delete state.getSiteFlows?.data?.flows?.series[action.payload];
      delete state.getSiteSpeed?.data?.avgDownSpeed?.series[action.payload];
      delete state.getSiteSpeed?.data?.avgUpSpeed?.series[action.payload];
      delete state.getSiteLatency?.data?.latency?.series[action.payload];
      delete state.getSiteStatus?.data?.status?.series[action.payload];
      delete state.getSiteLinkProbeNative?.data?.probeSuccess?.series[action.payload];
      delete state.getSiteLinkProbeVtp?.data?.probeSuccess?.series[action.payload];
      delete state.getSiteQoeStreaming?.data?.QoEStreamingPoints?.series[action.payload];
      delete state.getSiteQoeRtc?.data?.QoEGamingPoints?.series[action.payload];

      return {
        ...state,
        getSiteRSSI: {
          ...state.getSiteRSSI
        },
        getSiteSINR: {
          ...state.getSiteSINR
        },
        getSiteDataRate: {
          ...state.getSiteDataRate
        },
        getSiteLinkStatus: {
          ...state.getSiteLinkStatus
        },
        getSiteLatencyJitter: {
          ...state.getSiteLatencyJitter
        },
        getSiteThroughput: {
          ...state.getSiteThroughput
        },
        getSiteVSATSnr: {
          ...state.getSiteVSATSnr
        },
        getSiteVSATTxpower: {
          ...state.getSiteVSATTxpower
        },
        getSiteVSATPower: {
          ...state.getSiteVSATPower
        },
        getSiteVSATSymbolRate: {
          ...state.getSiteVSATSymbolRate
        },
        getSiteClients: {
          ...state.getSiteClients
        },
        getSiteUsage: {
          ...state.getSiteUsage
        },
        getSiteSystemUptime: {
          ...state.getSiteSystemUptime
        },
        getSiteLanStatus: {
          ...state.getSiteLanStatus
        },
        getSiteFlows: {
          ...state.getSiteFlows
        },
        getSiteSpeed: {
          ...state.getSiteSpeed
        },
        getSiteLatency: {
          ...state.getSiteLatency
        },
        getSiteStatus: {
          ...state.getSiteStatus
        },
        getSiteSpeedtest: {
          ...state.getSiteSpeedtest
        },
        getSiteLinkProbeNative: {
          ...state.getSiteLinkProbeNative
        },
        getSiteLinkProbeVtp: {
          ...state.getSiteLinkProbeVtp
        },
        getSiteQoeStreaming: {
          ...state.getSiteQoeStreaming
        },
        getSiteQoeRtc: {
          ...state.getSiteQoeRtc
        }
      }
    }
    case CLEAR_SITE_CHART_EVENTS:{
      return {
        ...state,
        getEdgeEvents: {}
      }
    }
    case GET_USER_LOGIN_HISTORY:{
      return {
        ...state,
        getUserLoginHistory: action.payload
      }
    }
    case SET_INVENTORY_LISTING_LOADING:
      return {
        ...state,
        inventoryListingLoading: action.payload
      }
    case SET_T0_AND_OPEN_EDGE_LOADING:
      return {
        ...state,
        t0AndOpenEdgeLoading: action.payload
      }
    case SET_THREAT_KONTROL_LOADING:
      return {
        ...state,
        threatKontrolLoading: action.payload
      }
    case GET_TRAFFIC_POLICY_LISTING_LOADING:
      return {
        ...state,
        trafficPolicyListingLoading: action.payload
      }
    case GET_WAN_PROFILE_LISTING_LOADING:
      return {
        ...state,
        wanProfileListingLoading: action.payload
      }
    case GET_POP_IPS_LISTING_LOADING:
      return {
        ...state,
        getPopIpsListingLoading: action.payload
      }
    case APPLY_TRAFFIC_POLICY_LOADING: 
      return {
        ...state, 
        applyTrafficPolicyLoading: action.payload
      }
    case APPLY_WAN_PROFILE_LOADING:
      return {
        ...state,
        applyWanProfileLoading: action.payload
      }
    case APPLY_FIREWALL_RULE_LOADING:
      return {
        ...state,
        applyFirewallRuleLoading: action.payload
      }
    case APPLY_HUB_IP_CONFIG_LOADING:
      return {
        ...state,
        applyHubIPConfigLoading: action.payload
      }
    case COPY_QUOTA_CONFIG_LOADING:
      return {
        ...state,
        copyQuotaConfigLoading: action.payload
      }
    case GET_TRAFFIC_POLICY_LOG_LIST_LOADING:
      return {
        ...state,
        trafficPolicyLogListLoading: action.payload
      }
    case GET_INVENTORY_DEPLOYED_DATA:{
      return {
        ...state,
        getInventoryDeployedData: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_T0_AND_OPEN_EDGE_DATA:{
      return {
        ...state,
        getT0AndOpenEdgeData: action.payload,
        inventoryListingLoading: false
      }
    }
    case DOWNLOAD_INVENTORY_DEPLOYED_DATA: {
      return {
        ...state,
        downloadInventoryDeployedData: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_INVENTORY_WAREHOUSE_TABLE_DATA:{
      return {
        ...state,
        getInventoryWarehouseTableData: action.payload,
        inventoryListingLoading: false
      }
    }
    case INVENTORY_WAREHOUSE_CHANGE_GROUP:{
      return {
        ...state,
        inventoryWarehouseChangeGroupData: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_INVENTORY_WAREHOUSE_VESSEL_LIST:{
      return {
        ...state,
        getInventoryWarehouseVesselList: action.payload,
        inventoryListingLoading: false
      }
    }
    case SET_ASSIGN_INVENTORY_TO_VESSEL: {
      return {
        ...state,
        setAssignInventoryToVessel: action.payload
      }
    }
    case ASSIGN_INVENTORY_TO_VESSEL:{
      return {
        ...state,
        assignInventoryToVessel: action.payload,
        setAssignInventoryToVessel: false
      }
    }
    case GET_LICENSE_TYPES:{
      return {
        ...state,
        getLicenseTypes: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_DPS_DEFAULT_ACTIVE_CONFIG:{
      return {
        ...state,
        getDpsDefaultActiveConfig: action.payload,
      }
    }
    case CREATE_AND_ASSIGN_INVENTORY:{
      return {
        ...state,
        createVessel: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_DP_LIST:{
      return {
        ...state,
        getDPList: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_INVENTORY_DEPLOYED_DATA_COUNT:{
      return {
        ...state,
        getInventoryDeployedDataCount: action.payload,
        inventoryListingLoading: false
      }
    }
    case SET_INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE: {
      return {
        ...state,
        setInventoryAssignDeviceToWarehouse: action.payload
      }
    }
    case INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE:{
      return {
        ...state,
        inventoryAssignDeviceToWarehouse: action.payload,
        setInventoryAssignDeviceToWarehouse: false
      }
    }
    case INVENTORY_DELETE_SITE:{
      return {
        ...state,
        inventoryDeleteSite: action.payload,
        inventoryListingLoading: false
      }
    }
    case INVENTORY_TOP_WIDGETS_REGISTERED_COUNT:{
      return {
        ...state,
        inventoryTopWidgetsRegisteredCount: action.payload,
        setLoading: false
      }
    }
    case INVENTORY_TOP_WIDGET_KAAS_COUNT:{
      return {
        ...state,
        inventoryTopWidgetsKaasCount: action.payload,
        setLoading: false
      }
    }
    case INVENTORY_TOP_WIDGETS_WAREHOUSE_COUNT:{
      return {
        ...state,
        inventoryTopWidgetsWarehouseCount: action.payload,
        setLoading: false
      }
    }
    case INVENTORY_DOWNLOAD_CONFIG: {
      return {
        ...state,
        inventoryDownloadConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case INVENTORY_CHANGE_GROUP: {
      return {
        ...state,
        inventoryChangeGroup: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_CONFIG_PRODUCTS: {
      return {
        ...state,
        getConfigProducts: action.payload,
        inventoryListingLoading: false
      }
    }
    case APPLY_GOLDEN_CONFIG: {
      return {
        ...state,
        applyGoldenConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case BACKUP_CONFIG_NOW: {
      return {
        ...state,
        backupConfigNow: action.payload,
        inventoryListingLoading: false
      }
    }
    case DEVICE_LATEST_EOS_VERSION: {
      return {
        ...state,
        deviceLatestEosVersion: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_DEVICE_LIST: {
      return {
        ...state,
        getDeviceList: action.payload,
        inventoryListingLoading: false
      }
    }
    case CREATE_INVENTORY_GROUP: {
      return {
        ...state,
        createInventoryGroup: action.payload,
        inventoryListingLoading: false
      }
    }
    case UPDATE_INVENTORY_GROUP: {
      return {
        ...state,
        updateInventoryGroup: action.payload,
        inventoryListingLoading: false
      }
    }
    case DELETE_INVENTORY_GROUP: {
      return {
        ...state,
        deleteInventoryGroup: action.payload,
        inventoryListingLoading: false
      }
    }
    case CREATE_THREAT_KONTROL: {
      return {
        ...state,
        createThreatKontrol: action.payload,
        threatKontrolLoading: false
      }
    }
    case UPDATE_THREAT_KONTROL: {
      return {
        ...state,
        updateThreatKontrol: action.payload,
        threatKontrolLoading: false
      }
    } 
    case DELETE_THREAT_KONTROL: {
      return {
        ...state,
        deleteThreatkontrol: action.payload,
        threatKontrolLoading: false
      }
    }
    case GET_TRAFFIC_POLICY_LIST: {
      return {
        ...state,
        getTrafficPolicy: action.payload,
        inventoryListingLoading: false,
      }
    }
    case GET_TRAFFIC_POLICY_LIST_V3: {
      return {
        ...state,
        getTrafficPolicyV3: action.payload,
        inventoryListingLoading: false,
      }
    }
    case GET_TRAFFIC_POLICY_LIST_V4: {
      return {
        ...state,
        getTrafficPolicyV4: action.payload,
        inventoryListingLoading: false,
      }
    }
    case GET_TRAFFIC_POLICY_LIST_V5: {
      return {
        ...state,
        getTrafficPolicyV5: action.payload,
        inventoryListingLoading: false,
      }
    }
    case APPLY_TRAFFIC_POLICY: {
      return {
        ...state,
        applyTrafficPolicy: action.payload,
        inventoryListingLoading: false
      }
    }
    case APPLY_FIREWALL_RULE: {
      return {
        ...state,
        applyFirewallRule: action.payload,
        inventoryListingLoading: false
      }
    }
    case APPLY_WAN_PROFILE: {
      return {
        ...state,
        applyWanProfile: action.payload,
        inventoryListingLoading: false
      }
    }
    case APPLY_HUB_IP_CONFIG: {
      return {
        ...state,
        applyHubIPConfig: action.payload,
        inventoryListingLoading: false,
      }
    }
    case GET_TRAFFIC_POLICY_LOG_LIST: {
      return {
        ...state,
        getTraficPolicyApplyLogs: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_WAN_PROFILE_LIST: {
      return {
        ...state,
        getWANProfileList: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_POP_IPS_LIST: {
      return {
        ...state,
        getPopIpsList: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_INVENTORY_GROUP_LIST: {
      return {
        ...state,
        getInventoryGroupList: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_INVENTORY_GROUP_LIST_COUNT: {
      return {
        ...state,
        getInventoryGroupListCount: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_SITE_INFO_LIST: {
      return {
        ...state,
        getSiteInfoList: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_SITE_INFO_LIST_COUNT: {
      return {
        ...state,
        getSiteInfoListCount: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_BACKUP_CONFIG_DATA: {
      return {
        ...state,
        backupConfigData: action.payload,
        inventoryListingLoading: false
      }
    }
    case COPY_CONFIG_TO_MANUAL_BACKUP: {
      return {
        ...state,
        copyConfigToManualBackup: action.payload,
        inventoryListingLoading: false
      }
    }
    case DELETE_MANUAL_BACKUP_CONFIG: {
      return {
        ...state,
        deleteManualBackupConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_DEFAULT_CONFIG_LISTING: {
      return {
        ...state,
        getDefaultConfigListing: action.payload,
        inventoryListingLoading: false
      }
    }
    case EDIT_DEFAULT_CONFIG: {
      return {
        ...state,
        editDefaultConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case CREATE_DEFAULT_CONFIG: {
      return {
        ...state,
        createDefaultConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case DELETE_DEFAULT_CONFIG: {
      return {
        ...state,
        deleteDefaultConfig: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_DEFAULT_PRODUCTS: {
      return {
        ...state,
        getDefaultProducts: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_CONFIG_SOFTWARE_VERSIONS: {
      return {
        ...state,
        getConfigSoftwareVersions: action.payload,
        inventoryListingLoading: false
      }
    }
    case GET_CONFIG_STATUS: {
      let [configStatus, totalCount] = action.payload?.data?.rows[0]
      return {
          ...state,
          getConfigStatus: configStatus || [],
          getConfigStatusTotalCount: totalCount ? totalCount : 0
      }
  }
    case GET_GROUP_TRANSCATION_CONFIG_STATUS: {
      let [configStatus, totalCount] = action.payload?.data?.rows[0]
      return {
          ...state,
          getGroupTranscationConfigStatus: configStatus || [],
          getGroupTranscationConfigStatusTotalCount: totalCount ? totalCount : 0
      }
  }
    case HIGH_AVAILABILITY:{ 
      return{
        ...state,
        highAvailability: action.payload,
        setLoading: false
      }  
    }
    case GET_HIGH_AVAILABILITY_DEVICE:{
      return {
        ...state,
        getHighAvailabilityDevice: action.payload
      }
    }
    case CREATE_HA:{
      return {
        ...state,
        createHighAvailability: action.payload,
        setLoading: false
      }
    }
    case DELETE_HIGH_AVAILABILITY: {
      return {
        ...state,
        deleteHighAvailability: action.payload,
        setLoading: false
      }
    }
    case SYNC_DEVICE:{
      return {
        ...state,
        syncDevice: action.payload
      }
    }
    case UPDATE_DEVICE:{
      return {
        ...state,
        updateDevice: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_LOADING:
      return {
        ...state,
        activityReportsLoading: action.payload
      }
    case SET_ACTIVITY_REPORTS_APPS_LOADING: {
      return {
        ...state,
        activityReportsAppsLoading: action.payload,
      }
    }
    case SET_ACTIVITY_REPORTS_VLANS_LOADING: {
      return {
        ...state,
        activityReportsVlansLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_WIDGET_LOADING: {
      return {
        ...state,
        activityReportsWidgetLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_CATEGORY_PIE_LOADING: {
      return {
        ...state,
        activityReportsCategoryPieLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_APPLICATION_PIE_LOADING: {
      return {
        ...state,
        activityReportsApplicationPieLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_USAGE_LINE_CHART_LOADING: {
      return {
        ...state,
        activityReportsUsageLineChartLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_TOP_APP_LINE_CHART_LOADING: {
      return {
        ...state,
        activityReportsTopAppLineChartUsage: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_TOP_USERS_LOADING: {
      return {
        ...state,
        activityReportsTopUsersLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_TOP_SITES_LOADING: {
      return {
        ...state,
        activityReportsTopSitesLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING: {
      return {
        ...state,
        setActivityReportsBottomTableLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT_LOADING: {
      return {
        ...state,
        setOperationalUsageDataTableLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING: {
      return {
        ...state,
        setOperationalUsageDataTableCountLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_LOADING: {
      return {
        ...state,
        setOperationalUsageTimeseriesTableLoading: action.payload 
      }
    }
    case SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT_LOADING: {
      return {
        ...state,
        setOperationalUsageTimeseriesTableCountLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE_LOADING: {
      return {
        ...state,
        setOperationalUsageDataSingleSitePerWanWiseLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST_LOADING: {
      return {
        ...state,
        setOperationalUsageFilterDeviceListLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_FILTER_SW_LIST_LOADING: {
      return {
        ...state,
        setOperationalUsageFilterSWListLoading: action.payload
      }
    }
    case SET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE_LOADING: {
      return {
        ...state,
        setOperationalUsageReportsDownloadLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_BOTTOM_TABLE_DOMAIN_LOADING: {
      return {
        ...state,
        setActivityReportsBottomTableDomainLoading: action.payload
      }
    }
    case SET_ACTIVITY_REPORTS_BOTTOM_TABLE_IP_LOADING: {
      return {
        ...state,
        setActivityReportsBottomTableIPLoading: action.payload
      }
    }
    case SET_REPORTS__TOP_BLOCKED_APP_BOTTOM_TABLE_LOADING: {
      return {
        ...state,
        setActivityReportsTopBlockedAppBottomTableLoading: action.payload
      }
    }
    case SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK: {
      return {
        ...state,
        setTopWidgetRiskScore: action.payload
      }
    }
    case SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK: {
      return {
        ...state,
        setPieChartAllSiteRisk: action.payload
      }
    }
    case GET_ALL_USERNAMES:{
      return {
        ...state,
        getAllUsernames: action.payload,
        activityReportsLoading: false
      }
    }
    case GET_ALL_APPLICATIONS:{
      return {
        ...state,
        getAllApplications: action.payload,
        activityReportsAppsLoading: false
      }
    }
    case GET_ALL_VLANS:{
      return {
        ...state,
        getAllVlans: action.payload,
        activityReportsVlansLoading: false
      }
    }
    case GET_DPI_REPORTS_USER_MAX_RISK_TYPES: {
      return {
        ...state,
        riskList: action.payload,
        setLoading: false
      }
    }
    case GET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK: {
      return {
        ...state,
        topWidgetAllSiteRiskScore: action.payload,
        setTopWidgetRiskScore: false
      }
    }
    case GET_DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK: {
      return {
        ...state,
        topWidgetSingleSiteRiskScore: action.payload,
        setTopWidgetRiskScore: false
      }
    }
    case GET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK: {
      return {
        ...state,
        getPieChartAllSiteRisk: action.payload,
        setPieChartAllSiteRisk: false
      }
    }
    case GET_DPI_REPORTS_PIE_CHART_SINGLESITE_RISK: {
      return {
        ...state,
        getPieChartSingleSiteRisk: action.payload,
        setPieChartAllSiteRisk: false
      }
    }
    case GET_USAGE_WIDGETS:{
      return {
        ...state,
        UsageWidgetsData: action.payload,
        activityReportsWidgetLoading: false
      }
    }
    case GET_USAGE_TABLE_DATA:{
      return {
        ...state,
        UsageTableData: action.payload,
        activityReportsTopUsersLoading: false
      }
    }
    case GET_LINE_CHART_USAGE: {
      return {
        ...state,
        getLineChartUsage: action.payload,
        activityReportsUsageLineChartLoading: false
      }
    }
    case GET_CATEGORY_PIE_CHART_USAGE: {
      return {
        ...state,
        CategoryPieChartUsageData: action.payload,
        activityReportsCategoryPieLoading: false
      }
    }
    case GET_TOP_APPLICATION_LINE_CHART: {
      return {
        ...state,
        getTopApplicationLineChart: action.payload,
        activityReportsTopAppLineChartUsage: false
      }
    }
    case GET_TOP_SITES_TABLE_DATA: {
      return {
        ...state,
        TopSitesTableData: action.payload,
        activityReportsTopSitesLoading: false
      }
    }
    case GET_TOP_APP_TOP_BLOCKED_PIE_CHART: {
      return {
        ...state,
        TopAppTopBlockedPieChartData: action.payload,
        activityReportsApplicationPieLoading: false
      }
    }
    case GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION: {
      return {
        ...state,
        getTopDownloadUploadBlockedApplication: action.payload,
        setActivityReportsBottomTableLoading: false
      }
    }
    case GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_DOMAIN: {
      return {
        ...state,
        getTopDownloadUploadBlockedDomain: action.payload,
        setActivityReportsBottomTableDomainLoading: false
      }
    }
    case GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_IP: {
      return {
        ...state,
        getTopDownloadUploadBlockedIp: action.payload,
        setActivityReportsBottomTableIPLoading: false
      }
    }
    case GET_ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE: {
      return {
        ...state,
        getContentFiltersTable: action.payload,
        setActivityReportsBottomTableLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE: {
      return {
        ...state,
        getOperationalUsageDataTable: action.payload,
        setOperationalUsageDataTableLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT: {
      return {
        ...state,
        getOperationalUsageDataTableCount: action.payload?.data?.rows[0],
        setOperationalUsageDataTableCountLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_TIMESERIES_TABLE: {
      return {
        ...state,
        getOperationalUsageTimeseriesTable: action.payload,
        setOperationalUsageTimeseriesTableLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT: {
      return {
        ...state,
        getOperationalUsageTimeseriesTableCount: action.payload?.data?.rows[0],
        setOperationalUsageTimeseriesTableCountLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE: {
      return {
        ...state,
        getOperationalUsageDataSingleSitePerWanWise: action.payload,
        setOperationalUsageDataSingleSitePerWanWiseLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST: {
      return {
        ...state,
        getOperationalUsageFilterDeviceList: action.payload,
        setOperationalUsageFilterDeviceListLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_FILTER_SW_LIST: {
      return {
        ...state,
        getOperationalUsageFilterSWList: action.payload,
        setOperationalUsageFilterSWListLoading: false
      }
    }
    case GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE: {
      return {
        ...state,
        getOperationalUsageReportsDownload: action.payload,
        setOperationalUsageReportsDownloadLoading: false
      }
    }
    case CREATE_DOWNLOAD_SERVICE: {
      return {
        ...state,
        createDownloadService: action.payload,
        activityReportsLoading: false
      }
    }
    case CREATE_DOWNLOAD_SERVICE_FIREWALL: {
      return {
        ...state,
        createDownloadServiceFirewall: action.payload,
        setLoading: false
      }
    }
    case CREATE_DOWNLOAD_SERVICE_CONTENT_FILTER: {
      return {
        ...state,
        createDownloadServiceContentFilter: action.payload,
        setLoading: false
      }
    }
    case SET_GENERATED_REPORTS_TABLE_LOADING: {
        return {
          ...state,
          setGeneratedReportsTableLoading: action.payload
        }
    }
    case SET_GENERATED_REPORTS_TABLE_COUNT_LOADING: {
        return {
          ...state,
          setGeneratedReportsTableCountLoading: action.payload
        }
    }
    case GET_GENERATED_REPORTS_TABLE: {
        return {
          ...state,
          getGeneratedReportsTable: action.payload?.result,
          getGeneratedReportsTableCount: action.payload?.result_info?.total_count || 0,
          setGeneratedReportsTableLoading: false
        }
    }
    case AUDIT_LOGS:
      return {
        ...state,
        auditLogs: action.payload
      }
    case DOMAIN_NAME: {
      return {
        ...state,
        hostName: action.payload
      }
    }
    case DOMAIN_CONFIG: {
      return {
        ...state,
        domainConfig: action.payload
      }
    }
    case GET_FIREALL_WIDGETS: {
      return {
        ...state,
        getFirewallWidgets: action.payload,
        setFirewallWidgetsLoading: false,
      }
    }
    case GET_FIREWALL_ACTIONS_TODAY_GRAPH:
      return {
        ...state,
        getFirewallActiveToday: action.payload,
        setFirewallActiveTodayLoading: false,
      }
    case GET_FIREWALL_RULE_NAME_GRAPH:
      return {
        ...state,
        getFirewallRuleName: action.payload,
        setFirewallRuleNameLoading: false,
      }
    case GET_FIREWALL_TABLE_DATA:
      return {
        ...state,
        getFirewallTableData: action.payload,
        setFirewallTableDataLoading: false,
      }
    case GET_FIREWALL_RULE_NAME_FILTER:
      return {
        ...state,
        getFirewallRuleNameFilter: action.payload,
        setFirewallRuleNameFilterLoading: false,
      }
    case GET_FIREWALL_TABLE_DATA_COUNT:
      return {
        ...state,
        getFirewallTableDataCount: action.payload,
        setFirewallTableDataCountLoading: false,
      }
    case GET_FIREWALL_LINE_CHART:
      return {
        ...state,
        getFirewallLineChart: action.payload,
        setFirewallLineChartLoading: false,
      } 

      case SET_LOADING_FIREWALL_WIDGETS: {
        return {
          ...state,
          setFirewallWidgetsLoading: action.payload
        }
      }
      
      case SET_LOADING_FIREWALL_RISTRICT_PIECHART: {
        return {
          ...state,
          setFirewallActiveTodayLoading: action.payload
        }
      }

      case SET_LOADING_FIREWALL_PIE_RULENAME: {
        return {
          ...state,
          setFirewallRuleNameLoading: action.payload
        }
      }

      case SET_LOADING_FIREWALL_RULE_FILTER: {
        return {
          ...state,
          setFirewallRuleNameFilterLoading: action.payload
        }
      }

      case SET_LOADING_FIREWALL_TABLE_DATA: {
        return {
          ...state,
          setFirewallTableDataLoading: action.payload
        }
      }

      case SET_LOADING_FIREWALL_COUNTS: {
        return {
          ...state,
          setFirewallTableDataCountLoading: action.payload
        }
      }

      case SET_LOADING_FIREWALL_LINECHART: {
        return {
          ...state,
          setFirewallLineChartLoading: action.payload
        }
      }
      case GET_TOP_WIDGET_AVAILABILITY: {
        return {
          ...state,
          setTopWidgetAvailability: action.payload
        }
      }
      case GET_TOP_WIDGET_DATA_NEW_SUMM: {
        return {
          ...state,
          setTopWidgetData: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_TABLE: {
        return {
          ...state,
          setIamLoginHistoryTable: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_TABLE_COUNT: {
        return {
          ...state,
          setIamLoginHistoryCountLoading: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_TOP_WIDGET: {
        return {
          ...state,
          setIamLoginHistoryTopWidget: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_TIMESERIES_CHART: {
        return {
          ...state,
          setIamLoginHistoryTimeseriesChart: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_FILTER_USER: {
        return {
          ...state,
          setIamLoginHistoryFilterUser: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_FILTER_APP: {
        return {
          ...state,
          setIamLoginHistoryFilterApp: action.payload
        }
      }
      case SET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST: {
        return {
          ...state,
          setIamLoginHistoryFilterDescList: action.payload
        }
      }
      case GET_IAM_LOGIN_HISTORY_TABLE: {
        return {
          ...state,
          getIamLoginHistoryTable: action.payload,
          setIamLoginHistoryTable: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_TABLE_COUNT: {
        return {
          ...state,
          getIamLoginHistortTableCount: action.payload,
          setIamLoginHistoryCountLoading: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_TOP_WIDGET: {
        return {
          ...state,
          getIamLoginHistoryTopWidget: action.payload,
          setIamLoginHistoryTopWidget: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_TIMESERIES_CHART: {
        return {
          ...state,
          getIamLoginHistoryTimeseriesChart: action.payload,
          setIamLoginHistoryTimeseriesChart: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_FILTER_USER: {
        return {
          ...state,
          getIamLoginHistoryFilterUser: action.payload,
          setIamLoginHistoryFilterUser: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_FILTER_APP: {
        return {
          ...state,
          getIamLoginHistoryFilterApp: action.payload,
          setIamLoginHistoryFilterApp: false
        }
      }
      case GET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST: {
        return {
          ...state,
          getIamLoginHistoryFilterDescList: action.payload,
          setIamLoginHistoryFilterDescList: false
        }
      }
      case GET_MAINTENANCE_INFO: {
        return {
          ...state,
          getMaintenanceInfo: action.payload
        }
      }
      case GET_GROUP_SETTINGS_DATA: {
        return {
          ...state,
          getGroupSettingsData: action.payload,
          setLoading: false
        }
      }
      case UPDATE_GROUP_SETTINGS_DATA:{
        return {
          ...state,
          updateGroupSettingsData: action.payload,
          setLoading: false
        }
      }
      case SET_CF_TOP_USERS_TABLE_LOADING: {
        return {
          ...state,
          setContentFilterTopUsersTableLoading: action.payload
        }
      }
      case SET_CF_TOP_USERS_TABLE_COUNT_LOADING: {
        return {
          ...state,
          setContentFilterTopUsersTableCountLoading: action.payload
        }
      }
      case SET_CF_TOP_SITES_TABLE_LOADING: {
        return {
          ...state,
          setContentFilterTopSitesTableLoading: action.payload
        }
      }
      case SET_CF_TOP_SITES_TABLE_COUNT_LOADING: {
        return {
          ...state,
          setContentFilterTopSitesTableCountLoading: action.payload
        }
      }
      case SET_CF_CATEGORY_PIE_LOADING: {
        return {
          ...state,
          setContentFilterCategoryPieLoading: action.payload,
        }
      }
      case SET_CF_APPLICATION_PIE_LOADING: {
        return {
          ...state,
          setContentFilterApplicationPieLoading: action.payload,
        }
      }
      case SET_CF_SUBCAT_PIE_LOADING: {
        return {
          ...state,
          setContentFilterSubCategoryPieLoading: action.payload,
        }
      }
      case SET_CF_LINECHART_LOADING: {
        return {
          ...state,
          setContentFilterLinechartLoading: action.payload
        }
      }
      case SET_CF_TW_TOP_SITE_LOADING: {
        return {
          ...state,
          setContentFilterTopWidgetTopSiteLoading: action.payload,
        }
      }
      case SET_CF_TW_TOP_USER_LOADING: {
        return {
          ...state,
          setContentFilterTopWidgetTopUserLoading: action.payload,
        }
      }
      case SET_CF_TW_TOP_CATEGORY_LOADING: {
        return {
          ...state,
          setContentFilterTopWidgetTopCategoryLoading: action.payload,
        }
      }
      case SET_CF_TW_TOP_DOMAIN_LOADING: {
        return {
          ...state,
          setContentFilterTopWidgetTopDomainLoading: action.payload,
        }
      }
      case SET_CF_CATEGORY_LIST_LOADING: {
        return {
          ...state,
          setContentFilterCategoryListLoading: action.payload
        }
      }
      case SET_CF_APP_LIST_LOADING: {
        return {
          ...state,
          setContentFilterAppListLoading: action.payload
        }
      }
      case SET_CF_USER_LIST_LOADING: {
        return {
          ...state,
          setContentFilterUserListLoading: action.payload
        }
      }
      case SET_CF_VLAN_LIST_LOADING: {
        return {
          ...state,
          setContentFilterVlanListLoading: action.payload
        }
      }
      case SET_CF_DEVICE_LIST_LOADING: {
        return {
          ...state,
          setContentFilterDeviceListLoading: action.payload
        }
      }
      case SET_CF_SUb_CATEGORY_LIST_LOADING: {
        return {
          ...state,
          steContentFilterSubCategoryListLoading: action.payload
        }
      }
      case GET_CF_TOP_USERS_TABLE: {
        return {
          ...state,
          getContentFilterTopUsersTable: action.payload,
          setContentFilterTopUsersTableLoading: false
        }
      }
      case GET_CF_TOP_USERS_TABLE_COUNT: {
        return {
          ...state,
          getContentFilterTopUsersTableCount: action.payload,
          setContentFilterTopUsersTableCountLoading: false
        }
      }
      case GET_CF_TOP_SITES_TABLE: {
        return {
          ...state,
          getContentFilterTopSitesTable: action.payload,
          setContentFilterTopSitesTableLoading: false
        }
      }
      case GET_CF_TOP_SITES_TABLE_COUNT: {
        return {
          ...state,
          getContentFilterTopSitesTableCount: action.payload,
          setContentFilterTopSitesTableCountLoading: false
        }
      }
      case GET_CF_CATEGORY_PIE: {
        return {
          ...state,
          getContentFilterCategoryPie: action.payload,
          setContentFilterCategoryPieLoading: false
        }
      }
      case GET_CF_APPLICATION_PIE: {
        return {
          ...state,
          getContentFilterApplicationPie: action.payload,
          setContentFilterApplicationPieLoading: false
        }
      }
      case GET_CF_SUBCAT_PIE: {
        return {
          ...state,
          getContentFilterSubCategoryPie: action.payload,
          setContentFilterSubCategoryPieLoading: false
        }
      }
      case GET_CF_LINECHART: {
        return {
          ...state,
          getContentFilterLinechart: action.payload,
          setContentFilterLinechartLoading: false
        }
      }
      case GET_CF_TW_TOP_SITE: {
        return {
          ...state,
          getContentFilterTopWidgetTopSite: action.payload,
          setContentFilterTopWidgetTopSiteLoading: false
        }
      }
      case GET_CF_TW_TOP_USER: {
        return {
          ...state,
          getContentFilterTopWidgetTopUser: action.payload,
          setContentFilterTopWidgetTopUserLoading: false
        }
      }
      case GET_CF_TW_TOP_CATEGORY: {
        return {
          ...state,
          getContentFilterTopWidgetTopCategory: action.payload,
          setContentFilterTopWidgetTopCategoryLoading: false
        }
      }
      case GET_CF_TW_TOP_DOMAIN: {
        return {
          ...state,
          getContentFilterTopWidgetTopDomain: action.payload,
          setContentFilterTopWidgetTopDomainLoading: false
        }
      }
      case GET_CF_CATEGORY_LIST: {
        return {
            ...state,
            getContentFilterCategoryList: action.payload,
            setContentFilterCategoryListLoading: false
        }
      }
      case GET_CF_APP_LIST: {
        return {
            ...state,
            getContentFilterAppList: action.payload,
            setContentFilterAppListLoading: false
        }
      }
      case GET_CF_USER_LIST: {
        return {
            ...state,
            getContentFilterUserList: action.payload,
            setContentFilterUserListLoading: false
        }
      }
      case GET_CF_VLAN_LIST: {
        return {
            ...state,
            getContentFilterVlanList: action.payload,
            setContentFilterVlanListLoading: false
        }
      }
      case GET_CF_DEVICE_LIST: {
        return {
            ...state,
            getContentFilterDeviceList: action.payload,
            setContentFilterDeviceListLoading: false
        }
      }
      case GET_CF_SUB_CATEGORY_LIST: {
        return {
          ...state,
          getContentFilterSubCategoryList: action.payload,
          steContentFilterSubCategoryListLoading: false
        }
      }
      case UPDATE_NEW_SUMMARY_START_DATE:
        return {
            ...state,
            newSummaryStartDate: action.payload
        }
      case UPDATE_NEW_SUMMARY_END_DATE:
        return {
            ...state,
            newSummaryEndDate: action.payload
        }
      case UPDATE_NEW_SUMMARY_WAN_TYPE:
      return {
          ...state,
          newSummaryWanType: action.payload
      }
      case UPDATE_NEW_SEVERITY_TYPE:
        return {
          ...state,
          newSummarySeverityType: action.payload
        }
      case UPDATE_NEW_SUMMARY_SERVICE_LINE:
        return {
            ...state,
            newSummaryServiceLine: action.payload
        }
        case NEW_SUMMARY_SERVICE_ACCOUNTS:
          let credentials = action?.payload?.data?.rows?.[0]?.[0];
        
          let serviceAccounts = [
            { accountNumber: "All", accountName: "All Accounts" }, 
            ...(credentials?.map((cr) => cr?.serviceAccounts || []).flat() || [])
          ];
        
          return {
            ...state,
            newSummaryServiceAccount: serviceAccounts
          };
          case UPDATE_NEW_SUMMARY_SERVICE_ACCOUNTS:
            return {
                ...state,
                selectedServiceAccount: action?.payload
            }
      case GET_DRAWER_OPEN: {
        return {
          ...state,
          getDrawerOpen: action.payload
        }
      }
      case SET_DASHBOARD_PIE_USAGEPER_WANTYPE_LOADING: {
        return {
          ...state,
          setDashboardPieUsageperWantypeLoading: action.payload
        }
      }
      case SET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE_LOADING: {
        return {
          ...state,
          setDashboardHistogramUsagePerWantypeLoading: action.payload
        }
      }
      case SET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_LOADING: {
        return {
          ...state,
          setDashboardHistogramUsagePerSiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_USAGE_PIE_TOP10_APPS_LOADING: {
        return {
          ...state,
          setDashboardUsagePieTop10AppsLoading: action.payload
        }
      }
      case SET_DASHBOARD_PIE_WAN_USAGE_BYSITE_LOADING: {
        return {
          ...state,
          setDashboardPieWanUsageBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK_LOADING: {
        return {
          ...state,
          setDashboardUsageHistogramUpDownLinkLoading: action.payload
        }
      }
      case SET_DASHBOARD_USAGE_HISTOGRAM_BYSITE_LOADING: {
        return {
          ...state,
          setDashboardUsageHistogramBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE_LOADING: {
        return {
          ...state,
          setDashboardUptimeSiteStatisticsTableLoading: action.payload
        }
      }
      case SET_DASHBOARD_UPTIME_LINECHART_BYWAN_LOADING: {
        return {
          ...state,
          setDashboardUptimeLinechartByWanLoading: action.payload
        }
      }
      case SET_DASHBOARD_UPTIME_PIECHART_BYSITE_LOADING: {
        return {
          ...state,
          setDashboardUptimePiechartBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_UPTIME_LINECHART_BYSITE_LOADING: {
        return {
          ...state,
          setDashboardUptimeLinechartBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_UPTIME_PIECHART_BYWAN_LOADING: {
        return {
          ...state,
          setDashboardUptimePiechartByWanLoading: action.payload
        }
      }
      case GET_DASHBOARD_PIE_USAGEPER_WANTYPE: {
        return {
          ...state,
          newUsageperWantype: action.payload,
          setDashboardPieUsageperWantypeLoading: false
        }
      }
      case GET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE: {
        return {
          ...state,
          newHistogramUsageperWantype: action.payload,
          setDashboardHistogramUsagePerWantypeLoading: false
        }
      }
      case GET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_COUNT: {
        return {
          ...state,
          newHistogramBySiteCount: action.payload,
          setDashboardHistogramUsagePerSiteLoading: false
        }
      }
      case GET_DASHBOARD_USAGE_PIE_TOP10_APPS: {
        return {
          ...state,
          newUsagePieTop10Apps: action.payload,
          setDashboardUsagePieTop10AppsLoading: false
        }
      }
      case GET_DASHBOARD_PIE_WAN_USAGE_BYSITE: {
        return {
          ...state,
          wanUsageBySites: action.payload,
          setDashboardPieWanUsageBySiteLoading: false
        }
      }
      case GET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK: {
        return {
          ...state,
          newSummaryUploadDownload: action.payload,
          setDashboardUsageHistogramUpDownLinkLoading: false
        }
      }
      case GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY_LOADING: {
        return {
          ...state,
          newSummaryUptimeAllLinksLoading: action.payload
        }
      }
      case GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY:
        return {
          ...state,
          newSummaryUptimeAllLinks: action.payload,
          newSummaryUptimeAllLinksLoading: false
        }
      case GET_DASHBOARD_USAGE_HISTOGRAM_BYSITE: {
        return {
          ...state,
          newSummaryHistogramBySite: action.payload,
          setDashboardUsageHistogramBySiteLoading: false
        }
      }
      case GET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE: {
        return {
          ...state,
          newSummaryUptimeTable: action.payload,
          setDashboardUptimeSiteStatisticsTableLoading: false
        }
      }
      case GET_DASHBOARD_UPTIME_LINECHART_BYWAN: {
        return {
          ...state,
          newSummaryUptimeLinechart: action.payload,
          setDashboardUptimeLinechartByWanLoading: false
        }
      }
      case GET_DASHBOARD_UPTIME_PIECHART_BYSITE: {
        return {
          ...state,
          newSummaryUptimePiechart: action.payload,
          setDashboardUptimePiechartBySiteLoading: false
        }
      }
      case GET_DASHBOARD_UPTIME_LINECHART_BYSITE: {
        return {
          ...state,
          newSummaryUptimeLineChartBySite: action.payload,
          setDashboardUptimeLinechartBySiteLoading: false
        }
      }
      case GET_DASHBOARD_UPTIME_PIECHART_BYWAN: {
        return {
          ...state,
          newSummaryUptimePiechartByWan: action.payload,
          setDashboardUptimePiechartByWanLoading: false
        }
      }
      case GET_CSV_DATA_TO_DOWNLOAD: {
        return {
          ...state,
          csvDownloadId: action.id,
          csvDataDownloded: action.payload,
          csvDownloadLoading: false
        }
      }
      case SET_DASHBOARD_ALERT_PIECHART_BYNAME_LOADING: {
        return {
          ...state,
          newSummaryAlertPieChartByNameLoading: action.payload
        }
      }
      case SET_DASHBOARD_ALERT_PIECHART_BYSITE_LOADING: {
        return {
          ...state,
          newSummaryAlertPieChartBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_ALERT_LINECHART_BYNAME_LOADING: {
        return {
          ...state,
          newSummaryAlertLineChartByNameLoading: action.payload
        }
      }
      case SET_DASHBOARD_ALERT_LINECHART_BYSITE_LOADING: {
        return {
          ...state,
          newSummaryAlertLineChartBySiteLoading: action.payload
        }
      }
      case SET_DASHBOARD_ALERT_TOPWIDGET_LOADING: {
        return {
          ...state,
          newSummaryAlertTopWidgetLoading: action.payload
        }
      }
      case GET_DASHBOARD_ALERT_PIECHART_BYNAME: {
        return {
          ...state,
          alertPieChartByName: action.payload,
          newSummaryAlertPieChartByNameLoading: false
        }
      }
      case GET_DASHBOARD_ALERT_PIECHART_BYSITE: {
        return {
          ...state,
          alertPieChartBySite: action.payload,
          newSummaryAlertPieChartBySiteLoading: false
        }
      }
      case GET_DASHBOARD_ALERT_LINECHART_BYNAME: {
        return {
          ...state,
          alertLineChartByName: action.payload,
          newSummaryAlertLineChartByNameLoading: false
        }
      }
      case GET_DASHBOARD_ALERT_LINECHART_BYSITE: {
        return {
          ...state,
          alertLineChartBySite: action.payload,
          newSummaryAlertLineChartBySiteLoading: false
        }
      }
      case GET_DASHBOARD_ALERT_TOPWIDGET: {
        return {
          ...state,
          alertTopWidget: action.payload,
          newSummaryAlertTopWidgetLoading: false
        }
      }
      case SET_DASHBOARD_PER_DEVICE_WAN_PROFILES: {
        return {
          ...state,
          setWanProfilePerDeviceLoading: action.payload
        }
      }
      case GET_DASHBOARD_PER_DEVICE_WAN_PROFILES: {
        return {
          ...state,
          wanProfilesPerDevice: action.payload,
          setWanProfilePerDeviceLoading: false
        }
      }
      case SET_DASHBOARD_ADVANCED_BONDING_LOADING: {
        return {
          ...state,
          setAdvancedBondingLoading: action.payload
        }
      }
      case GET_DASHBOARD_ADVANCED_BONDING: {
        return {
          ...state,
          getAdvancedBonding: action.payload,
          setAdvancedBondingLoading: false
        }
      }
      case SET_DASHBOARD_LAN_STATUS_LOADING: {
        return {
          ...state,
          setLANStatusLoading: action.payload
        }
      }
      case GET_DASHBOARD_LAN_STATUS: {
        return {
          ...state,
          getLANStatus: action.payload,
          setLANStatusLoading: false
        }
      }
      case SET_DASHBOARD_CONNECTED_HUB_LOADING: {
        return {
          ...state,
          setConnectedHubLoading: action.payload
        }
      }
      case GET_DASHBOARD_CONNECTED_HUB: {
        return {
          ...state,
          getConnectedHub: action.payload,
          setConnectedHubLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_DEVICES_LOADING: {
        return {
          ...state,
          setSystemHealthDevicesLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_DEVICES: {
        return {
          ...state,
          systemHealthDevices: action.payload,
          setSystemHealthDevicesLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU_LOADING: {
        return {
          ...state,
          setSystemHealthCpuChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU: {
        return {
          ...state,
          systemHealthCpuChart: action.payload,
          setSystemHealthCpuChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY_LOADING: {
        return {
          ...state,
          setSystemHealthMemoryChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY: {
        return {
          ...state,
          systemHealthMemoryChart: action.payload,
          setSystemHealthMemoryChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK_LOADING: {
        return {
          ...state,
          setSystemHealthContrackChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK: {
        return {
          ...state,
          systemHealthContrackChart: action.payload,
          setSystemHealthContrackChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS_LOADING: {
        return {
          ...state,
          setSystemHealthRxDropsChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS: {
        return {
          ...state,
          systemHealthRxDropsChart: action.payload,
          setSystemHealthRxDropsChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP_LOADING: {
        return {
          ...state,
          setSystemHealthSwapChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP: {
        return {
          ...state,
          systemHealthSwapChart: action.payload,
          setSystemHealthSwapChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE_LOADING: {
        return {
          ...state,
          setSystemHealthTemperatureChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE: {
        return {
          ...state,
          systemHealthTemperatureChart: action.payload,
          setSystemHealthTemperatureChartLoading: false
        }
      }
      case SET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART_LOADING: {
        return {
          ...state,
          setSystemHealthDiskStorageChartLoading: action.payload
        }
      }
      case GET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART: {
        return {
          ...state,
          systemHealthDiskStorageChart: action.payload,
          setSystemHealthDiskStorageChartLoading: false
        }
      }
      case SET_FLIGHT_SCHEDULE_DATA_LOADING: {
        return {
          ...state,
          flightSchedulingDataLoading: action.payload
        }
      }
      case GET_FLIGHT_SCHEDULE_DATA: {
        return {
          ...state,
          flightSchedulingDataLoading: false,
          flightSchedulingdata: action.payload
        }
      }
      case SET_FLIGHT_TAILID_UPDATE_TABLE_LOADING: {
        return {
          ...state,
          flightTailIdUpdateTableLoading: action.payload
        }
      }
      case GET_FLIGHT_TAILID_UPDATE_TABLE: {
        return {
          ...state,
          flightTailIdUpdateTableLoading: false,
          flightTailIdUpdateTable: action.payload
        }
      }
      case SET_FLIGHT_TAILID_UPDATE_TABLE_COUNT_LOADING: {
        return {
          ...state,
          flightTailIdUpdateTableCountLoading: action.payload
        }
      }
      case GET_FLIGHT_TAILID_UPDATE_TABLE_COUNT: {
        return {
          ...state,
          flightTailIdUpdateTableCountLoading: false,
          flightTailIdUpdateTableCount: action.payload
        }
      }
      case SET_FLIGHT_SCHEDULE_TABLE_LOADING: {
        return {
          ...state,
          flightScheduleTableLoading: action.payload
        }
      }
      case GET_FLIGHT_SCHEDULE_TABLE: {
        return {
          ...state,
          flightScheduleTableLoading: false,
          flightScheduleTable: action.payload
        }
      }
      case SET_FLIGHT_SCHEDULE_TABLE_COUNT_LOADING: {
        return {
          ...state,
          flightScheduleTableCountLoading: action.payload
        }
      }
      case GET_FLIGHT_SCHEDULE_TABLE_COUNT: {
        return {
          ...state,
          flightScheduleTableCountLoading: false,
          flightScheduleTableCount: action.payload
        }
      }
      case SET_DEVICE_HISTORY_LOADING: {
        return {
          ...state,
          setDeviceHistoryLoading: action.payload
        }
      }
      case GET_DEVICE_HISTORY: {
        return {
          ...state,
          deviceHistory: action.payload,
          setDeviceHistoryLoading: false
        }
      }
      case SET_DEVICE_CONFIG_ACTION_LOGS_LOADING: {
        return {
          ...state,
          deviceConfigActionLogsLoading: action.payload
        }
      }
      case GET_DEVICE_CONFIG_ACTION_LOGS: {
        return {
          ...state,
          deviceConfigActionLogs: action.payload,
          deviceConfigActionLogsLoading: false
        }
      }
      case SET_DEVICE_EOS_HISTORY_EVENTS_LOADING: {
        return {
          ...state,
          deviceEosHistoryEventsLoading: action.payload
        }
      }
      case GET_DEVICE_EOS_HISTORY_EVENTS: {
        return {
          ...state,
          deviceEosHistoryEvents: action.payload,
          deviceEosHistoryEventsLoading: false
        }
      }
      case SET_DEVICE_LICENSE_HISTORY_LOADING: {
        return {
          ...state,
          deviceLicenseHistoryLoading: action.payload
        }
      }
      case GET_DEVICE_LICENSE_HISTORY: {
        return {
          ...state,
          deviceLicenseHistory: action.payload,
          deviceLicenseHistoryLoading: false
        }
      }
      case SET_UPGRADE_INVENTORY_LICENSE_LOADING: {
        return {
          ...state,
          setUpgradeInventoryLicenseLoading: action.payload
        }
      }
      case GET_UPGRADE_INVENTORY_LICENSE: {
        return {
          ...state,
          inventoryUpgradeLicense: action.payload,
          setUpgradeInventoryLicenseLoading: false
        }
      }
      case IS_INVENTORY_PREVIOUS_PAGE: {
        return {
          ...state,
          isInventoryPreviousPage: action.payload,
        }
      }
      case SET_UPGRADE_WAREHOUSE_DEVICE_TO_LATEST_EOS_VERSION_LOADING: {
        return {
          ...state,
          upgradeWarehouseDeviceToLatestEosVersionLoading: action.payload
        }
      }
      case UPGRADE_WAREHOUSE_DEVICE_TO_LATEST_EOS_VERSION: {
        return {
          ...state,
          upgradeWarehouseDeviceToLatestEosVersion: action.payload,
          upgradeWarehouseDeviceToLatestEosVersionLoading: false
        }
      }
      case SET_HUB_DASHBOARD_HUB_DATA_LOADING: {
        return {
          ...state,
          setHubDataLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_HUB_DATA: {
        return {
          ...state,
          hubDataTableRow: action.payload,
          setHubDataLoading: false
        }
      }
      case SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING: {
        return {
          ...state,
          setHubDataCountLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_HUB_DATA_COUNT: {
        return {
          ...state,
          hubDataTableRowCount: action.payload,
          setHubDataCountLoading: false
        }
      }
      case SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING: {
        return {
          ...state,
          setHubDataClientTableLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_CLIENT_TABLE: {
        return {
          ...state,
          hubDataClientTable: action.payload,
          setHubDataClientTableLoading: false
        }
      }
      case SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING: {
        return {
          ...state,
          hubDataSitesDropdownLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_SITES_DROPDOWN:
        if (!_.isEqual(state.getHubVesselListing, action.payload)) {
          return {
            ...state,
            getHubVesselListing: action.payload,
            hubDataSitesDropdownLoading: false
          }
      } else {
        return {
          ...state
        }
      }
      case SET_HUB_DASHBOARD_CLIENT_WISE_LOADING: {
        return {
          ...state,
          setHubDashboardClientWiseLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_CLIENT_WISE: {
        return {
          ...state,
          hubDashboardClientWiseData: action.payload,
          setHubDashboardClientWiseLoading: false
        }
      }
      case SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING: {
        return {
          ...state,
          setHubDashboardClientWiseCountLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_CLIENT_WISE_COUNT: {
        return {
          ...state,
          hubDashboardClientWiseDataCount: action.payload,
          setHubDashboardClientWiseCountLoading: false
        }
      }
      case SET_TOPOLOGY_HUB_TABLE: {
        return {
          ...state,
          setTopologyHubTableLoading: action.payload
        }
      }
      case GET_TOPOLOGY_HUB_TABLE: {
        return {
          ...state,
          getTopologyHubTable: action.payload,
          setTopologyHubTableLoading: false
        }
      }
      case SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE: {
        return {
          ...state,
          setHubDashboardCarrierClientTableLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE: {
        return {
          ...state,
          carrierHubDataClientTable: action.payload,
          setHubDashboardCarrierClientTableLoading: false
        }
      }
      case SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE: {
        return {
          ...state,
          setHubDashboardCarrierServiceTableLoading: action.payload
        }
      }
      case GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE: {
        return {
          ...state,
          getHubDashboardServiceTable: action.payload,
          setHubDashboardCarrierServiceTableLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_STATUS: {
        return {
          ...state,
          setCarrierHubSDWAnStatusLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_STATUS: {
        return {
          ...state,
          getCarrierHubSDWANStatus: action.payload,
          setCarrierHubSDWAnStatusLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_LATENCY: {
        return {
          ...state,
          setCarrierHubSDWAnLatencyLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_LATENCY: {
        return {
          ...state,
          getCarrierHubSDWANLatency: action.payload,
          setCarrierHubSDWAnLatencyLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_DATA_RATES: {
        return {
          ...state,
          setCarrierHubSDWANDataRatesLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_DATA_RATES: {
        return {
          ...state,
          getCarrierHubSDWANDataRates: action.payload,
          setCarrierHubSDWANDataRatesLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_USAGE: {
        return {
          ...state,
          setCarrierHubSDWANUsageLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_USAGE: {
        return {
          ...state,
          getCarrierHubSDWANUsage: action.payload,
          setCarrierHubSDWANUsageLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_HUBS: {
        return {
          ...state,
          setCarrierHubSDWANHubsLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_HUBS: {
        return {
          ...state,
          getCarrierHubSDWANHubs: action.payload,
          setCarrierHubSDWANHubsLoading: false
        }
      }
      case SET_CARRIER_HUB_AGENT_DROPDOWN: {
        return {
          ...state,
          setCarrierHubAgentDropdownLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_AGENT_DROPDOWN: {
        return {
          ...state,
          getCarrierHubAgentDropdown: action.payload,
          setCarrierHubAgentDropdownLoading: false
        }
      }
      case SET_CARRIER_HUB_WAN_DROPDOWN: {
        return {
          ...state,
          setCarrierHubWanDropdownLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_WAN_DROPDOWN: {
        return {
          ...state,
          getCarrierHubWanDropdown: action.payload,
          setCarrierHubWanDropdownLoading: false
        }
      }
      case SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN: {
        return {
          ...state,
          setCarrierHubListDropdownLoading: action.payload
        }
      }
      case GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN: {
        return {
          ...state,
          getCarrierHubListDropdown: action.payload,
          setCarrierHubListDropdownLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET: {
        return {
          ...state,
          setCaptiveDashSitesSubscribersSesTopWidgetLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET: {
        return {
          ...state,
          getCDSitesSubscribersSesTopWidget: action.payload,
          setCaptiveDashSitesSubscribersSesTopWidgetLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET: {
        return {
          ...state,
          setCaptiveDashPlansTopWidgetLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET: {
        return {
          ...state,
          getCDPlansTopWidget: action.payload,
          setCaptiveDashPlansTopWidgetLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET: {
        return {
          ...state,
          setCaptiveDashUsageTopWidgetLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET: {
        return {
          ...state,
          getCDUsageTopWidget: action.payload,
          setCaptiveDashUsageTopWidgetLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR: {
        return {
          ...state,
          setCaptiveDashSitesUsageBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR: {
        return {
          ...state,
          getCDSitesUsageBar: action.payload,
          setCaptiveDashSitesUsageBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART: {
        return {
          ...state,
          setCaptiveDashSubscribersLineChartLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART: {
        return {
          ...state,
          getCDSitesSubscribersLineChart: action.payload,
          setCaptiveDashSubscribersLineChartLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART: {
        return {
          ...state,
          setCaptiveDashSessionsLineChartLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART: {
        return {
          ...state,
          getCDSitesSessionsLineChart: action.payload,
          setCaptiveDashSessionsLineChartLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART: {
        return {
          ...state,
          setCaptiveDashUsageLineChartLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART: {
        return {
          ...state,
          getCDSitesUsageLineChart: action.payload,
          setCaptiveDashUsageLineChartLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SITES_TABLE: {
        return {
          ...state,
          setCaptiveDashSitesTableLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SITES_TABLE: {
        return {
          ...state,
          getCDSitesTable: action.payload,
          setCaptiveDashSitesTableLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR: {
        return {
          ...state,
          setCaptiveDashTopSitesSubscribersBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR: {
        return {
          ...state,
          getCDTopSitesSubscribersBar: action.payload,
          setCaptiveDashTopSitesSubscribersBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR: {
        return {
          ...state,
          setCaptiveDashTopSitesSessionsBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR: {
        return {
          ...state,
          getCDTopSitesSessionsBar: action.payload,
          setCaptiveDashTopSitesSessionsBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE: {
        return {
          ...state,
          setCaptiveDashSubscribersSessionsTableLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE: {
        return {
          ...state,
          getCDSubscribersSessionsTable: action.payload,
          setCaptiveDashSubscribersSessionsTableLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE: {
        return {
          ...state,
          setCaptiveDashTopPlansUsersPieLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE: {
        return {
          ...state,
          getCDTopPlansUsersPie: action.payload,
          setCaptiveDashTopPlansUsersPieLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE: {
        return {
          ...state,
          setCaptiveDashEventsSubscribersPlanUsageTableLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE: {
        return {
          ...state,
          getCDEventsSubscribersPlanUsageTable: action.payload,
          setCaptiveDashEventsSubscribersPlanUsageTableLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR: {
        return {
          ...state,
          setCaptiveDashTopSubscribersUsageBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR: {
        return {
          ...state,
          getCDTopSubscribersUsageBar: action.payload,
          setCaptiveDashTopSubscribersUsageBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR: {
        return {
          ...state,
          setCaptiveDashTopSubscribersSessionsBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR: {
        return {
          ...state,
          getCDTopSubscribersSessionsBar: action.payload,
          setCaptiveDashTopSubscribersSessionsBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR: {
        return {
          ...state,
          setCaptiveDashPlansPercentageUsageCountBarLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR: {
        return {
          ...state,
          getCDPlansPercentageUsageCountBar: action.payload,
          setCaptiveDashPlansPercentageUsageCountBarLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART: {
        return {
          ...state,
          setCaptiveDashPlansUsageLineChartLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART: {
        return {
          ...state,
          getCDPlansUsageLineChart: action.payload,
          setCaptiveDashPlansUsageLineChartLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST: {
        return {
          ...state,
          setCaptiveDashSubscribersListLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST: {
        return {
          ...state,
          getCDSubscribersList: action.payload,
          setCaptiveDashSubscribersListLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_PLANS_LIST: {
        return {
          ...state,
          setCaptiveDashPlansListLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_PLANS_LIST: {
        return {
          ...state,
          getCDPlansList: action.payload,
          setCaptiveDashPlansListLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE: {
        return {
          ...state,
          setCaptiveDashUserSessionsTableLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE: {
        return {
          ...state,
          getCDUserSessionsTable: action.payload,
          setCaptiveDashUserSessionsTableLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_EVENTS_TABLE: {
        return {
          ...state,
          setCaptiveDashEventsTableLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_EVENTS_TABLE: {
        return {
          ...state,
          getCDEventsTable: action.payload,
          setCaptiveDashEventsTableLoading: false
        }
      }
      case SET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST: {
        return {
          ...state,
          setCaptiveDashIpMacListLoading: action.payload
        }
      }
      case GET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST: {
        return {
          ...state,
          getCDIpMacList: action.payload,
          setCaptiveDashIpMacListLoading: false
        }
      }
    default:
      return state;
  }
}