import { useEffect, useRef, useState } from "react";
import L, { Map as LeafletMap } from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents, ZoomControl } from "react-leaflet";
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, ButtonGroup, Divider, Tooltip as MUIToolTip, IconButton } from "@mui/material";
import "leaflet/dist/leaflet.css";
import "./StarlinkMaps.css";
import _ from "lodash";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { getStarlinkMapTelemetry } from "../StarlinkDashboard/slice"
// import LayersIcon from '@mui/icons-material/Troubleshoot';
import PerformanceIcon from "../../asset/image/PerformanceIcon.svg"
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import Control from "react-leaflet-custom-control";
import { VITE_ACCESS_TOKEN, VITE_USERNAME, VITE_STYLE_ID } from "../../config";
import moment from "moment";
import EditActionsPopup from "../StarlinkAdmin/ServiceLines/EditActionsPopup";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import SettingsIcon from '@mui/icons-material/Settings';
import { bulkUpdateServiceLineOrganization, getServiceLines, subscribeToServiceLines, optInForServiceLine, pauseResumeServiceLine, getSubscriptionForAccount } from "../StarlinkAdmin/slice";
import authReducer from "../../reducers/authReducer";

const StarlinkMap = (props) => {
    const { location, starlinkLocations, authReducer, getServiceLines, serviceLines, pauseResumeServiceLine, optInForServiceLine, serviceLinesForAdmin, bulkUpdateServiceLineOrganization, getSubscriptionForAccount, selectedServiceLine, selectedServiceAccount, setSelectedServiceLine,subscriptionsForAccount, dpIds, getStarlinkMapTelemetry, starlinkMapTelemetry, mapClick, setMapClick, tableSearch, newSummaryServiceLine, hoveredSL, setHoveredSL } = props;
    const history = useHistory();
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [map, setMap] = useState<LeafletMap | null>(null);
    const [zoomLevel, setZoomLevel] = useState<number>(2);
    const [selectedTerminal, setSelectedTerminal] = useState<any>(null);
    const [open, setOpen] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState<{
        service_line_name: string;
        service_line_number: string;
        lat: number;
        long: number;
    } | null>(null);

    const [selectedServiceLineLocal, setSelectedServiceLineLocal] = useState<any>(null)
    const [starlinkMapTelemetryLocal, setStarlinkMapTelemetryLocal] = useState<any>(null)
    const prevServiceLineRef = useRef(selectedServiceLineLocal);
    const [editActionsPopup, setEditActionsPopup] = useState(false);
    const [dataForPopup, setDataForPopup] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [match, setMatch] = useState(true)
    const hoveredLocationRef = useRef(null);

    useEffect(() => {
        if(!_.isEmpty(selectedServiceLine) && match){
        setSelectedServiceLineLocal(selectedServiceLine);
       }
    }, [selectedServiceLine])

    useEffect(() => {
        setStarlinkMapTelemetryLocal(starlinkMapTelemetry);
    }, [starlinkMapTelemetry])

    const handleChangeTerminal = (terminal) => {
        setSelectedTerminal(terminal);
        getStarlinkMapTelemetry({
            userTerminalId: terminal?.userTerminalId
        })
    }

    const handleInsightsClick = (row) => {
        let kits;
        if (row?.userTerminals !== '' && JSON.parse(row?.userTerminals)) {
            kits = JSON.parse(row?.userTerminals)?.map((userTerminal: any) => {
                return userTerminal?.kitSerialNumber;
            });
        }

        let params: any = getDecodeURI(location?.search);
        params.kitSerialNumber = kits.map((kit) => `'${kit}'`).join(',');
        delete params.page;
        const newTabURL = `/starlink/analytics?${getEncodedURI(params)}&serviceLineAccess=All`;
        window.open(newTabURL, "_blank");
    };


    const getIcon = (status: number, isSelected: boolean) =>
        L.divIcon({
            html: `
        <div style="
          color: ${status === 1 ? "#2AB75A" : "red"};
          background: linear-gradient(145deg, ${status === 1 ? "#83E29C" : "#F77373"}, ${status === 1 ? "#C3F3D1" : "#FFA3A3"});
          border: 3px solid ${isSelected ? "#264C86" : status === 1 ? "#2AB75A" : "#D50000"};
          box-shadow: 0px 0px 6px 0px ${isSelected ? "#264C86" : status === 1 ? "#2AB75A" : "#D50000"};
          border-radius: 50%;
          width: ${zoomLevel * 2}px;
          height:  ${zoomLevel * 2}px;
          display: inline-flex;
        "></div>
      `,
        });

    const MyComponent = () => {
        useMapEvents({
            zoomend: () => {
                if (map) {
                    const zoomLvl = map.getZoom();
                    setZoomLevel(zoomLvl);
                }
            },
        });
        return null;
    };

    useEffect(() => {

        if (map) {
            map.setView([35, -35], 2);
        }
        setOpen(false)
    }, [newSummaryServiceLine])

    useEffect(() => {
        if (_.isEqual(prevServiceLineRef.current, selectedServiceLineLocal)) {
            return;
        }
        prevServiceLineRef.current = selectedServiceLineLocal;

        if(selectedServiceLineLocal && selectedServiceLineLocal?.usageSplit) {
            setOpen(true)
            setMatch(true)
        }
      
        setSelectedTerminal(null)
        setStarlinkMapTelemetryLocal(null)

        if (!_.isEmpty(selectedServiceLineLocal) && !_.isEmpty(starlinkLocations)) {
            if (selectedServiceLineLocal?.userTerminals != '' && JSON.parse(selectedServiceLineLocal?.userTerminals)?.length > 0) {
                setSelectedTerminal(JSON.parse(selectedServiceLineLocal?.userTerminals)[0])
                getStarlinkMapTelemetry({
                    userTerminalId: JSON.parse(selectedServiceLineLocal?.userTerminals)[0]?.userTerminalId
                })
            }

            getServiceLines({
                accounts: selectedServiceAccount,
                dpIds,
                searchText: selectedServiceLine?.serviceLineNumber,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                page: tableParams.page,
                limit: tableParams.size,
                serviceLineStatus: tableParams.serviceLineStatus,
                serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
            })

            const matchingLocation = starlinkLocations?.find((location) => {
                return location.service_line_number === selectedServiceLineLocal?.serviceLineNumber;
            });

            if (matchingLocation) {
                setSelectedLocation({
                    service_line_name: matchingLocation.service_line_name,
                    service_line_number: matchingLocation.service_line_number,
                    lat: matchingLocation.lat,
                    long: matchingLocation.long,
                });
                if (map) {
                    map.setView([matchingLocation.lat, matchingLocation.long + 1.0], 8);
                }
            }
        }
    }, [selectedServiceLineLocal, starlinkLocations])

    //     useEffect(()=>{
    //         if(tableSearch && tableSearch !== ''){
    //         if( !_.isEmpty(starlinkLocations) && starlinkLocations?.length > 0){
    //             if (map) {
    //                 map.setView([starlinkLocations[0]?.lat, starlinkLocations[0]?.long], 6);
    //             }
    //         }
    //         else{
    //             if(map){
    //                 map.setView([35, -35], 2);
    //     }
    //         }
    //     } else if( tableSearch === '' && !mapClick){
    //         if(map){
    //             map.setView([35, -35], 2);
    // }
    //     }
    //     },[starlinkLocations])

    const handleLayerClick = () => {
        if (open) {
            if (map) {
                map.setView([35, -35], 2);
                setMapClick(null)
                setSelectedServiceLineLocal(null)
                setSelectedServiceLine(null)
            }
        } else {
            if (serviceLines && serviceLines.length > 0) {
                if (_.isEmpty(selectedServiceLineLocal)) {
                    setSelectedServiceLineLocal(serviceLines[0])
                }
                setSelectedServiceLine(serviceLines[0])
            }
        }
        setOpen(!open)
    }

    useEffect(() => {
        if (mapClick) {

        } else {
            if (map) {
                map.setView([35, -35], 2);
            }
        }
    }, [mapClick])

    useEffect(()=>{
        if(!_.isEmpty(serviceLines)) {
        const matchedData = serviceLines?.find(
            (serviceLine) => serviceLine.serviceLineNumber === selectedLocation?.service_line_number
        );

        if (matchedData) {
            setOpen(true);
            setSelectedServiceLineLocal(matchedData);
            setSelectedServiceLine(matchedData);
        } 
        }

    }, [serviceLines])


    const handleMarkerClick = (location: typeof starlinkLocations[0]) => {
        let param = {
            nickname: location.service_line_name,
            serviceLineNumber: location.service_line_number,
            userTerminals: location?.user_terminals,
        }
        // setOpen(true);
        // setSelectedServiceLineLocal(param);
        setMatch(false)
        setSelectedServiceLine(param); 

        setMapClick({
            nickname: location.service_line_name,
            serviceLineNumber: location.service_line_number,
            userTerminals: location?.user_terminals,
        })

        setSelectedLocation({
            service_line_name: location.service_line_name,
            service_line_number: location.service_line_number,
            lat: location.lat,
            long: location.long,
        });
    };

    const toDMS = (deg) => {
        const degrees = Math.floor(deg);
        const minutes: any = ((deg - degrees) * 60).toFixed(2);
        const seconds: any = (((deg - degrees) * 60 - minutes) * 60).toFixed(2);


        return `${degrees}° ${minutes}'`;
    };

    const convertLatLongToCoordinates = (latitude, longitude) => {
        const latDirection = latitude >= 0 ? 'N' : 'S';
        const lonDirection = longitude >= 0 ? 'E' : 'W';

        const latInDMS = toDMS(Math.abs(latitude));
        const lonInDMS = toDMS(Math.abs(longitude));

        return `${latInDMS} ${latDirection}, ${lonInDMS} ${lonDirection}`;
    };

    const handleConvert = (latitude, longitude) => {
        const result = convertLatLongToCoordinates(parseFloat(latitude), parseFloat(longitude));
        return result;
    };

    const handleSiteClick = (row) => {
        let params = getDecodeURI(location?.search);
        params.k4Ids = `${row.location_id}:${row.site}`;
        delete params.page;
        const newTabURL = `/fleet-dashboard/sites/topology?${getEncodedURI(params)}`;
        window.open(newTabURL, "_blank");
    };

    const handleOverlayInteraction = () => {
        if (map) {
            map.dragging.disable();
        }
    };

    const handleOverlayInteractionEnd = () => {
        if (map) {
            map.dragging.enable();
        }
    };
    const STARLINK_ADMIN_SF = "starlink-admin";
    const STARLINK_FULL_SF = "starlink-full";

    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
    const [serviceLineToUpdate, setServiceLineToUpdate] = useState<any>(null);
     const [pauseData, setPauseData] = useState<any>({
            serviceLineNumber: '',
            accountNumber: '',
            accountDpId: '',
            action: '',
            endNow: false,
            serviceLineName: ''
        });
        const [product, setProduct] = useState('');
        const [endNow, setEndNow] = useState(false)
        const [rejectReason, setRejectReason] = useState('')
        const [mobilePriority, setMobilePriority] = useState(false);
           const [optInData, setOptInData] = useState<any>({
                serviceLineNumber: '',
                accountNumber: '',
                accountDpId: '',
                action: '',
                serviceLineName: ''
            });
        const [availableProducts, setAvailableProducts] = useState<{
            plan: string;
            product_reference_id: string;
        }[]>([])

          const [tableParams, setTableParams] = useState<{
                page: number;
                size: number;
                sortBy: any;
                sortOrder: any;
                search: string;
                serviceLineStatus: String[];
            }>({
                page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
                size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
                sortBy:  'serviceLineName',
                sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as any : 'asc',
                search: queryParams.has('search') ? queryParams.get('search') as string : '',
                serviceLineStatus: queryParams.has('serviceLineStatus') ? queryParams.get('serviceLineStatus')?.split(',') as string[] : ['true', 'false']
            });

    const handleCloseEditPopUp = () => {
        setEditActionsPopup(false);
    }

    const handleEdit = (serviceLine: any) => {
        setServiceLineToUpdate(serviceLine);
    }

    const handleChangeServiceLine = () => {
        getServiceLines({
            accounts: selectedServiceAccount,
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLineStatus: tableParams.serviceLineStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
        });
    }

    const handleChangeSubOrg = async (dpId: string, serviceLineNumbers: string[]) => {
        await bulkUpdateServiceLineOrganization({
            serviceLineNumbers: serviceLineNumbers,
            dpId: dpId,
        });
        setServiceLineToUpdate(null);
    }

    const handleChangeEndNow = (event) => {
        setEndNow(event.target.value === "Yes" ? true : false)
    }

    const handleChangeProduct = (event) => {
        setProduct(event.target.value)
    }

    
    const handleOptIn = () => {
        if (optInData?.serviceLineNumber != '' && optInData?.accountDpId != '' && optInData?.accountNumber != '') {
            handleChangeOptIn(optInData?.serviceLineNumber, optInData?.accountDpId, optInData?.accountNumber, optInData.action)
        }
    }

    const handleChangeOptIn = async (sl, accountDpId, accountNumber, action) => {
        const requestData = {
            dpId: accountDpId,
            serviceLineNumber: sl,
            accountNumber: accountNumber,
            action: action
        }
        await optInForServiceLine(requestData)

        handleChangeServiceLine()
    }

    const handlePauseResume = async () => {
        const requestData = {
            dpId: pauseData.accountDpId,
            serviceLineNumber: pauseData.serviceLineNumber,
            accountNumber: pauseData.accountNumber,
            action: pauseData.action,
            productReferenceId: product,
            endNow: endNow,
            rejectReason: rejectReason
        }
        await pauseResumeServiceLine(requestData)

        handleChangeServiceLine()
    }

    const convertSeconds = (input) => {
        let seconds = parseInt(input, 10);
        if (isNaN(seconds) || seconds < 0) {
          return "Invalid input.";
        }
        const days = Math.floor(seconds / (60 * 60 * 24));
        seconds %= 60 * 60 * 24;
        const hours = Math.floor(seconds / (60 * 60));
        seconds %= 60 * 60;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
      
        let result = "";
        if (days > 0) result += `${days}D `;
        if (hours > 0) result += `${hours}H `;
        if (minutes > 0) result += `${minutes}M `;
        if (seconds > 0 || result === "") result += `${seconds}S`;
      
        return result.trim();
      }

      const handleMarkerHover = (location) => {
        setHoveredSL({nickname: location?.service_line_name})
    };

    return (
        <>
            <MapContainer
                center={[35, -35] as [number, number]}
                zoom={zoomLevel}
                minZoom={2}
                className="strMap-mapStyle"
                zoomControl={false}
                attributionControl={true}
                maxBoundsViscosity={1.0}
                maxBounds={[[-90, -180], [90, 180]]}
                whenCreated={(mapInstance) => setMap(mapInstance as LeafletMap)}
            >
                <TileLayer
                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${VITE_USERNAME}/${VITE_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${VITE_ACCESS_TOKEN}`}
                    maxZoom={19}
                />
                <ZoomControl position="topright" />
                <Control position="topright">
                    <div className="">
                        <ButtonGroup orientation="vertical" className="mapCustButtonsGroup">
                            <Button title="Insights" id="layersSel" onClick={() => { handleLayerClick() }}>
                                <img src={PerformanceIcon} alt="Performance Icon" />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Control>

                <MyComponent />

                {starlinkLocations?.map((location, index) => (
                    <Marker
                        key={`${index}-${selectedServiceLineLocal?.serviceLineNumber}`}
                        position={[location.lat, location.long]}
                        icon={location?.service_line_number === selectedServiceLineLocal?.serviceLineNumber
                            ? getIcon(location.status, true)
                            : getIcon(location.status, false)}
                        eventHandlers={{
                            click: () => handleMarkerClick(location),
                            mouseover: (e) => {
                                e.originalEvent.stopPropagation();
                                if (hoveredLocationRef.current !== location) {
                                    hoveredLocationRef.current = location;
                                    handleMarkerHover(location);
                                }
                            },
                        }}
                    >
                         {location?.service_line_number === selectedServiceLineLocal?.serviceLineNumber ? (
                            <Tooltip
                                direction="top"
                                offset={[0, -zoomLevel * 2]}
                                opacity={1}
                                className="strMaps-custom-tooltip"
                                permanent={true} 
                            >
                                {location.service_line_name}
                            </Tooltip>
                        ) : null}
                    </Marker>
                ))}


                {/* {selectedLocation && ( */}
                {open && selectedServiceLineLocal && (
                    <div className="overlay-container"
                        onMouseEnter={handleOverlayInteraction}
                        onMouseLeave={handleOverlayInteractionEnd}
                    >
                        <button
                            className="cancel-button"
                            onClick={() => {
                                setSelectedServiceLine(null)
                                setMapClick(null)
                                setOpen(!open)
                                if (map) {
                                    map.setView([35, -35], 3);
                                    map.dragging.enable();
                                }
                            }}
                        >
                            ✕
                        </button>
                        <Typography variant="h6" className="titleTypo">
                        {
                            selectedServiceLineLocal?.accountDpName && selectedServiceLineLocal?.dpName
                                ? `${selectedServiceLineLocal.accountDpName} / ${selectedServiceLineLocal.dpName}`
                                : selectedServiceLineLocal?.accountDpName || selectedServiceLineLocal?.dpName || ""
                            }

                        </Typography>
                        <Typography variant="h6" className="titleTypo">
                            {selectedServiceLineLocal?.nickname || "NA"}
                        </Typography>
                        <Divider style={{ backgroundColor: '#ccc', height: '1px', width: '100%' }} />
                        <Typography variant="h6" className="titleTypo">
                            SL Number: {selectedServiceLineLocal?.serviceLineNumber || ''}
                        </Typography>
                        <Typography variant="h6" className="titleTypo">
                            Plan: {selectedServiceLineLocal?.servicePlan ? selectedServiceLineLocal?.servicePlan?.length > 25
                                ? `${selectedServiceLineLocal?.servicePlan.slice(0, 25)}.. (${Number(selectedServiceLineLocal?.billingUsagePercent ? selectedServiceLineLocal?.billingUsagePercent : 0.00)?.toFixed(1)}%)`
                                : `${selectedServiceLineLocal?.servicePlan} (${Number(selectedServiceLineLocal?.billingUsagePercent ? selectedServiceLineLocal?.billingUsagePercent : 0.00)?.toFixed(1)}%)` : "NA"}

                        </Typography>
                        <div className="usage-container">
                            <div className="usage-header">
                                <span>Usage</span>
                                <span className="usage-value">
                                    {selectedServiceLineLocal?.usageSplit && selectedServiceLineLocal?.usageSplit !== '' && JSON.parse(selectedServiceLineLocal?.usageSplit)?.length > 0 ? JSON.parse(selectedServiceLineLocal?.usageSplit)[0]?.usageGB : 0} GB
                                </span>
                            </div>
                            <div className="usage-item">
                                <span className="usage-label">• Priority</span>
                                <span className="usage-amount">
                                    {selectedServiceLineLocal?.usageSplit && selectedServiceLineLocal?.usageSplit !== '' && JSON.parse(selectedServiceLineLocal?.usageSplit)?.length > 0 ? JSON.parse(selectedServiceLineLocal?.usageSplit)[0]?.usagePriorityGB : 0} GB
                                </span>
                            </div>
                            <div className="usage-item">
                                <span className="usage-label">• Opt-In</span>
                                <span className="usage-amount">
                                    {selectedServiceLineLocal?.usageSplit && selectedServiceLineLocal?.usageSplit !== '' && JSON.parse(selectedServiceLineLocal?.usageSplit)?.length > 0 ? JSON.parse(selectedServiceLineLocal?.usageSplit)[0]?.usageOptInPriorityGB : 0} GB
                                </span>
                            </div>
                            <div className="usage-item">
                                <span className="usage-label">• Standard</span>
                                <span className="usage-amount">
                                    {selectedServiceLineLocal?.usageSplit && selectedServiceLineLocal?.usageSplit !== '' && JSON.parse(selectedServiceLineLocal?.usageSplit)?.length > 0 ? JSON.parse(selectedServiceLineLocal?.usageSplit)[0]?.usageStandardGB : 0} GB
                                </span>
                            </div>
                            {/* <div className="usage-item">
                                <span className="usage-label">• Non-Billable</span>
                                <span className="usage-amount">
                                {selectedServiceLineLocal?.usageSplit && selectedServiceLineLocal?.usageSplit !== '' && JSON.parse(selectedServiceLineLocal?.usageSplit)?.length > 0 ? JSON.parse(selectedServiceLineLocal?.usageSplit)[0]?.usageNonBillableGB : 0} GB
                                </span>
                            </div> */}
                        </div>


                        <div className="terminal-container">
                            <div className="terminal-item">
                                {selectedServiceLineLocal?.userTerminals && selectedServiceLineLocal?.userTerminals !== '' ?
                                    JSON.parse(selectedServiceLineLocal?.userTerminals) && JSON.parse(selectedServiceLineLocal?.userTerminals).length > 1 ?
                                        <Select
                                            fullWidth
                                            size="small"
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: '#333',
                                                            color: 'white',
                                                        }
                                                    }
                                                }
                                            }}
                                            value={selectedTerminal || ""}
                                            className="strMaps-custom-select strMap-sl-dropdown"
                                            onChange={(e) => handleChangeTerminal(e.target.value)}
                                            displayEmpty
                                            renderValue={(selected) =>
                                                selected
                                                    ? `${selected?.kitSerialNumber}`
                                                    : "Select Terminal"
                                            }
                                        >
                                            {selectedServiceLineLocal?.userTerminals &&
                                                JSON.parse(selectedServiceLineLocal?.userTerminals)?.map((terminal) => (
                                                    <MenuItem key={terminal.userTerminalId} value={terminal}>
                                                        {terminal.kitSerialNumber}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        :
                                        <div style={{ width: "100%" }}>
                                            <Typography variant="h6" className="titleTypo">
                                                {JSON.parse(selectedServiceLineLocal?.userTerminals)[0]?.kitSerialNumber || "User Terminal Details"}
                                            </Typography>
                                            <Divider style={{ backgroundColor: '#ccc', height: '1px', width: '100%' }} />
                                        </div>
                                    : null}
                            </div>

                            {starlinkMapTelemetryLocal && starlinkMapTelemetryLocal.length > 0 &&
                                <>
                                    <div className="terminal-item">
                                        <span className="terminal-label">Terminal ID</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.device_id}
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Obstruction</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.obstruction_percent_time?.toFixed(2)} %
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Downlink</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.downlink_throughput?.toFixed(2)} Mbps
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Uplink</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.uplink_throughput?.toFixed(2)} Mbps
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Signal Quality:</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.signal_quality?.toFixed(2)} %
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Ping Drop Rate</span>
                                        <span className="terminal-value">
                                        {typeof starlinkMapTelemetryLocal[0]?.ping_drop_rate_avg === 'number' 
                                            ? (starlinkMapTelemetryLocal[0].ping_drop_rate_avg * 100).toFixed(2) + ' %' 
                                            : 'N/A'}

                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Latency</span>
                                        <span className="terminal-value">
                                            {starlinkMapTelemetryLocal[0]?.ping_latency_ms_avg?.toFixed(2)} ms
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Uptime</span>
                                        <span className="terminal-value">
                                            {convertSeconds(starlinkMapTelemetryLocal[0]?.uptime?.toFixed(2))}
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Last Online</span>
                                        <span className="terminal-value">
                                            {moment(starlinkMapTelemetryLocal[0]?.last_online).format(YYYYMMDD_HHmmss)}
                                        </span>
                                    </div>

                                    <div className="terminal-item">
                                        <span className="terminal-label">Coordinates</span>
                                        <span className="terminal-value">
                                            {handleConvert(starlinkMapTelemetryLocal[0]?.lati, starlinkMapTelemetryLocal[0]?.long)}
                                        </span>
                                    </div>
                                    <Divider style={{ backgroundColor: '#ccc', height: '1px', width: '100%', marginTop: "10px" }} />
                                </>}
                        </div>

                        <div className="d-flex str-justify str-mt-0">
                            {selectedServiceLineLocal?.k4SiteName &&
                                <MUIToolTip title={selectedServiceLineLocal?.k4SiteName} arrow  >
                                    <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={() => { handleSiteClick({ location_id: selectedServiceLineLocal?.k4SiteId, site: selectedServiceLineLocal?.k4SiteName }) }} >Site</Button>
                                </MUIToolTip>}
                            <Button size="small" variant="contained" className='starlink--button_contained--primary'
                                disabled={selectedServiceLineLocal ? selectedServiceLineLocal?.userTerminals ? selectedServiceLineLocal?.userTerminals === "" : true : true}
                                onClick={() => {
                                    handleInsightsClick(selectedServiceLineLocal)
                                }} >Insights</Button>

                            <Button size="small" variant="contained" className='starlink--button_contained--primary'
                                onClick={() => {
                                    setEditActionsPopup(true)
                                    setDataForPopup(serviceLinesForAdmin[0])
                                    getSubscriptionForAccount({dpIds: serviceLinesForAdmin[0]?.accountDpId})
                                }} >
                                <IconButton
                                    className="strMap-SettingsIcon"
                                    size='small'>
                                    <SettingsIcon />
                                </IconButton>
                            </Button>
                        </div>
                    </div>
                )}
                {hoveredSL &&
                    <div className="overlay-container-2">
                        <Typography variant="h6" className="hoveredSlDetails">
                            {hoveredSL?.accountDpName || ""}
                            {hoveredSL?.dpName ? ` / ${hoveredSL.dpName}  ` : ""}
                            {hoveredSL?.nickname ? (hoveredSL?.dpName || hoveredSL?.accountDpName ) ? ` / ${hoveredSL.nickname}` : ` ${hoveredSL.nickname}` : ""}

                        </Typography>

                    </div>}
            </MapContainer>

            {editActionsPopup ? <EditActionsPopup
                open={editActionsPopup} setOpen={() => { handleCloseEditPopUp() }}
                confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}
                row={dataForPopup} setRow={setSelectedServiceLine} handleChangeServiceLine={handleChangeServiceLine}
                handleEdit={handleEdit} serviceLine={serviceLineToUpdate} availableCustomPlans={subscriptionsForAccount}
                onSave={handleChangeSubOrg} starlinkService={starlinkAdminService} starlinkFunction={starlinkAdminFunction}
                pauseData={pauseData} setPauseData={setPauseData} endNow={endNow} handleChangeEndNow={handleChangeEndNow} rejectReason={rejectReason} setRejectReason={setRejectReason}
                product={product} setProduct={setProduct} handleChangeProduct={handleChangeProduct} availableProducts={availableProducts} handlePauseResume={handlePauseResume}
                optInData={optInData} setOptInData={setOptInData} mobilePriority={mobilePriority} setMobilePriority={setMobilePriority} handleOptIn={handleOptIn} dpIds={dpIds}
            /> : null}


        </>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    warnings: state.starlinkDashboard.warnings,
    starlinkMapTelemetry: state.starlinkDashboard.starlinkMapTelemetry,
    subscriptionsForAccount: state.starlinkAdmin.subscriptionsForAccount,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount,
    serviceLinesForAdmin: state.starlinkAdmin.serviceLines,


});

export default withRouter(
    connect(mapStateToProps, {
        getStarlinkMapTelemetry,
        getServiceLines,
        pauseResumeServiceLine,
        optInForServiceLine,
        getSubscriptionForAccount,
        bulkUpdateServiceLineOrganization
    })(StarlinkMap)
);