import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Typography, Box, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI } from "../../../utils/util";
import { getAvailableProductsForAccount, assignCustomPlan, updateNonAdminPermissions, getNonAdminPermissions } from "../slice";

import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
import OrgSelection from "../../OrgSelection";
var Loader = require("react-loader");

const EditActionsPopup = (props) => {
    const { loading, open, setOpen, confirmDialog, setConfirmDialog, location, authReducer, allNonAdminPermissions, updateNonAdminPermissions, getNonAdminPermissions } = props;
   const STARLINK_ADMIN_SF = "starlink-admin";

    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
    const params = getDecodeURI(location?.search)
    const [selectedOu, setSelectedOu] = useState(params?.ouId)
    const [allowMobilePriority, setAllowMobilePriority] = useState(false)
    const [allowPauseResume, setAllowPauseResume] = useState(false)
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    const deviceActionsList = [ 
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? "CONFIGURE" : null,
    ].filter(action => action);

    useEffect(()=>{
        if(allNonAdminPermissions ){
            setAllowMobilePriority(allNonAdminPermissions["mobilePriority"])
            setAllowPauseResume(allNonAdminPermissions["pauseResume"])
        }

    },[allNonAdminPermissions])

    const getIdNameForList = (action) => {
        if (action == "CONFIGURE") {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)) {
                return 'actionType-enabled';
            }
        } 
        return 'actionType-disabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
 
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));


    const updateActionValues = (action) => {
        switch (action) {
            case "CONFIGURE": {
                break;
            }
          
            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setOpen(false);
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleSavePermissions = async() =>{
        let params = {
            dpId: authReducer?.selectedOu?.id,
            mobilePriority: allowMobilePriority,
            pauseResume: allowPauseResume
        }

        // await updateNonAdminPermissions(params)
        // await getNonAdminPermissions({dpId: authReducer?.selectedOu?.id})
        handleClose()
    }

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-starlink-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Permission for Non Admins</span>
                            <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{`Organization: ${authReducer?.selectedOu ? authReducer?.selectedOu?.name : ''} `}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={3} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists'
                                                style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }}
                                                key={`device-${index}`}
                                                selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} className="menuScroll starlinkAdmin-edit-actions-cont" style={{ minHeight: "250px" }}>
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == "CONFIGURE" && getIdNameForList(selectedAction) == 'actionType-enabled' && 
                                            <div>
                                                <Box className="starlink-serviceAccount-details-container">

                                                <Box className="starlink-serviceAccount-details-row">
                                                        <Box className="starlink-serviceAccount-details-heading">Sub Organization </Box>
                                                        <Grid className="configuration-form-build">
                                                        <OrgSelection label={"Sub Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />

                                                        </Grid>
                                                    </Box>
                                                    <Box className="starlink-serviceAccount-details-row">
                                                        <Box className="starlink-serviceAccount-details-heading">Mobile Priority </Box>
                                                        <Grid className="configuration-form-build">
                                                            <FormControl>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={allowMobilePriority === true ? "Yes" : "No"}
                                                                    label=""
                                                                    onChange={(event)=>{setAllowMobilePriority(event.target.value === "Yes" ? true : false)}}
                                                                    size="small"
                                                                    style={{width: "200px"}}
                                                                >
                                                                    <MenuItem value="Yes">Allowed</MenuItem>
                                                                    <MenuItem value="No">Not Allowed</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>

                                                    <Box className="starlink-serviceAccount-details-row">
                                                        <Box className="starlink-serviceAccount-details-heading">Pause Resume Service Line</Box>
                                                        <Box > 
                                                            <Grid className="configuration-form-build">
                                                            <FormControl>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={allowPauseResume === true ? "Yes" : "No"}
                                                                    label=""
                                                                    onChange={(event)=>{setAllowPauseResume(event.target.value === "Yes" ? true : false)}}
                                                                    size="small"
                                                                    style={{width: "200px"}}
                                                                >
                                                                    <MenuItem value="Yes">Allowed</MenuItem>
                                                                    <MenuItem value="No">Not Allowed</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <div className="edit-actions-confirm-btns" style={{width:"73%"}}>
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => {handleSavePermissions()}}
                                                    disabled={false}>Save</Button>
                                                </div>
                                            </div>
                                            </div>
                                            }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>


           
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    allNonAdminPermissions: state.starlinkAdmin.allNonAdminPermissions
});

export default withRouter(
    connect(mapStateToProps, { getAvailableProductsForAccount, assignCustomPlan, updateNonAdminPermissions, getNonAdminPermissions })(EditActionsPopup)
);