import { Grid, Switch, Tooltip, Typography } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPooledBarChart, getPooledLineChart } from "./slice";
import _ from "lodash";
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import { formatDataSizeAsTB, formatSize, getDateForChart, getSingleRecordChartLable, getTimezoneCity } from "../../utils/util";
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment";

const SiteLineChart = (props) => {
    const { selectedOu, newSummaryStartDate, newSummaryEndDate, userTimezone, pooledLineChart, title, getPooledBarChart, getPooledLineChart, pieLimit, barChartData, selectedServiceAccount, toggleBtn, pooledTopCards, chartRef={} } = props;
    const [lineChartData, setLineChartData] = useState<any>([]);
    const timeZone = getTimezoneCity(userTimezone);
    const [chartView, setChartView] = useState('spline');

    const getUsageSource = () => {
        const endDate = moment(newSummaryEndDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(newSummaryStartDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference <= 24) {
            return 'agg_source';
        } else {
            return 'direct_source';
        }
    }

    useEffect(() => {
        if (!selectedServiceAccount || selectedServiceAccount.length === 0) return;
        const payload = {
            dpId: selectedOu?.id,
            chartStartDate: newSummaryStartDate.format(YYYYMMDD_HHmmss),
            chartEndDate: newSummaryEndDate.format(YYYYMMDD_HHmmss),
            limit: pieLimit,
            offset: 0,
            source: getUsageSource(),
            accounts: selectedServiceAccount
        }
        getPooledBarChart(payload);
    }, [selectedOu, newSummaryStartDate, newSummaryEndDate, pieLimit, selectedServiceAccount])

    useEffect(() => {
        if (!selectedServiceAccount || selectedServiceAccount.length === 0) return;
        getPooledLineChart({
             dpId: selectedOu?.id,
             accounts: selectedServiceAccount, 
             chartStartDate: newSummaryStartDate.format(YYYYMMDD_HHmmss),
             chartEndDate: newSummaryEndDate.format(YYYYMMDD_HHmmss),
             usageSource: getUsageSource(), });
    }, [selectedOu, selectedServiceAccount])

    useEffect(() => {
        let cumulativeData: any = [];
        let total = 0;
        if (!_.isEmpty(pooledTopCards)) {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();

            const totalLimit = parseFloat(pooledTopCards?.data?.[0]?.totalLimit) / 1000; // Fetch the total usage (limit) from the API response
            const dailyUsage = totalLimit / daysInMonth; // Calculate dynamic daily usage
            // Generate average cumulative data for the whole month
            for (let day = 1; day <= daysInMonth; day++) {
                total += dailyUsage;
                cumulativeData.push({
                    x: Date.UTC(year, month, day),
                    y: parseFloat(total.toFixed(2)),
                    unit: ' TB'
                })
            }
        }

        if (!_.isEmpty(pooledLineChart)) {
            const data = pooledLineChart;
            const seriesData = data?.usage_value?.series;

            const formatUsageData = (usageData) => usageData.map(({ x, y }) => {
                const [value, unit] = formatDataSizeAsTB(y).split(' ');
                return { x, y: Number(value), unit: ` ${unit}` };
            });
        
            const priorityUsage = formatUsageData(seriesData?.cumulative_priority_gb || []);
            const standardUsage = formatUsageData(seriesData?.cumulative_standard_gb || []);
            const nonBillableUsage = formatUsageData(seriesData?.cumulative_non_billable_gb || []);

            setLineChartData([
                { name: 'Standard GB', data: standardUsage, color: '#3aab9a', type: 'column', visible: true },
                { name: 'Non-Billable GB', data: nonBillableUsage, color: '#f9c74f', type: 'column', visible: true },
                { name: 'Priority GB', data: priorityUsage, color: '#2f2282', type: 'column', visible: true },
                // { name: 'Trend', data: cumulativeData, color: '#88cae3', type: 'spline', visible: true }
            ]);
        } else {
            setLineChartData([]);
        }
    }, [pooledLineChart, pooledTopCards]);

    const barOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '260px',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: chartView === 'column' ? ' GB' : ' TB',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 2,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            formatter: function (this: any) {
                return '<b style="color: #7e8089">' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + '</b><br/>' +
                       '<b style="color: #7e8089">' + selectedOu?.name + '</b><br/>' +
                       '<b style="color: #7e8089">' + this.series.name + ' ' +
                       '<b style="color: #fff">' + this.y + (chartView === 'column' ? ' GB' : this.point.options.unit) + '</b>';
            }
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: chartView == 'column' && getDateForChart(barChartData) && getSingleRecordChartLable(barChartData, timeZone)
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                format: `{value} ${chartView == 'column' ? ' GB' : ' TB'}`,
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: `Usage (${chartView == 'column' ? 'GB' : 'TB'})`,
            }
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    return `${item.name.replace("GB", "")} ${chartView == 'column' ? '(GB)' : '(TB)'}`;
                }
            }
        },
        series: chartView == 'column' ? barChartData : lineChartData.map((series: any) => ({
            ...series,
            marker: {
                enabled: series.name !== 'Trend'
            }
        })),
        colorByPoint: true
    }

    const handleToggleChange = (e) => {
        if (e.target.checked) {
            setChartView('spline');
        } else {
            setChartView('column')
        }
    }

    const handleLegendClick = (app, color, index) => {
        let all_true = true;
        let updatedData: any = [...lineChartData];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            setLineChartData([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            setLineChartData([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            setLineChartData([...updatedData]);
        }
    }

    return (
        <Grid className="site-line-chart-title-div">
            <Grid className={title ? "legends-pie-new-container site-line-chart-title-text" : "legends-pie-new-container"} style={{ margin: '0px' }}>
                {chartView == 'spline' && <div className="starlink-pooled-helpIcon">
                    <Tooltip placement="left" title='Cumulative usage is always represented as MTD chart.'><InfoIcon /></Tooltip>
                </div>}
                <Typography className="title-text">Cumulative(MTD/Plan)</Typography><Switch checked={chartView == 'spline'} onChange={handleToggleChange} size="small" />
            </Grid>
            <HighchartsReact
                immutable={true}
                highcharts={Highcharts}
                options={barOptions}
                ref={chartRef}
            />
            {chartView == 'spline' ? <Grid className="time-series-legends-new time-series-legends-new-sites pooled-chart-legends">
                {lineChartData && !_.isEmpty(lineChartData) && lineChartData.map((usage, i) => (
                    <Grid className="summary-pie-bar-legends summary-pie-bar-legends-sites name-color">
                        <div className="summary-pie-usage-dot" style={{ background: `${usage.color}` }}></div>
                        <Grid className={lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'} onClick={() => handleLegendClick(usage, usage['color'], i)}>{usage.name}</Grid>
                    </Grid>
                ))}
            </Grid> : (chartView == 'column' && toggleBtn) ? <Grid className="time-series-legends-new time-series-legends-new-sites">
                {barChartData && !_.isEmpty(barChartData) && barChartData.map((usage, i) => (
                    <Grid className="summary-pie-bar-legends summary-pie-bar-legends-sites">
                        <div className="summary-pie-usage-dot" style={{ background: `${usage.color}` }}></div>
                        <span className='barChart-legends'>{usage.name}</span>
                    </Grid>
                ))}
            </Grid> : null}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    pooledLineChart: state?.starlinkPooled?.pooledLineChart,
    userTimezone: state.authReducer.userTimezone,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount,
    pooledTopCards: state?.starlinkPooled?.pooledTopCards
});

export default withRouter(
    connect(mapStateToProps, { getPooledBarChart, getPooledLineChart })(SiteLineChart)
);