import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState, useRef} from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Button } from "@mui/material";
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import BarChart from "./BarChart";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import moment from "moment";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

const CaptiveCharts = (props) => {
    const { authReducer, newSummaryStartDate, newSummaryEndDate, location, history, vessels, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart, getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart, topcardSelected, selectedTab, values, setValues, applyFilterClick } = props;
    const [subscribertsData, setSubscribertsData] = useState<any>([]);
    const [sessionsData, setSessionsData] = useState<any>([]);
    const [usageData, setUsageData] = useState<any>([]);
    const [chartView, setChartView] = useState('spline');

    const [resetDate, setResetDate] = useState<any>([]);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);
    const { selectedSubscribers, selectedPlans } = values;

    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "5 minutes";
        } else if (minutes > 720 && minutes <= 1440) {
            return "15 minutes";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "1 hour";
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [dataUnit, setDataUnit] = useState("GB");

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    useEffect(() => {
        const _interval = getInterval(
            Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
        );
        setSelectedBin(_interval);
    
        const tabToChartMap = {
            'SUBSCRIBERS': () => getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans),
            'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans),
            'TOTAL USAGE': () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans),
        };
    
        if (vessels?.length > 0) {
            tabToChartMap[topcardSelected]?.();
        } else {
            setSubscribertsData([])
            setSessionsData([])
            setUsageData([])
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate, topcardSelected]);

    const convertUsageData = (data) => {
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = Object.keys(data).some(key =>
            _.isArray(data[key]) && data[key].some(item => item.y > gbValue)
        );
    
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        const usageData = Object.keys(data).map(key => {
            data[key].forEach((el, i) => {
                data[key][i].y = Number(convertBytes(el.y, false));
            });
            return {
                name: 'Usage',
                data: data[key],
                marker: { symbol: 'circle' },
                visible: true,
                color: '#2f2282',
                unit: isGBCrossed ? 'GB' : 'MB'
            };
        });
    
        setDataUnit(isGBCrossed ? 'GB' : 'MB');
        return usageData;
    };
    
    const setChartData = (name, chartData, setDataFunction) => {
        if (_.isEmpty(chartData)) {
            setDataFunction([]);
            return;
        }
        const data = chartData?.data?.[`${name}Count`]?.series;
        const formattedData = Object.keys(data).map(key => ({
            name,
            data: data[key],
            marker: { symbol: 'circle' },
            visible: true,
            color: '#2f2282'
        }));
        setDataFunction(formattedData);
    };
    
    useEffect(() => {
        if (['SUBSCRIBERS', 'SESSIONS', 'TOTAL USAGE'].includes(topcardSelected)) {
            setChartData('Subscribers', getCDSitesSubscribersLineChart, setSubscribertsData);
            setChartData('Sessions', getCDSitesSessionsLineChart, setSessionsData);
    
            if (!_.isEmpty(getCDSitesUsageLineChart)) {
                const usageData = convertUsageData(getCDSitesUsageLineChart?.data?.quotaUsed?.series);
                setUsageData(usageData);
            } else {
                setUsageData([]);
            }
        }
    }, [getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart]);    

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const barOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '285px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: topcardSelected === 'TOTAL USAGE' ? ` ${dataUnit}` : '',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: topcardSelected === 'TOTAL USAGE' ? 2 : undefined,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            formatter: function (this: any) {
                let unit = '';
                if (topcardSelected === 'TOTAL USAGE') {
                    unit = ` ${dataUnit}`;
                }
        
                const options: Intl.DateTimeFormatOptions = {
                    weekday: 'long',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                };
        
                const timeZoneFormattedDate = new Intl.DateTimeFormat('en-US', {
                    ...options,
                    timeZone: timeZone,
                }).format(new Date(this.x));
        
                return `<b style="color: #7e8089">${timeZoneFormattedDate}</b><br/>
                        <b style="color: #7e8089">${this.series.name} <b style="color: #fff">${this.y}${unit}</b></b>`;
            }
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: topcardSelected === 'SUBSCRIBERS' && getDateForChart(subscribertsData) ? getSingleRecordChartLable(subscribertsData, timeZone) :
                        topcardSelected === 'SESSIONS' && getDateForChart(sessionsData) ? getSingleRecordChartLable(sessionsData, timeZone) :
                        topcardSelected === 'TOTAL USAGE' && getDateForChart(usageData) ? getSingleRecordChartLable(usageData, timeZone) : ''
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            title: {
                text: ''
            },
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
                rotation: 0
            }
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    else if(topcardSelected === 'TOTAL USAGE'){
                        return `${item.name} (${dataUnit})`
                    }
                    return `${item.name}`;
                }
            }
        },
        series: topcardSelected === 'SUBSCRIBERS' && !_.isEmpty(subscribertsData) ? subscribertsData :
               topcardSelected === 'SESSIONS' && !_.isEmpty(sessionsData) ? sessionsData : 
               !_.isEmpty(usageData) ? usageData : [],
        colorByPoint: true
    };

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
    
        if (vessels?.length > 0) {
            switch (topcardSelected) {
                case 'SUBSCRIBERS':
                    getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                case 'SESSIONS':
                    getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                case 'TOTAL USAGE':
                    getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                default:
                    break;
            }
        }
    };

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'captive_usage_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ marginTop: '15px' }}>
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        <Grid item xs={5} sm={5} md={5} lg={5} className="pie-chart-summary">
                            <BarChart vessels={vessels} topcardSelected={topcardSelected} selectedTab={selectedTab} values={values} setValues={setValues} applyFilterClick={applyFilterClick} chartRef={chartRef}/>
                        </Grid>
                        {<Grid item xs={7} sm={7} md={7} lg={7}>
                            <Grid className={resetDate.length > 0 ? "legends-pie-new-container justify-content-tabs removeMarginTop" : "legends-pie-new-container justify-content-tabs"}>
                                <Grid className="captive-line-chart-title">
                                    {topcardSelected === "TOTAL USAGE" ? "Usage over Time" : topcardSelected == 'SUBSCRIBERS' ? 'Subscribers over Time' : 'Sessions over Time'}
                                </Grid>
                                <Grid className="time-series-legends-new">
                                    <Grid className="summary-pie-bar-legends">
                                        <div className="summary-pie-usage-dot" style={{ background: '#2f2282' }}></div>
                                        <span className="barChart-legends">{topcardSelected === "TOTAL USAGE" ? "Usage" : topcardSelected == 'SUBSCRIBERS' ? 'Subscribers' : 'Sessions'}</span>
                                    </Grid>
                                    <Grid className="pieChat-selectTag">
                                        <FormControl variant="standard" className='selectEntry-pie'>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectedBin}
                                                onChange={handleBinChange}
                                            >
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5 minutes'}>5m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minutes'}>10m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15 minutes'}>15m</MenuItem>} 
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30 minutes'}>30m</MenuItem>} 
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2 hour'}>2h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3 hour'}>3h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <img className={chartView == 'spline' ? "chart-view-icons-enable" : ''} onClick={() => { setChartView('column') }} src={chartView == 'column' ? barIcon : bar_chart} />
                                    <img className={chartView == 'column' ? "chart-view-icons-enable": ''} onClick={() => { setChartView('spline') }} src={chartView == 'spline' ? line_chart : lineIcon} />
                                    {resetDate.length > 0 &&
                                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                            <div>
                                                <FormControlLabel
                                                    value="Zoom Back"
                                                    control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="Zoom Reset"
                                                    control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                            </div>
                                        </div>}
                                </Grid>
                            </Grid>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barOptions}
                                ref={chartRef}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCaptiveDashSubscribersLineChart: state?.authReducer?.getCaptiveDashSubscribersLineChart,
    getCDSitesSubscribersLineChart: state?.authReducer?.getCDSitesSubscribersLineChart,
    getCDSitesSessionsLineChart: state?.authReducer?.getCDSitesSessionsLineChart,
    getCDSitesUsageLineChart: state?.authReducer?.getCDSitesUsageLineChart,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart })(CaptiveCharts)
);